import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import ViewFlight from "../Flights/ViewFlight";
import ViewFlightClient from "../FlightClients/ViewFlightClient";

import {
  APIClient,
  getClient
} from "../../API/Orders/Clients";
import {
  APIJob,
  getJob
} from "../../API/Orders/Jobs";
import {
  SingleAPIOrder,
  getOrder
} from "../../API/Orders/Orders";
import {
  APIProcessingZone,
  getProcessingZones
} from "../../API/Orders/ProcessingZones";

export default function ViewFlightJob(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [job, setJob] = useState<APIJob | null>(null);
  const [client, setClient] = useState<APIClient | null>(null);
  const [order, setOrder] = useState<SingleAPIOrder | null>(null);
  const [processingZone, setProcessingZone] = useState<APIProcessingZone | null>(null);
  const [modal, setModal] = useState<"client" | "flight" | "order" | null>(null);
  const [selected, setSelected] = useState<any>(null);

  function formatOrder(value: SingleAPIOrder | null) {
    if (value === null)
      return "";
    return value.id + ": " + value.zone.name + " (" + value.status.name + ")";
  }

  function formatClient(value: APIClient | null) {
    if (value === null)
      return "";
    return (<>
      {value.id + ": "}
      <Button
        color="primary"
        className={classes.buttonLink}
        onClick={(e) => handleModal(e, "client", value.id)}
      >
        {value.name}
      </Button>
    </>);
  }

  function formatFlightId(value: number | null) {
    if (value === null)
      return "";
    return (
      <Button
        color="primary"
        className={classes.buttonLink}
        onClick={(e) => handleModal(e, "flight", value)}
      >
        {value}
      </Button>
    );
  }

  function formatProcessingZone(value: APIProcessingZone | null) {
    if (value === null)
      return "";
    return value.id + ": size " + value.size;
  }

  function valueOrNone(value: any) {
    return value && value !== "" ? value : "None";
  }

  function yesOrNo(value: boolean) {
    return value ? "Yes" : "No";
  }

  useEffect(() => {
    getJob(props.jobId)
      .then((result: APIJob | null) => {
        if (result === null)
          return;
        Promise.all([
          getClient(result.client_id),
          getOrder(result.order_id),
          getProcessingZones(result.order_id)
        ]).then(([ client, order, processingZones ]) => {
          setClient(client);
          setOrder(order);
          const processingZone = processingZones && processingZones.filter((zone) => zone.id === result.processing_zone);
          setProcessingZone(processingZone && processingZone.length ? processingZone[0] : null);
          setJob(result);
        })
        .catch((err: any) => console.log(err));
      })
      .catch((err: any) => console.log(err));
  }, [ props.jobId ]);

  function handleModal(e: any, modal: "client" | "flight" | "order", item: any) {
    e.preventDefault();
    setModal(modal);
    setSelected(item);
    return false;
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Flight Job
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            color="primary"
            className={classes.viewTitle}
          >
            Job ID: {props.jobId}
          </Typography>

          {job === null &&
            <Container className={classes.formContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Loading data ...
              </Typography>
            </Container>
          }

          {job !== null &&
          <Container className={classes.formContainer}>

            <Box className={classes.formSection}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Execution Info
              </Typography>
              <TableContainer>
                <Table className={classes.viewTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>{job.status.code + " - " + job.status.message}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Running</TableCell>
                      <TableCell>{job.running ? "Yes" : "No"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Start Date</TableCell>
                      <TableCell>{(new Date(job.start_date * 1000)).toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>EC2 Mosaic ID</TableCell>
                      <TableCell>{valueOrNone(job.ec2_id.mosaic)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>EC2 Preprocess ID</TableCell>
                      <TableCell>{valueOrNone(job.ec2_id.preprocessing)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>EC2 Tree Count ID</TableCell>
                      <TableCell>{valueOrNone(job.ec2_id.tree_count)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Execution ID</TableCell>
                      <TableCell>{job.execution_id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>GCP</TableCell>
                      <TableCell>{job.gcp ? "Yes" : "No"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Workteam ARN</TableCell>
                      <TableCell>{job.workteam_arn}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Number of files</TableCell>
                      <TableCell>{job.number_of_files}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box className={classes.formColumns}>
              <Box className={classes.formColumn2}>
                <Box className={classes.formSection}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.viewSectionTitle}
                  >
                    General Info
                  </Typography>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>DEM</TableCell>
                          <TableCell>{job.dem.replace(/\//g, " / ")}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Order</TableCell>
                          <TableCell>{formatOrder(order)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Client</TableCell>
                          <TableCell>{formatClient(client)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Processing Zone</TableCell>
                          <TableCell>{formatProcessingZone(processingZone)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>

              <Box className={classes.formColumn2}>
                <Box className={classes.formSection}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.viewSectionTitle}
                  >
                    Crops
                  </Typography>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                      {job && job.crops && job.crops.map((crop) => (
                        <TableRow key={crop.id+""}>
                          <TableCell>{crop.id}</TableCell>
                          <TableCell>{crop.name}</TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>

            <Box className={classes.formSection}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Flights
              </Typography>
              <TableContainer>
                <Table className={classes.viewTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Number</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {job && job.flights && job.flights.map((flight) => (
                    <TableRow key={flight.id}>
                      <TableCell>{formatFlightId(flight.id)}</TableCell>
                      <TableCell>{flight.number}</TableCell>
                      <TableCell>{(new Date(flight.date)).toLocaleString()}</TableCell>
                      <TableCell>{flight.code}</TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box className={classes.formColumns}>
              <Box className={classes.formColumn2}>

                <Box className={classes.formSection}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.viewSectionTitle}
                  >
                    3D Model Quality
                  </Typography>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Face Count</TableCell>
                          <TableCell>{job.quality["3d_model"].face_count}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Surface Type</TableCell>
                          <TableCell>{job.quality["3d_model"].surface_type}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className={classes.formSection}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.viewSectionTitle}
                  >
                    Elevation Quality
                  </Typography>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Ground Point Classification</TableCell>
                          <TableCell>{yesOrNo(job.quality.elevation_quality.ground_point_classification)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Colour Point Classification</TableCell>
                          <TableCell>{yesOrNo(job.quality.elevation_quality.colour_point_classification)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Max Angle</TableCell>
                          <TableCell>{job.quality.elevation_quality.max_angle}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Max Distance</TableCell>
                          <TableCell>{job.quality.elevation_quality.max_distance}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Search Radius</TableCell>
                          <TableCell>{job.quality.elevation_quality.search_radius}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

              </Box>
              <Box className={classes.formColumn2}>

                <Box className={classes.formSection}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.viewSectionTitle}
                  >
                    Alignment Quality
                  </Typography>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Downscale</TableCell>
                          <TableCell>{job.quality.alignment_quality.downscale}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Key Point Limit</TableCell>
                          <TableCell>{job.quality.alignment_quality.key_point_limit}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Tie Point Limit</TableCell>
                          <TableCell>{job.quality.alignment_quality.tie_point_limit}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className={classes.formSection}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.viewSectionTitle}
                  >
                    Dense Cloud Quality
                  </Typography>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Downscale</TableCell>
                          <TableCell>{job.quality.dense_cloud_quality.downscale}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Filter Mode</TableCell>
                          <TableCell>{job.quality.dense_cloud_quality.filter_mode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Colourise</TableCell>
                          <TableCell>{yesOrNo(job.quality.dense_cloud_quality.colourise)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

              </Box>
            </Box>

          </Container>
          }

          <Box className={classes.formButtons} >
            <Button
              variant="contained"
              color="secondary"
              className={classes.formButtonMultiple}
              onClick={props.onCancel}
            >
              Close
            </Button>
          </Box>

        </CardContent>
      </Card>

      <Modal
        className={classes.modalContainerSmall}
        open={modal === "client"}
      >
        <div>
          <ViewFlightClient
            clientId={selected}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>

      <Modal
        className={classes.modalContainerSmall}
        open={modal === "flight"}
      >
        <div>
          <ViewFlight
            flightId={selected}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
