import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import AddGeoserverServer from "./AddGeoserverServer";
import ListGeoserverServers from "./ListGeoserverServers";

export default function GeoserverServers() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListGeoserverServers />
      <Box mt={4} />
      <AddGeoserverServer />
    </Container>
  );
}
