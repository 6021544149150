import { Button, Container, TextField, Typography } from "@material-ui/core";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import { poolData } from "../../constants";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface ResetPasswordFormProps {
  authData: { email: string },
  onSuccess(): void
}

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default function ResetPasswordForm(props: ResetPasswordFormProps) {
  const classes = useStyles();
  const [formState, { password, text }] = useFormState();
  const [snackbar, setSnackbar] = useState(defaultSnackbarState);

  const [submitted, setSubmitted] = useState(false);

  async function makeResetRequest(resetCode: string, newPassword: string) {
    const userData = {
      Username: props.authData.email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.confirmPassword(resetCode, newPassword, {
      onSuccess: (result) => {
        props.onSuccess();
      },
      onFailure: (err) => {
        setSubmitted(false);
        setSnackbar({
          open: true,
          message: err.message,
          severity: "error",
        });
      }
    });

  }

  async function handleReset(e: SyntheticEvent) {
    e.preventDefault();
    setSubmitted(true);

    if (!formState.touched.resetCode && !formState.validity.resetCode) {
      formState.setFieldError("resetCode", "Please fill out this field.");
      setSubmitted(false);
    }

    if (!formState.touched.newPassword && !formState.validity.newPassword) {
      formState.setFieldError("newPassword", "Please fill out this field.");
      setSubmitted(false);
    }

    if (formState.validity.newPassword && formState.validity.resetCode) {
      await makeResetRequest(formState.values.resetCode, formState.values.newPassword);
    }
  }

  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.loginContainer}>
        <Typography variant="h4" component="h1" align="center">
          A password reset was requested
        </Typography>

        <form className={classes.loginForm} onSubmit={handleReset} noValidate>
          <TextField
            {...text("resetCode")}
            label="Reset Code"
            placeholder="Reset Code"
            error={formState.errors.resetCode ? true : false}
            helperText={formState.errors.resetCode}
            variant="outlined"
            fullWidth
            required
            margin="normal"
          />

          <TextField
            {...password("newPassword")}
            label="New Password"
            placeholder="New Password"
            error={formState.errors.newPassword ? true : false}
            helperText={formState.errors.newPassword}
            variant="outlined"
            fullWidth
            required
            margin="normal"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.loginButton}
            disabled={submitted}
            fullWidth
          >
            Update Password
          </Button>
        </form>
      </Container>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
