import { useMutation } from "@apollo/react-hooks";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { DELETE_QR, GET_QRS } from "../../graphql/Qrs";
import * as DeleteQrTypes from "../../graphql/__generated__/DeleteQr";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface DeleteQrParams {
  id: string;
}

export default function DeleteQr() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<DeleteQrParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);

  const [deleteQr, { data, loading }] = useMutation<DeleteQrTypes.DeleteQr>(
    DELETE_QR
  );

  async function handleDeleteQr(e: SyntheticEvent) {
    e.preventDefault();

    try {
      await deleteQr({
        variables: {
          id: parseFloat(String(id)),
        },
        refetchQueries: [{ query: GET_QRS }],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      const error = graphqlService.getError(err);

      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  }

  if (data && data.deleteQr) {
    return <Redirect to="/qrs" />;
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Delete QR Login
          </Typography>

          <Typography variant="body1" color="error">
            This action cannot be undone, make sure you have the correct QR
            login selected
          </Typography>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.formButton}
            onClick={handleDeleteQr}
            disabled={loading ? true : false}
          >
            Delete QR login
          </Button>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
