import { Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import ListFlightOrders from "./ListFlightOrders";

export default function FlightOrders() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListFlightOrders />
    </Container>
  );
}
