import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import DeleteQr from "./DeleteQr";
import GenerateQr from "./GenerateQr";
import ShowQr from "./ShowQr";

export default function Qr() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ShowQr />
      <Box mt={4} />
      <GenerateQr />
      <Box mt={4} />
      <DeleteQr />
    </Container>
  );
}
