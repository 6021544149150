import { Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import ListFlightJobs from "./ListFlightJobs";

export default function FlightJobs() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListFlightJobs />
    </Container>
  );
}
