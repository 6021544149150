import { Action, action, createStore, createTypedHooks } from "easy-peasy";

interface StoreModel {
  authenticated: boolean;
  setLoggedIn: Action<StoreModel, undefined>;
  setLoggedOut: Action<StoreModel, undefined>;
}

const store = createStore<StoreModel>({
  authenticated: false,
  setLoggedIn: action((state, payload) => {
    state.authenticated = true;
  }),
  setLoggedOut: action((state, payload) => {
    state.authenticated = false;
  }),
});

const typedHooks = createTypedHooks<StoreModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default store;
