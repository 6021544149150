import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";
import React, { SyntheticEvent, useContext, useState } from "react";
import { useFormState } from "react-use-form-state";
import AppContext from "../../app-context";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

export default function AddUser() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);

  const [formState, { text, email }] = useFormState({});

  const appContext = useContext(AppContext);

  async function handleAddUser(e: SyntheticEvent) {
    e.preventDefault();

    if (!formState.touched.path && !formState.validity.path) {
      formState.setFieldError("path", "Please fill out this field.");
    }

    if (!formState.touched.email && !formState.validity.email) {
      formState.setFieldError("email", "Please fill out this field.");
    }

    if (!formState.touched.folder && !formState.validity.folder) {
      formState.setFieldError("folder", "Please fill out this field.");
    }

    if (!formState.touched.comment && !formState.validity.comment) {
      formState.setFieldError("comment", "Please fill out this field.");
    }

    if (
      formState.validity.path &&
      formState.validity.email &&
      formState.validity.folder &&
      formState.validity.comment
    ) {
      try {
        setAdding(true);
        const res = await appContext.gatewayApi.addUploadUser(
          formState.values.path, formState.values.email,
          formState.values.folder, formState.values.comment
        );
        setAdding(false);
        formState.reset();
        console.log(res);
      } catch (err: any) {
        setAdding(false);
        setSnackbar({
          open: true,
          message: err.message,
          severity: "error"
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Add an upload user
          </Typography>

          <form onSubmit={handleAddUser} noValidate>
            <TextField
              {...text("path")}
              label="User Path"
              placeholder="Path"
              error={formState.errors.path ? true : false}
              helperText={formState.errors.path}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...email("email")}
              label="Email address"
              placeholder="Email address"
              error={formState.errors.email ? true : false}
              helperText={formState.errors.email}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("folder")}
              label="Folder"
              placeholder="Folder"
              error={formState.errors.folder ? true : false}
              helperText={formState.errors.folder}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("comment")}
              label="Comment"
              placeholder="Comment"
              error={formState.errors.comment ? true : false}
              helperText={formState.errors.comment}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={adding ? true : false}
            >
              Add User
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
