import { useQuery } from "@apollo/react-hooks";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { GET_COMPANY_AREAS } from "../../graphql/Companies";
import * as GetCompanyAreasTypes from "../../graphql/__generated__/GetCompanyAreas";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

interface ListAreasParams {
  id: string;
}

export default function ListAreas() {
  const graphqlService = new GraphqlService();

  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams<ListAreasParams>();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
  }>({ order: "asc" });
  let areas: any[] = [];

  const { data, loading, error } =
    useQuery<GetCompanyAreasTypes.GetCompanyAreas>(GET_COMPANY_AREAS, {
      variables: { id: parseFloat(String(id)) },
    });

  if (data && data.company) {
    areas = sortBy(data.company.areas, [
      (area) => {
        return area.name.toLowerCase();
      },
    ]);
    if (sortOrder.order === "desc") areas = areas.reverse();
    areas = areas.slice(page * 10, page * 10 + 10);
  }

  function updateOrder() {
    if (sortOrder.order === "asc") {
      setSortOrder({ order: "desc" });
    } else {
      setSortOrder({ order: "asc" });
    }
  }

  if (loading) {
    return <Feedback title="Areas" message="Loading areas..." />;
  }

  if (error) {
    const err = graphqlService.getError(error);

    if (err.statusCode === 404) {
      return <Redirect to="/companies" />;
    }

    return (
      <Feedback title="Areas" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        Areas
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sortOrder.order} key="name">
                <TableSortLabel
                  active={true}
                  direction={sortOrder.order}
                  onClick={() => updateOrder()}
                >
                  Name
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.length === 0 ? (
              <TableRow key="0">
                <TableCell>No areas found</TableCell>
              </TableRow>
            ) : (
              areas.map((area: any) => (
                <TableRow
                  key={area.id}
                  onClick={() => history.push(`/areas/${area.id}`)}
                >
                  <TableCell>{area.name}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data ? (data.company ? data.company.areas.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
