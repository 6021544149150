import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Autocomplete
} from "@material-ui/lab";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  CreateAPIClient,
  createClient
} from "../../API/Orders/Clients";

export default function CreateFlightClient(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);

  const formValues: { [key: string]: any } = {
    name: "",
    processing_name: "",
    country: null
  };

  const [formState, { text }] = useFormState(formValues);

  function setError(e: any) {
    if (e instanceof Error)
      e = e.message;
    setSnackbar({
      open: true,
      message: e,
      severity: "error"
    });
  }

  async function handleCreate(e: SyntheticEvent) {
    e.preventDefault();
    setAdding(true);

    let hasErrors = false;
    function setFieldError(key: string, error: string) {
      formState.setFieldError(key, error);
      hasErrors = true;
    }

    try {
      Object.keys(formValues).forEach((key: string) => {
        if (typeof(formValues[key]) === "object")
          if (formState.values[key] === null)
            setFieldError(key, "Please select an option from the list");
        if (typeof(formValues[key]) === "string")
          if (formState.values[key] === "" || formState.values[key] === null)
            setFieldError(key, "Please enter a valid value");
      });

      if (hasErrors) {
        setAdding(false);
        return;
      }

      const client: CreateAPIClient = {
        name: formState.values.name,
        processing_name: formState.values.processing_name,
        contact_name: formState.values.contact_name,
        contact_address: formState.values.contact_address,
        country_id: parseInt(formState.values.country.id)
      };

      createClient(client)
        .then((response) => {
          setAdding(false);
          props.onCreate && props.onCreate(response);
        })
        .catch((err: any) => setError(err));
    }
    catch (err: any) {
      setError(err);
      setAdding(false);
    }
  }

  const countriesById = props.data && props.data.countries ? props.data.countries : {};
  const countries = Object.values(countriesById).sort((a: any, b: any) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

  const options:{ [key: string]: any } = {
    country: countries ? countries.map((country: any) => ({
      id: country.id,
      label: country.name
    })) : []
  };

  const rememberedOptions:{ [key: string]: any } = {};

  function recallOption(key: string, e: any, reason: string) {
    if (reason === "escape" || reason === "blur")
      if (rememberedOptions[key] !== undefined)
        formState.setField(key, rememberedOptions[key]);
  }

  function rememberOption(key: string) {
    rememberedOptions[key] = formState.values[key];
  }

  function setOption(key: string, e: any, value: any, reason: string, callback?: Function) {
    formState.setField(key, value);
    if (callback)
      callback(key, value);
  }

  function renderDropDown(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <Autocomplete
          id={key}
          value={formState.values[key]}
          options={options[key]}
          onChange={(e, value, reason) => setOption(key, e, value, reason, onChange ? onChange : undefined)}
          onClose={(e, reason) => recallOption(key, e, reason)}
          onOpen={(e) => rememberOption(key)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(a: any, b: any) => a.id === b.id}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderTextField(key: string, label: string) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...text(key) }
          label={label}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Add Client
          </Typography>

          <form onSubmit={handleCreate} noValidate>

            <Container className={classes.formContainer}>

              {renderTextField("name", "Name *")}
              {renderTextField("processing_name", "Processing Name *")}
              {renderTextField("contact_name", "Contact Name")}
              {renderTextField("contact_address", "Contact Address")}
              {renderDropDown("country", "Country *")}

            </Container>

            <Box className={classes.formButtons} >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButtonMultiple}
                disabled={adding ? true : false}
              >
                Add Client
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.formButtonMultiple}
                onClick={props.onCancel}
              >
                {adding ? "Close" : "Cancel"}
              </Button>
            </Box>

          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
