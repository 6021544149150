import { useMutation } from "@apollo/react-hooks";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { DELETE_GEOSERVER, GET_GEOSERVERS } from "../../graphql/Geoservers";
import * as DeleteGeoserverTypes from "../../graphql/__generated__/DeleteGeoserver";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface DeleteGeoserverParams {
  id: string;
}

export default function DeleteGeoserver() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<DeleteGeoserverParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);

  const [deleteGeoserver, { data, loading }] = useMutation<
    DeleteGeoserverTypes.DeleteGeoserver
  >(DELETE_GEOSERVER);

  async function handleDeleteGeoserver(e: SyntheticEvent) {
    e.preventDefault();

    try {
      await deleteGeoserver({
        variables: {
          id: parseFloat(String(id)),
        },
        refetchQueries: [{ query: GET_GEOSERVERS }],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      const error = graphqlService.getError(err);

      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  }

  if (data && data.deleteGeoserver) {
    return <Redirect to="/geoservers" />;
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Delete Geoserver
          </Typography>

          <Typography variant="body1" color="error">
            This action cannot be undone, make sure you have the correct
            geoserver selected
          </Typography>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.formButton}
            onClick={handleDeleteGeoserver}
            disabled={loading ? true : false}
          >
            Delete Geoserver
          </Button>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
