import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { history } from "../index";
import { poolData } from "../constants";
import store from "../store";

export default class AuthService {
  async logout() {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }

    store.getActions().setLoggedOut(undefined);

    history.push("/");
  }
}
