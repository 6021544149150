import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import DeleteUser from "./DeleteUser";
import ShowUser from "./ShowUser";

export default function User() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ShowUser />
      <Box mt={4} />
      <DeleteUser />
    </Container>
  );
}
