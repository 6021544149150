import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { GET_USER, GET_USERS, UPDATE_USER } from "../../graphql/Users";
import * as GetUserTypes from "../../graphql/__generated__/GetUser";
import * as UpdateUserTypes from "../../graphql/__generated__/UpdateUser";
import AuthService from "../../services/AuthService";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface ShowUserParams {
  id: string;
}

export default function ShowUser() {
  const graphqlService = new GraphqlService();
  const authService = new AuthService();

  const classes = useStyles();
  const { id } = useParams<ShowUserParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [updating, setUpdating] = useState(false);

  const [formState, { text, email, password, checkbox }] = useFormState({
    name: "",
    email: "",
    password: "",
    isAdmin: false,
  });

  const { data, loading, error } = useQuery<GetUserTypes.GetUser>(GET_USER, {
    variables: { id: parseFloat(String(id)) },
  });
  const [
    updateUser,
    { data: updateData, loading: updateLoading },
  ] = useMutation<UpdateUserTypes.UpdateUser>(UPDATE_USER);

  if (updating && updateData) {
    setSnackbar({
      open: true,
      message: `Updated user ${updateData.updateUser.name}`,
      severity: "success",
    });
    setUpdating(false);
  }

  async function handleUpdateUser(e: SyntheticEvent) {
    e.preventDefault();

    if (formState.validity.name && formState.validity.email) {
      let variables = {
        name: formState.values.name,
        email: formState.values.email,
        isAdmin: formState.values.isAdmin,
      } as any;

      if (formState.values.password !== "") {
        variables.password = formState.values.password;
      }

      try {
        await updateUser({
          variables: {
            id: parseFloat(String(id)),
            input: variables,
          },
          refetchQueries: [{ query: GET_USERS }],
          awaitRefetchQueries: true,
        });
        setUpdating(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        if (error.statusCode === 401) {
          await authService.logout();
        }

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  if (loading) {
    return <Feedback title="Edit User" message="Loading users..." />;
  }

  if (error) {
    const err = graphqlService.getError(error);

    if (err.statusCode === 404) {
      return <Redirect to="/users" />;
    }

    return (
      <Feedback
        title="Edit User"
        message="Unable to load data from the backend"
      />
    );
  }

  if (!formState.touched.name && !formState.touched.email && data) {
    formState.setField("name", data.user.name);
    formState.setField("email", data.user.email);
    formState.setField("isAdmin", data.user.isAdmin);
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Edit User
          </Typography>

          <form onSubmit={handleUpdateUser} noValidate>
            <TextField
              {...text("name")}
              label="Name"
              placeholder="Name"
              error={formState.errors.name ? true : false}
              helperText={formState.errors.name}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...email("email")}
              label="Email address"
              placeholder="Email address"
              error={formState.errors.email ? true : false}
              helperText={formState.errors.email}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...password("password")}
              label="Password (leave empty to keep unchanged)"
              placeholder="Password"
              error={formState.errors.password ? true : false}
              helperText={formState.errors.password}
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox("isAdmin")["checked"]}
                  id={checkbox("isAdmin")["id"]}
                  name={checkbox("isAdmin")["name"]}
                  onChange={checkbox("isAdmin")["onChange"]}
                  onBlur={checkbox("isAdmin")["onBlur"]}
                  color="primary"
                />
              }
              label="Is Admin"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={loading || updateLoading ? true : false}
            >
              Update User
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
