import { Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import ListFlightClients from "./ListFlightClients";

export default function FlightClients() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListFlightClients />
    </Container>
  );
}
