import {
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import TitleBar from "../TitleBar/TitleBar";
import { filter, highlight } from "../SearchField/SearchField";

import CreateFlightOrder from "./CreateFlightOrder";
import ViewFlightOrder from "./ViewFlightOrder";

import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";
import {
  APIOrder,
  getAllOrders
} from "../../API/Orders/Orders";

type ComponentData = {
  orders: Array<APIOrder> | null;
  countries: APICountriesById | null;
}

export default function ListFlightOrders() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "id" | "client" | "zone" | "orderDate" | "flownDate" | "status";
  }>({ order: "asc", field: "id" });
  let orders: any[] = [];
  let searchOrders: any[] = [];

  const [data, setData] = useState<ComponentData>({ countries: null, orders: null });
  const [selected, setSelected] = useState<APIOrder | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [modal, setModal] = useState<"create" | "view" | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const searchTerms = searchText ? searchText.split("/") : "";

  function loadData() {
    setLoading(true);
    Promise.all([ getAllCountriesById(), getAllOrders() ])
      .then(([ countries, orders ]) => {
        if (countries === null || orders === null)
          throw new Error("Could not load data");
        orders.forEach((order) => (
          order.client && countries[order.client.id + ""] && (order.client.country = countries[order.client.id + ""].name)
        ));
        setData((prev: ComponentData) => ({
          ...prev,
          countries: countries,
          orders: orders
        }));
        setLoading(false);
      })
      .catch((err) => setError(err));
  }

  function handleCreate() {
    setModal("create");
  }

  function handleSearch(str: string) {
    setPage(0);
    setSearchText(str);
  }

  function handleView(order: APIOrder) {
    setSelected(order);
    setModal("view");
  }

  useEffect(loadData, []);

  if (data && data.orders) {
    searchOrders = filter(data.orders, searchTerms, [
      "id", "client.name", "client.country", "zone.name", "order_date", "flown_date", "status.name"
    ]);
    orders = sortBy(searchOrders, [
      (order) => {
        if (sortOrder.field === "id") return order.id;
        if (sortOrder.field === "client")
          return order.client && order.client.name ? order.client.name.toLowerCase() : "";
        if (sortOrder.field === "zone")
          return order.zone ? order.zone.name.toLowerCase() : "";
        if (sortOrder.field === "orderDate")
          return order.order_date;
        if (sortOrder.field === "flownDate")
          return order.flown_date;
        if (sortOrder.field === "status")
          return order.status ? order.status.id : 0;
      },
    ]);
    if (sortOrder.order === "desc") orders = orders.reverse();
    orders = orders.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "id" || field === "client" || field === "zone" ||
        field === "orderDate" || field === "flownDate" || field === "status") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Flight Orders" message="Loading orders..." />;
  }

  if (error) {
    return (
      <Feedback title="Flight Orders" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <TitleBar
        onSearchChange={(str: string) => handleSearch(str)}
        searchText={searchText}
        title="Flight Orders"
      />

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["id", "Order ID"],
                ["client", "Client"],
                ["zone", "Zone (ID)"],
                ["orderDate", "Order Date"],
                ["flownDate", "Flown Date"],
                ["status", "Status"]
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length === 0 ? (
              <TableRow key="0">
                <TableCell>No orders found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              orders.map((order: any) => (
                <TableRow
                  key={order.id + "-" + order.client.id + "-" + order.flown_date}
                  onClick={ () => handleView(order) }
                >
                  <TableCell>{highlight(order.id, searchTerms)}</TableCell>
                  <TableCell>{
                    order.client ? highlight(
                      order.client.name + (order.client.country ? " (" + order.client.country + ")" : ""),
                      searchTerms
                    ) : ""}
                  </TableCell>
                  <TableCell>
                    {order.zone ? highlight(order.zone.name + " (" + order.zone.id + ")", searchTerms) : ""}
                  </TableCell>
                  <TableCell>{highlight(order.order_date, searchTerms)}</TableCell>
                  <TableCell>{highlight(order.flown_date, searchTerms)}</TableCell>
                  <TableCell>
                    {order.status ? highlight(order.status.id + " - " + order.status.name, searchTerms) : ""}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={searchOrders ? searchOrders.length : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />

      <Container
        className={classes.footerContainer}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={handleCreate}
        >
          New Order
        </Button>
      </Container>

      <Modal
        className={classes.modalContainer}
        open={modal === "create"}
      >
        <div>
          <CreateFlightOrder
            data={data}
            onCancel={() => setModal(null)}
            onCreate={() => setModal(null)}
          />
        </div>
      </Modal>

      {selected &&
      <Modal
        className={classes.modalContainer}
        open={modal === "view"}
      >
        <div>
          <ViewFlightOrder
            orderId={selected.id}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>
      }

    </Paper>
  );
}
