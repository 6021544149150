import * as GetUsersTypes from "../graphql/__generated__/GetUsers";
import * as GetCompaniesTypes from "../graphql/__generated__/GetCompanies";
import * as GetAreasTypes from "../graphql/__generated__/GetAreas";

export default class CompanyService {
  getNames(
    users:
      | GetUsersTypes.GetUsers_users[]
      | GetCompaniesTypes.GetCompanies_companies[]
      | GetAreasTypes.GetAreas_areas[]
  ) {
    let n: { [index: string]: string } = {};

    for (let u of users) {
      n[u.id] = u.name;
    }

    return n;
  }
}
