import { useQuery } from "@apollo/react-hooks";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_AREAS } from "../../graphql/Areas";
import * as GetAreasTypes from "../../graphql/__generated__/GetAreas";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

export default function ListAreas() {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "name" | "company";
  }>({ order: "asc", field: "name" });
  let areas: any[] = [];

  const { data, loading, error } = useQuery<GetAreasTypes.GetAreas>(GET_AREAS);

  if (data && data.areas) {
    areas = sortBy(data.areas, [
      (area) => {
        if (sortOrder.field === "name") return area.name.toLowerCase();
        if (sortOrder.field === "company")
          return area.company.name.toLowerCase();
      },
    ]);
    if (sortOrder.order === "desc") areas = areas.reverse();
    areas = areas.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "name" || field === "company") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Areas" message="Loading areas..." />;
  }

  if (error) {
    return (
      <Feedback title="Areas" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        Areas
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["name", "Name"],
                ["company", "Company"],
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.length === 0 ? (
              <TableRow key="0">
                <TableCell>No areas found</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              areas.map((area: any) => (
                <TableRow
                  key={area.id}
                  onClick={() => history.push(`/areas/${area.id}`)}
                >
                  <TableCell>{area.name}</TableCell>
                  <TableCell>{area.company.name}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data ? (data.areas ? data.areas.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
