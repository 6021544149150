import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import { ADD_GEOSERVER, GET_GEOSERVERS } from "../../graphql/Geoservers";
import * as CreateGeoserverTypes from "../../graphql/__generated__/CreateGeoserver";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import SelectArea from "../Forms/SelectArea";
import SelectGeoserverServer from "../Forms/SelectGeoserverServer";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

export default function AddGeoserver() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [selectedGeoserverServer, setSelectedGeoserverServer] = useState<
    string
  >("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [submitted, setSubmitted] = useState(false);
  const [adding, setAdding] = useState(false);

  const [formState, { text }] = useFormState({});

  const [addGeoserver, { data, loading }] = useMutation<
    CreateGeoserverTypes.CreateGeoserver
  >(ADD_GEOSERVER);

  if (adding && data) {
    setSnackbar({
      open: true,
      message: `Added Geoserver namespace ${data.createGeoserver.namespace}`,
      severity: "success",
    });

    setAdding(false);
    setSubmitted(false);
    setSelectedArea("");
    formState.reset();
  }

  async function handleAddGeoserver(e: SyntheticEvent) {
    e.preventDefault();

    setSubmitted(true);

    if (!formState.touched.namespace && !formState.validity.namespace) {
      formState.setFieldError("namespace", "Please fill out this field.");
    }

    if (!formState.touched.username && !formState.validity.username) {
      formState.setFieldError("username", "Please fill out this field.");
    }

    if (!formState.touched.password && !formState.validity.password) {
      formState.setFieldError("password", "Please fill out this field.");
    }

    if (
      formState.validity.namespace &&
      formState.validity.username &&
      formState.validity.password &&
      selectedGeoserverServer !== "" &&
      selectedArea !== ""
    ) {
      try {
        await addGeoserver({
          variables: {
            input: {
              username: formState.values.username,
              password: formState.values.password,
              namespace: formState.values.namespace,
              geoserverServerId: selectedGeoserverServer,
              areaId: selectedArea,
            },
          },
          refetchQueries: [{ query: GET_GEOSERVERS }],
          awaitRefetchQueries: true,
        });
        setAdding(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Add a geoserver server
          </Typography>

          <form onSubmit={handleAddGeoserver} noValidate>
            <TextField
              {...text("namespace")}
              label="Namespace"
              placeholder="Namespace"
              error={formState.errors.namespace ? true : false}
              helperText={formState.errors.namespace}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("username")}
              label="Username"
              placeholder="Username"
              error={formState.errors.username ? true : false}
              helperText={formState.errors.username}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("password")}
              label="Password"
              placeholder="Password"
              error={formState.errors.password ? true : false}
              helperText={formState.errors.password}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <SelectGeoserverServer
              selectedGeoserverServer={selectedGeoserverServer}
              setSelectedGeoserverServer={setSelectedGeoserverServer}
              submitted={submitted}
            />

            <SelectArea
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
              submitted={submitted}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={loading ? true : false}
            >
              Add Geoserver
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
