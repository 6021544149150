import { useQuery } from "@apollo/react-hooks";
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { useState } from "react";
import { GET_USERS } from "../../graphql/Users";
import * as GetUsersTypes from "../../graphql/__generated__/GetUsers";
import CompanyService from "../../services/CompanyService";
import useStyles from "../../styles";

type SelectUsersProps = {
  selectedUsers: string[];
  setSelectedUsers: (users: string[]) => void;
};

export default function SelectUsers(props: SelectUsersProps) {
  const companyService = new CompanyService();
  const classes = useStyles();

  const { data, loading, error } = useQuery<GetUsersTypes.GetUsers>(GET_USERS);
  const [names, setNames] = useState<{ [index: string]: string }>({});

  if (data && Object.keys(names).length === 0) {
    setNames(companyService.getNames(data.users));
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="managers-select-label">Managers</InputLabel>
      <Select
        labelId="managers-select-label"
        id="managers-select"
        value={error ? "Unable to retrieve users" : props.selectedUsers}
        input={<Input />}
        onChange={e => props.setSelectedUsers(e.target.value as string[])}
        renderValue={selected => {
          return (selected as string[]).reduce((acc, curr) => {
            return `${acc}${acc.length > 0 ? "," : ""} ${names[curr]}`;
          }, "");
        }}
        variant="outlined"
        className={classes.formSelect}
        multiple
        fullWidth
        disabled={loading || error ? true : false}
      >
        {data?.users?.map(user => (
          <MenuItem key={user.id} value={user.id}>
            <Checkbox checked={props.selectedUsers.indexOf(user.id) > -1} />
            <ListItemText primary={user.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
