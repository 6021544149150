import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStoreState } from "../../store";
import LoginForm from "./LoginForm";
import NewPasswordForm from "./NewPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { defaultSnackbarState } from "../Snackbar/Snackbar";

export default function Login() {
  const history = useHistory();
  const isAuthenticated = useStoreState((state) => state.authenticated);
  const [authData, setAuthData] = useState({
    email: "",
    password: "",
  });
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [resetPasswordRequired, setResetPasswordRequired] = useState(false);
  const [snackbar, setSnackbar] = useState(defaultSnackbarState);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [history, isAuthenticated]);

  if (newPasswordRequired) {
    return <NewPasswordForm authData={authData} />;
  }

  if (resetPasswordRequired) {
    return <ResetPasswordForm
      authData={{ email: authData.email }}
      onSuccess={() => {
        setResetPasswordRequired(false);
        setSnackbar({
          open: true,
          message: "Password reset successfully, please login with your new password",
          severity: "success"
        });
      }}
    />;
  }

  return (
    <LoginForm
      setAuthData={setAuthData}
      setNewPasswordRequired={setNewPasswordRequired}
      setResetPasswordRequired={setResetPasswordRequired}
      snackbar={snackbar}
    />
  );
}
