import {
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import TitleBar from "../TitleBar/TitleBar";
import { filter, highlight } from "../SearchField/SearchField";

import CreateFlightZone from "./CreateFlightZone";
import ViewFlightZone from "./ViewFlightZone";

import {
  APIZone,
  getAllZones
} from "../../API/Orders/Zones";
import {
  APIClientsById,
  getAllClientsById
} from "../../API/Orders/Clients";
import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";

type ComponentData = {
  zones: Array<APIZone> | null;
  clients: APIClientsById | null;
  countries: APICountriesById | null;
}

export default function ListFlightZones() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "id" | "client" | "name" | "size";
  }>({ order: "asc", field: "id" });
  let zones: any[] = [];
  let searchZones: any[] = [];

  const [data, setData] = useState<ComponentData>({ zones: null, clients: {}, countries: {} });
  const [selected, setSelected] = useState<APIZone | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [modal, setModal] = useState<"create" | "view" | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const searchTerms = searchText ? searchText.split("/") : "";

  function loadData() {
    setLoading(true);
    Promise.all([ getAllZones(), getAllClientsById(), getAllCountriesById() ])
      .then(([ zones, clientsById, countriesById ]) => {
        if (zones === null || clientsById === null || countriesById === null)
          throw new Error("Could not load data");
        zones.forEach((zone) => zone.client = zone.client_id ? clientsById[zone.client_id + ""] : null);
        Object.values(clientsById).forEach((client) => (
          countriesById[client.country_id + ""] ? client.country = countriesById[client.country_id + ""] : null
        ));
        setData((prev: ComponentData) => ({
          ...prev,
          zones: zones,
          clients: clientsById,
          countries: countriesById
        }));
        setLoading(false);
      })
      .catch((err) => setError(err));
  }

  function handleCreate() {
    setModal("create");
  }

  function handleSearch(str: string) {
    setPage(0);
    setSearchText(str);
  }

  function handleView(zone: APIZone) {
    setSelected(zone);
    setModal("view");
  }

  useEffect(loadData, []);

  if (data && data.zones) {
    searchZones = filter(data.zones, searchTerms, [ "id", "client.name", "client.country.name", "name" ]);
    zones = sortBy(searchZones, [
      (zone) => {
        if (sortOrder.field === "id") return zone.id;
        if (sortOrder.field === "client")
          return zone.client && zone.client.name ? zone.client.name.toLowerCase() : "";
        if (sortOrder.field === "name")
          return zone.name ? zone.name.toLowerCase() : "";
        if (sortOrder.field === "size")
          return zone.size;
      },
    ]);
    if (sortOrder.order === "desc") zones = zones.reverse();
    zones = zones.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "id" || field === "client" || field === "name" || field === "size") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Flight Zones" message="Loading zones..." />;
  }

  if (error) {
    return (
      <Feedback title="Flight Zones" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <TitleBar
        onSearchChange={(str: string) => handleSearch(str)}
        searchText={searchText}
        title="Flight Zones"
      />

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["id", "Zone ID"],
                ["client", "Client"],
                ["name", "Name"],
                ["size", "Size"]
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {zones.length === 0 ? (
              <TableRow key="0">
                <TableCell>No zones found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              zones.map((zone: any) => (
                <TableRow
                  key={zone.id}
                  onClick={ () => handleView(zone) }
                >
                  <TableCell>{highlight(zone.id, searchTerms)}</TableCell>
                  <TableCell>
                    {zone.client ? highlight(
                      zone.client.name + (zone.client.country ? " (" + zone.client.country.name + ")" : ""),
                      searchTerms
                    ) : ""}
                  </TableCell>
                  <TableCell>{highlight(zone.name, searchTerms)}</TableCell>
                  <TableCell>{zone.size}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data && searchZones ? searchZones.length : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />

      <Container
        className={classes.footerContainer}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={handleCreate}
        >
          New Zone
        </Button>
      </Container>

      <Modal
        className={classes.modalContainerSmall}
        open={modal === "create"}
      >
        <div>
          <CreateFlightZone
            data={data}
            onCancel={() => setModal(null)}
            onCreate={() => setModal(null)}
          />
        </div>
      </Modal>

      <Modal
        className={classes.modalContainerSmall}
        open={modal === "view"}
      >
        <div>
          <ViewFlightZone
            zone={selected}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>

    </Paper>
  );
}
