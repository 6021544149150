import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  APIClient,
  getClient
} from "../../API/Orders/Clients";
import {
  APICountriesById,
  getAllCountriesById
  // getCountry TODO: use this when implemented in backend
} from "../../API/Orders/Countries";

export default function ViewFlightClient(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [client, setClient] = useState<APIClient | null>(null);

  useEffect(() => {
    if (props.client)
      setClient(props.client);
    else if (props.clientId) {
      getClient(props.clientId)
        .then((result: APIClient | null) => {
          if (result === null)
            return;
          getAllCountriesById()
            .then((countries: APICountriesById | null) => {
              if (countries)
                result.country = countries[result.country_id];
              setClient(result);
            })
            .catch((err: any) => {
              setClient(result);
              console.log(err)
            });
        })
        .catch((err: any) => console.log(err));
    }
  }, [ props.client, props.clientId ]);

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Client: {client && client.name}
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            color="primary"
            className={classes.viewTitle}
          >
            Client ID: {client ? client.id : props.clientId}
          </Typography>

          {client === null &&
            <Container className={classes.formContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Loading data ...
              </Typography>
            </Container>
          }

          {client !== null &&
          <Container className={classes.formContainer}>

            <Box className={classes.formSectionLast}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Client Info
              </Typography>
              <TableContainer>
                <Table className={classes.viewTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>{client.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Processing Name</TableCell>
                      <TableCell>{client.processing_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Contact Name</TableCell>
                      <TableCell>{client.contact_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Contact Address</TableCell>
                      <TableCell>{client.contact_address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Country</TableCell>
                      <TableCell>{client.country.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </Container>
          }

          <Box className={classes.formButtons} >
            <Button
              variant="contained"
              color="secondary"
              className={classes.formButtonMultiple}
              onClick={props.onCancel}
            >
              Close
            </Button>
          </Box>

        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
