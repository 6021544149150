import React from "react";
import { GatewayApi } from "./gateway-api";

const AppContext = React.createContext({
  gatewayApi: new GatewayApi("", "")
});

export const AppProvider = AppContext.Provider;

export default AppContext;
