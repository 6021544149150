import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIOrder = {
  id: number;
  order_date: string;
  client: {
    id: number;
    name: string;
    country?: string;
  };
  zone: {
    id: number;
    name: string;
    remarks: string;
  };
  flown_date: string;
  status: {
    id: number;
    name: string;
  };
};

export type APIOrdersById = { [ key: string ] : APIOrder };

export type SingleAPIOrder = {
  id: number;
  order_date: string;
  client: {
    id: number;
    name: string;
  };
  zone: {
    id: number;
    name: string;
    remarks: string;
    size: number;
  };
  flown_date: Array<string>;
  status: {
    id: number;
    name: string;
  };
  flights: Array<number>;
  deliverables: Array<number>;
};

export type CreateAPIOrder = {
  area_type: number;
  date: string;
  status: number;
  zone_id: number;
  deliverables: Array<number>;
};

export async function getAllOrders(
  paginationToken?: number
): Promise<Array<APIOrder> | null> {
  let url = "orders";
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    if (response.pagination_token !== undefined) {
      const moreOrders = await getAllOrders(response.pagination_token);

      if (moreOrders === null) {
        return null;
      }

      return [...response.orders, ...moreOrders];
    } else {
      return response.orders;
    }
  } catch (err: any) {
    return null;
  }
}

export async function getAllOrdersById(): Promise<APIOrdersById | null> {
  try {
    const orders = await getAllOrders();
    if (orders === null)
      return null;
    const ret: { [ key: string] : APIOrder } = {};
    orders.forEach((order) => ret[order.id + ""] = order);
    return ret;
  }
  catch (err: any) {
    throw err;
  }
}

export async function getOrder(id: number): Promise<SingleAPIOrder> {
  let url = `orders/${id}`;
  try {
    return await api.get(url);
  }
  catch (err: any) {
    throw err;
  }
}

export async function createOrder(
  payload: CreateAPIOrder
): Promise<any> {
  try {
    const response: any = await api.post(`orders`, payload);
    return response;
  }
  catch (err: any) {
    throw err;
  }
}

export async function attachFlightsToOrder(
  orderId: number,
  flightIds: Array<number>
): Promise<string> {
  const payload = {
    flight_ids: flightIds,
    order_ids: [orderId],
  };

  try {
    await api.patch("order_flight_xref", payload);
    return "Added flights";
  }
  catch (err: any) {
    throw err;
  }
}
