import { useQuery } from "@apollo/react-hooks";
import { Card, CardContent, Typography } from "@material-ui/core";
import QRCode from "qrcode";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APP_URL } from "../../constants";
import { GET_QR } from "../../graphql/Qrs";
import * as GetQrTypes from "../../graphql/__generated__/GetQr";
import Feedback from "../Feedback/Feedback";

interface GenerateQrParams {
  id: string;
}

export default function GenerateQr() {
  const { id } = useParams<GenerateQrParams>();
  const [qrCode, setQrCode] = useState<any>(null);

  const { data, loading, error } = useQuery<GetQrTypes.GetQr>(GET_QR, {
    variables: { id: parseFloat(String(id)) },
  });

  useEffect(() => {
    if (data) {
      const url = `${APP_URL}/login?qr=${data.qr.code}`;
      QRCode.toString(url, { type: "svg" }, (err, svg) => {
        if (err) {
          console.log(err);
          setQrCode(null);
        } else {
          setQrCode(svg);
        }
      });
    }
  }, [data]);

  if (loading) {
    return <Feedback title="QR Code" message="Loading data..." />;
  }

  if (error) {
    return (
      <Feedback
        title="QR Code"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" component="h1" color="primary">
          QR Code
        </Typography>

        <a href={`data:image/svg+xml;base64,${btoa(qrCode)}`} download="qr.svg">
          <img
            src={`data:image/svg+xml;base64,${btoa(qrCode)}`}
            alt="QR Code"
          />
        </a>
      </CardContent>
    </Card>
  );
}
