import { useQuery } from "@apollo/react-hooks";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_USERS } from "../../graphql/Users";
import * as GetUsersTypes from "../../graphql/__generated__/GetUsers";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

export default function ListUsers() {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "name" | "email" | "lastLogin";
  }>({ order: "asc", field: "name" });
  let users: any[] = [];

  const { data, loading, error } = useQuery<GetUsersTypes.GetUsers>(GET_USERS);

  if (data && data.users) {
    users = sortBy(data.users, [
      (user) => {
        if (sortOrder.field === "name") return user.name.toLowerCase();
        if (sortOrder.field === "email") return user.email.toLowerCase();
        if (sortOrder.field === "lastLogin")
          return user.lastLogin ? user.lastLogin : 0;
      },
    ]);
    if (sortOrder.order === "desc") users = users.reverse();
    users = users.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "name" || field === "email" || field === "lastLogin") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Users" message="Loading users..." />;
  }

  if (error) {
    return (
      <Feedback title="Users" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        Users
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["name", "Name"],
                ["email", "Email"],
                ["lastLogin", "Last Login"],
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length === 0 ? (
              <TableRow key="0">
                <TableCell>No users found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              users.map((user: any) => (
                <TableRow
                  key={user.id}
                  onClick={() => history.push(`/users/${user.id}`)}
                >
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.lastLogin
                      ? new Date(user.lastLogin).toDateString()
                      : ""}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data ? (data.users ? data.users.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
