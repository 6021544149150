import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import BusinessIcon from "@material-ui/icons/Business";
import CropFreeIcon from "@material-ui/icons/CropFree";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FlightIcon from '@mui/icons-material/Flight';
import FlightJobIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlightOrderIcon from '@mui/icons-material/AssignmentLate';
import FlightZoneIcon from '@mui/icons-material/Map';
import FlightListIcon from '@mui/icons-material/FormatListBulleted';
import FlightClientIcon from '@mui/icons-material/Business';
import PeopleIcon from "@material-ui/icons/People";
import PhotoIcon from "@material-ui/icons/Photo";
import StorageIcon from "@material-ui/icons/Storage";
import WbCloudyIcon from "@material-ui/icons/WbCloudy";
import React, {
  SyntheticEvent,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/AuthService";
import useStyles from "../../styles";



export default function Sidebar() {
  const authService = new AuthService();
  const classes = useStyles();
  const history = useHistory();


  const [ submenu, setSubmenu ] = useState(getOpenMenu(window.location.pathname));

  async function handleLogout(e: SyntheticEvent) {
    e.preventDefault();
    authService.logout();
  }

  function getOpenMenu(location: string): string {
    switch (location) {
      case "/flights":
      case "/flightclients":
      case "/flightjobs":
      case "/flightorders":
      case "/flightzones":
        return "flights";
      default:
        return "";
    }
  }

  function toggleNestedMenu(menu: string) {
    setSubmenu((prev) => prev === menu ? "" : menu);
  }

  return (
    <Drawer
      className={classes.sidebarDrawer}
      variant="permanent"
      classes={{
        paper: classes.sidebarPaper,
      }}
      anchor="left"
    >
      <List disablePadding>
        <ListItem button key="users" onClick={() => history.push("/users")}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>

        <ListItem
          button
          key="companies"
          onClick={() => history.push("/companies")}
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Companies" />
        </ListItem>

        <ListItem button key="areas" onClick={() => history.push("/areas")}>
          <ListItemIcon>
            <PhotoIcon />
          </ListItemIcon>
          <ListItemText primary="Areas" />
        </ListItem>

        <ListItem button key="flights" onClick={() => toggleNestedMenu("flights")}>
          <ListItemIcon>
            <FlightIcon />
          </ListItemIcon>
          <ListItemText primary="Flights" />
        </ListItem>

        <Collapse in={submenu==="flights"} timeout="auto" unmountOnExit>
          <List component="div" style={{paddingLeft: "10px"}} disablePadding>

            <ListItem button
              key="jobs"
              onClick={() => history.push("/flightjobs")}
            >
              <ListItemIcon>
                <FlightJobIcon />
              </ListItemIcon>
              <ListItemText primary="Jobs" />
            </ListItem>

            <ListItem button
              key="orders"
              onClick={() => history.push("/flightorders")}
            >
              <ListItemIcon>
                <FlightOrderIcon />
              </ListItemIcon>
              <ListItemText primary="Orders" />
            </ListItem>

            <ListItem button
              key="zones"
              onClick={() => history.push("/flightzones")}
            >
              <ListItemIcon>
                <FlightZoneIcon />
              </ListItemIcon>
              <ListItemText primary="Zones" />
            </ListItem>

            <ListItem button
              key="flightlist"
              onClick={() => history.push("/flights")}
            >
              <ListItemIcon>
                <FlightListIcon />
              </ListItemIcon>
              <ListItemText primary="Flights" />
            </ListItem>

            <ListItem button
              key="clients"
              onClick={() => history.push("/flightclients")}
            >
              <ListItemIcon>
                <FlightClientIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItem>

          </List>
        </Collapse>

        <ListItem
          button
          key="geoservers"
          onClick={() => history.push("/geoservers")}
        >
          <ListItemIcon>
            <WbCloudyIcon />
          </ListItemIcon>
          <ListItemText primary="Geoservers" />
        </ListItem>

        <ListItem
          button
          key="geoserverServers"
          onClick={() => history.push("/geoserverservers")}
        >
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="Geoserver Servers"></ListItemText>
        </ListItem>

        <ListItem button key="qrs" onClick={() => history.push("/qrs")}>
          <ListItemIcon>
            <CropFreeIcon />
          </ListItemIcon>
          <ListItemText primary="QR Logins" />
        </ListItem>

        <ListItem button key="bucketTransfer" onClick={() => history.push("/buckettransfer")}>
          <ListItemIcon>
            <DriveFileMoveIcon />
          </ListItemIcon>
          <ListItemText primary="Bucket Transfer" />
        </ListItem>

        {false &&
        <ListItem button key="uploadUsers" onClick={() => history.push("/uploadusers")}>
          <ListItemIcon>
            <AddAPhotoIcon />
          </ListItemIcon>
          <ListItemText primary="Upload Users" />
        </ListItem>
        }

        <ListItem button key="logout" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
}
