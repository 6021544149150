import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIWorkteams = {
  arn: string;
  name: string;
  description: string;
};

export type APIWorkteamsByArn = { [ key: string ] : APIWorkteams };

export async function getAllWorkteams(): Promise<Array<APIWorkteams>> {
  try {
    return await api.get(`workteams`);
  }
  catch (err: any) {
    throw err;
  }
}

export async function getAllWorkteamsByArn(): Promise<APIWorkteamsByArn> {
  try {
    const workteams = await getAllWorkteams();
    const ret: { [ key: string] : APIWorkteams } = {};
    workteams.forEach((workteam) => ret[workteam.arn + ""] = workteam);
    return ret;
  }
  catch (err: any) {
    throw err;
  }
}
