import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import DeleteArea from "./DeleteArea";
import ListGeoservers from "./ListGeoservers";
import ShowArea from "./ShowArea";

export default function Area() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ShowArea />
      <Box mt={4} />
      <ListGeoservers />
      <Box mt={4} />
      <DeleteArea />
    </Container>
  );
}
