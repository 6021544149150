import { Button, Container, TextField, Typography } from "@material-ui/core";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { poolData } from "../../constants";
import { useStoreActions } from "../../store";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface LoginFormProps {
  setAuthData(data: { email: string; password: string }): void;
  setNewPasswordRequired(required: boolean): void;
  setResetPasswordRequired(required: boolean): void;
  snackbar: any;
}

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default function LoginForm(props: LoginFormProps) {
  const classes = useStyles();
  const history = useHistory();
  const [formState, { email, password }] = useFormState();
  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);

  useEffect(() => {
    if (props.snackbar)
      setSnackbar(props.snackbar);
  }, [props.snackbar])

  const [submitted, setSubmitted] = useState(false);

  async function makeLoginRequest(email: string, password: string) {
    props.setAuthData({ email: email, password: password });

    const authenticationData = {
      Username: email,
      Password: password,
    };

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setLoggedIn(undefined);
        history.push("/");
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        props.setNewPasswordRequired(true);
      },
      onFailure: (err) => {
        if (err.name === "PasswordResetRequiredException") {
          props.setResetPasswordRequired(true);
          return;
        }
        setSubmitted(false);
        setSnackbar({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    });
  }

  async function handleLogin(e: SyntheticEvent) {
    e.preventDefault();
    setSubmitted(true);

    if (!formState.touched.email && !formState.validity.email) {
      formState.setFieldError("email", "Please fill out this field.");
      setSubmitted(false);
    }

    if (!formState.touched.password && !formState.validity.password) {
      formState.setFieldError("password", "Please fill out this field.");
      setSubmitted(false);
    }

    if (formState.validity.email && formState.validity.password) {
      await makeLoginRequest(formState.values.email, formState.values.password);
    }
  }

  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.loginContainer}>
        <Typography variant="h4" component="h1" align="center">
          Login
        </Typography>

        <form className={classes.loginForm} onSubmit={handleLogin} noValidate>
          <TextField
            {...email("email")}
            label="Email address"
            placeholder="Email address"
            error={formState.errors.email ? true : false}
            helperText={formState.errors.email}
            variant="outlined"
            fullWidth
            required
            margin="normal"
          />

          <TextField
            {...password("password")}
            label="Password"
            placeholder="Password"
            error={formState.errors.password ? true : false}
            helperText={formState.errors.password}
            variant="outlined"
            fullWidth
            required
            margin="normal"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.loginButton}
            disabled={submitted}
            fullWidth
          >
            Login
          </Button>
        </form>
      </Container>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
