import { useQuery } from "@apollo/react-hooks";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_COMPANIES } from "../../graphql/Companies";
import * as GetCompaniesTypes from "../../graphql/__generated__/GetCompanies";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

export default function ListCompanies() {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
  }>({ order: "asc" });
  let companies: any[] = [];

  const { data, loading, error } =
    useQuery<GetCompaniesTypes.GetCompanies>(GET_COMPANIES);

  if (data && data.companies) {
    companies = sortBy(data.companies, [
      (company) => {
        return company.name.toLowerCase();
      },
    ]);
    if (sortOrder.order === "desc") companies = companies.reverse();
    companies = companies.slice(page * 10, page * 10 + 10);
  }

  function updateOrder() {
    if (sortOrder.order === "asc") {
      setSortOrder({ order: "desc" });
    } else {
      setSortOrder({ order: "asc" });
    }
  }

  if (loading) {
    return <Feedback title="Companies" message="Loading companies..." />;
  }

  if (error) {
    return (
      <Feedback
        title="Companies"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        Companies
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sortOrder.order} key="name">
                <TableSortLabel
                  active={true}
                  direction={sortOrder.order}
                  onClick={() => updateOrder()}
                >
                  Name
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.length === 0 ? (
              <TableRow key="0">
                <TableCell>No companies found</TableCell>
              </TableRow>
            ) : (
              companies.map((company: any) => (
                <TableRow
                  key={company.id}
                  onClick={() => history.push(`/companies/${company.id}`)}
                >
                  <TableCell>{company.name}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data ? (data.companies ? data.companies.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
