import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import find from "lodash/find";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { GET_AREA, GET_AREAS, UPDATE_AREA } from "../../graphql/Areas";
import * as GetAreaTypes from "../../graphql/__generated__/GetArea";
import * as UpdateAreaTypes from "../../graphql/__generated__/UpdateArea";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import SelectCompany from "../Forms/SelectCompany";
import SelectUsers from "../Forms/SelectUsers";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface ShowAreaParams {
  id: string;
}

export default function ShowArea() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<ShowAreaParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [updating, setUpdating] = useState(false);

  const [formState, { text }] = useFormState({ name: "" });

  const { data: areaData, loading: areaLoading, error: areaError } = useQuery<
    GetAreaTypes.GetArea
  >(GET_AREA, {
    variables: { id: parseFloat(String(id)) },
  });
  const [
    updateArea,
    { data: updateAreaData, loading: updateAreaLoading },
  ] = useMutation<UpdateAreaTypes.UpdateArea>(UPDATE_AREA);

  if (!formState.touched.name && areaData) {
    formState.setField("name", areaData.area.name);
    setSelectedUsers(
      areaData.area.users.reduce((acc, curr) => {
        return [...acc, curr.id];
      }, [] as string[])
    );
    setSelectedCompany(areaData.area.company.id);
  }

  async function handleUpdateArea(e: SyntheticEvent) {
    e.preventDefault();

    if (formState.validity.name && areaData) {
      const addedUsers = findAddedUsers(selectedUsers, areaData);
      const removedUsers = findRemovedUsers(selectedUsers, areaData);

      try {
        await updateArea({
          variables: {
            id: parseFloat(String(id)),
            input: {
              name: formState.values.name,
              companyId: selectedCompany,
              addUserIds: addedUsers,
              removeUserIds: removedUsers,
            },
          },
          refetchQueries: [{ query: GET_AREAS }],
          awaitRefetchQueries: true,
        });
        setUpdating(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  if (updating && updateAreaData) {
    setSnackbar({
      open: true,
      message: `Updated area ${updateAreaData.updateArea.name}`,
      severity: "success",
    });
    setUpdating(false);
  }

  if (areaLoading) {
    return <Feedback title="Edit Area" message="Loading data..." />;
  }

  if (areaError) {
    const err = graphqlService.getError(areaError);

    if (err.statusCode === 404) {
      return <Redirect to="/areas" />;
    }

    return (
      <Feedback
        title="Edit Area"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Edit Area
          </Typography>

          <form onSubmit={handleUpdateArea} noValidate>
            <TextField
              {...text("name")}
              label="Name"
              placeholder="Name"
              error={formState.errors.name ? true : false}
              helperText={formState.errors.name}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <SelectUsers
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />

            <SelectCompany
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              submitted={true}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={updateAreaLoading ? true : false}
            >
              Update Area
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}

function findAddedUsers(selectedUsers: string[], data: GetAreaTypes.GetArea) {
  return selectedUsers.reduce((acc, curr) => {
    if (find(data.area.users, { id: curr }) === undefined) {
      acc.push(curr);
    }

    return acc;
  }, [] as string[]);
}

function findRemovedUsers(selectedUsers: string[], data: GetAreaTypes.GetArea) {
  return data.area.users.reduce((acc, curr) => {
    if (selectedUsers.indexOf(curr.id) === -1) {
      acc.push(curr.id);
    }

    return acc;
  }, [] as string[]);
}
