import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  APIFlight,
  getFlight
} from "../../API/Orders/Flights";
import {
  APICountriesById,
  getAllCountriesById
  // getCountry TODO: use this when implemented in backend
} from "../../API/Orders/Countries";

export default function ViewFlight(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [flight, setFlight] = useState<APIFlight | null>(null);

  useEffect(() => {
    if (props.flight)
      setFlight(props.flight);
    else if (props.flightId) {
      getFlight(props.flightId)
        .then((result: APIFlight | null) => {
          if (result === null)
            return;
          getAllCountriesById()
            .then((countries: APICountriesById | null) => {
              if (countries)
                result.country = countries[result.country_id];
              setFlight(result);
            })
            .catch((err: any) => {
              setFlight(result);
              console.log(err)
            });
        })
        .catch((err: any) => console.log(err));
    }
  }, [ props.flight, props.flightId ]);

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Flight ID: {flight ? flight.flight_id : props.flightId}
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            color="primary"
            className={classes.viewTitle}
          >
            <span>
			  {flight && (new Date(flight.flight_date)).toDateString() + ", " + flight.country.name}
			</span>
			<br/>
            <span>
              {flight && flight.flight_start + " - " + flight.flight_end}
			</span>
          </Typography>

          {flight === null &&
            <Container className={classes.formContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Loading data ...
              </Typography>
            </Container>
          }

          {flight !== null &&
          <Container className={classes.formContainer}>

            <Box className={classes.formSectionLast}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Flight Info
              </Typography>
              <TableContainer>
                <Table className={classes.viewTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Trigger Count</TableCell>
                      <TableCell>{flight.trigger_count}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>RGB Count</TableCell>
                      <TableCell>{flight.rgb_count}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>NIR Camera Used</TableCell>
                      <TableCell>{flight.nir_camera_used ? "Yes" : "No"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>NIR Count</TableCell>
                      <TableCell>{flight.nir_count}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Altitude</TableCell>
                      <TableCell>{flight.altitude + " ft"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Camera Set</TableCell>
                      <TableCell>{flight.camera_set}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Weather</TableCell>
                      <TableCell>{flight.weather}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Remarks</TableCell>
                      <TableCell>{flight.remarks}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </Container>
          }

          <Box className={classes.formButtons} >
            <Button
              variant="contained"
              color="secondary"
              className={classes.formButtonMultiple}
              onClick={props.onCancel}
            >
              Close
            </Button>
          </Box>

        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
