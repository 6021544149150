import {
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip
} from "@material-ui/core";
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PendingIcon from '@mui/icons-material/Pending';
import sortBy from "lodash/sortBy";
import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import TitleBar from "../TitleBar/TitleBar";
import { filter, highlight } from "../SearchField/SearchField";

import CreateFlightJob from "./CreateFlightJob";
import ViewFlightJob from "./ViewFlightJob";

import {
  APIClientsById,
  getAllClientsById
} from "../../API/Orders/Clients";
import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";
import {
  APIDem,
  getAllDems
} from "../../API/Orders/Dems";
import {
  APIJob,
  getAllJobs,
  resumeJob,
  stopJob
} from "../../API/Orders/Jobs";
import {
  APIOrdersById,
  getAllOrdersById
} from "../../API/Orders/Orders";
import {
  APIWorkteamsByArn,
  getAllWorkteamsByArn
} from "../../API/Orders/Workteams";

type ComponentData = {
  clients: APIClientsById | null;
  countries: APICountriesById | null;
  dems: Array<APIDem>;
  jobs: Array<APIJob> | null;
  orders: APIOrdersById;
  workteams: APIWorkteamsByArn;
}

export default function ListFlightJobs() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "id" | "client" | "status";
  }>({ order: "asc", field: "id" });
  let jobs: any[] = [];
  let searchJobs: any[] = [];

  const [data, setData] = useState<ComponentData>({
    clients: null, countries: null, dems: [], jobs: null, orders: {}, workteams: {}
  });
  const [selected, setSelected] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [stopping, setStopping] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [modal, setModal] = useState<"create" | "view" | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const searchTerms = searchText ? searchText.split("/") : "";

  function loadData(reload?: boolean) {
    if (reload !== true)
      setLoading(true);
    Promise.all([ getAllJobs(), getAllClientsById(), getAllCountriesById() ])
      .then(([ jobs, clientsById, countriesById ]) => {
        if (jobs === null || clientsById === null || countriesById === null)
          throw new Error("Could not load data");
        jobs.forEach((job) => job.client = clientsById[job.client_id + ""]);
        Object.values(clientsById).forEach((client) => client.country = countriesById[client.country_id + ""]);
        setData((prev: ComponentData) => ({
          ...prev,
          clients: clientsById,
          countries: countriesById,
          jobs: jobs
        }));
        setLoading(false);
        setStopping(null);
      })
      .catch((err) => setError(err));
  }

  function loadAdditionalData() {
    Promise.all([ getAllDems(), getAllOrdersById(), getAllWorkteamsByArn() ])
      .then(([ dems, orders, workteams ]) => {
        if (dems === null || orders === null || workteams === null)
          throw new Error("Could not load data");
        setData((prev: ComponentData) => ({
          ...prev,
          dems: dems,
          orders: orders,
          workteams: workteams
        }));
      })
      .catch((err) => setError(err));
  }

  function handleCreate() {
    loadAdditionalData();
    setModal("create");
  }

  function handleResume(e: any, id: string) {
    if (stopping)
      return;
    setStopping(id);
    e.preventDefault();
    e.stopPropagation();
    resumeJob(id)
      .then((result) => {
        loadData(true);
      })
      .catch((err) => {
        console.log(err);
        setStopping(null);
      })
  }

  function handleSearch(str: string) {
    setPage(0);
    setSearchText(str);
  }

  function handleStop(e: any, id: string) {
    if (stopping)
      return;
    setStopping(id);
    e.preventDefault();
    e.stopPropagation();
    stopJob(id)
      .then((result) => {
        loadData(true);
      })
      .catch((err) => {
        console.log(err);
        setStopping(null);
      })
  }

  function handleView(id: string) {
    setSelected(id + "");
    setModal("view");
  }

  useEffect(loadData, []);

  if (data && data.jobs) {
    searchJobs = filter(data.jobs, searchTerms, [
      "id", "client.name", "client.country.name", "status.message"
    ]);
    jobs = sortBy(searchJobs, [
      (job) => {
        if (sortOrder.field === "id") return job.id;
        if (sortOrder.field === "client")
          return job.client ? job.client.name.toLowerCase() : "";
        if (sortOrder.field === "status")
          return job.status ? job.status.message.toLowerCase() : "";
      },
    ]);
    if (sortOrder.order === "desc") jobs = jobs.reverse();
    jobs = jobs.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "id" || field === "client" || field === "status") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Flight Jobs" message="Loading jobs..." />;
  }

  if (error) {
    return (
      <Feedback title="Flight Jobs" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <TitleBar
        onSearchChange={(str: string) => handleSearch(str)}
        searchText={searchText}
        title="Flight Jobs"
      />

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["id", "Job ID"],
                ["client", "Client"],
                ["status", "Status"],
                ["stop", ""]
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.length === 0 ? (
              <TableRow key="0">
                <TableCell>No jobs found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              jobs.map((job: any) => (
                <TableRow
                  key={job.id}
                  onClick={ () => handleView(job.id) }
                >
                  <TableCell>{highlight(job.id, searchTerms)}</TableCell>
                  <TableCell>
                    {job.client && highlight(
                      job.client.name + (job.client.country ? " (" + job.client.country.name + ")" : ""),
                      searchTerms
                    )}
                  </TableCell>
                  <TableCell>{job.status && highlight(job.status.message, searchTerms)}</TableCell>
                  <TableCell>
                    {false && stopping !== job.id && job.status && job.status.message === "Job was stopped before completion" &&
                      <Tooltip title="resume">
                        <PlayCircleIcon
                          color="primary"
                          onClick={(e: any) => handleResume(e, job.id)}
                          sx={{ "&:hover": { cursor: "pointer" }}}
                        />
                      </Tooltip>
                    }
                    {stopping !== job.id && job.status && job.status.message !== "Job was stopped before completion" &&
                      <Tooltip title="stop">
                        <StopCircleIcon
                          color="warning"
                          onClick={(e: any) => handleStop(e, job.id)}
                          sx={{ "&:hover": { cursor: "pointer" }}}
                        />
                      </Tooltip>
                    }
                    {stopping === job.id &&
                      <Tooltip title="please wait ...">
                        <PendingIcon
                          color="info"
                        />
                      </Tooltip>
                    }
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={searchJobs ? searchJobs.length : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />

      <Container
        className={classes.footerContainer}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={handleCreate}
        >
          New Job
        </Button>
      </Container>

      <Modal
        className={classes.modalContainer}
        open={modal === "create"}
      >
        <div>
          <CreateFlightJob
            data={data}
            onCancel={() => setModal(null)}
            onCreate={() => setModal(null)}
          />
        </div>
      </Modal>

      <Modal
        className={classes.modalContainer}
        open={modal === "view"}
      >
        <div>
          <ViewFlightJob
            jobId={selected}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>

    </Paper>
  );
}
