import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import AddCompany from "./AddCompany";
import ListCompanies from "./ListCompanies";

export default function Companies() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListCompanies />
      <Box mt={4} />
      <AddCompany />
    </Container>
  );
}
