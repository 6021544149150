import { useQuery } from "@apollo/react-hooks";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { useState } from "react";
import { GET_COMPANIES } from "../../graphql/Companies";
import * as GetCompaniesTypes from "../../graphql/__generated__/GetCompanies";
import CompanyService from "../../services/CompanyService";
import useStyles from "../../styles";

type SelectCompanyProps = {
  selectedCompany: string;
  setSelectedCompany: (company: string) => void;
  submitted: boolean;
};

export default function SelectCompany(props: SelectCompanyProps) {
  const companyService = new CompanyService();
  const classes = useStyles();

  const { data, loading, error } = useQuery<GetCompaniesTypes.GetCompanies>(
    GET_COMPANIES
  );
  const [names, setNames] = useState<{ [index: string]: string }>({});

  if (data && Object.keys(names).length === 0) {
    setNames(companyService.getNames(data.companies));
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="managers-select-label">Companies</InputLabel>
      <Select
        labelId="companies-select-label"
        id="companies-select"
        value={
          error
            ? "Unable to retrieve companies"
            : Object.keys(names).length === 0
            ? ""
            : props.selectedCompany
        }
        input={<Input />}
        onChange={e => props.setSelectedCompany(e.target.value as string)}
        renderValue={selected => {
          return names[selected as string];
        }}
        variant="outlined"
        className={classes.formSelect}
        fullWidth
        disabled={loading || error ? true : false}
        error={props.submitted && props.selectedCompany === ""}
      >
        {data?.companies?.map(company => (
          <MenuItem key={company.id} value={company.id}>
            <Checkbox checked={props.selectedCompany === company.id} />
            <ListItemText primary={company.name} />
          </MenuItem>
        ))}
      </Select>
      {props.submitted && props.selectedCompany === "" && (
        <FormHelperText error={true}>A company is required</FormHelperText>
      )}
    </FormControl>
  );
}
