import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { GET_GEOSERVERS } from "../../graphql/Geoservers";
import {
  GET_GEOSERVER_SERVER,
  UPDATE_GEOSERVER_SERVER,
} from "../../graphql/GeoserverServers";
import * as GetGeoserverServerTypes from "../../graphql/__generated__/getGeoserverServer";
import * as UpdateGeoserverServerTypes from "../../graphql/__generated__/UpdateGeoserverServer";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface ShowGeoserverServerParams {
  id: string;
}

export default function ShowGeoserverServer() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<ShowGeoserverServerParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [updating, setUpdating] = useState(false);

  const [formState, { text, url }] = useFormState({
    server: "",
    adminUsername: "",
    adminPassword: "",
  });

  const {
    data: geoserverServerData,
    loading: geoserverServerLoading,
    error: geoserverServerError,
  } = useQuery<GetGeoserverServerTypes.getGeoserverServer>(
    GET_GEOSERVER_SERVER,
    { variables: { id: parseFloat(String(id)) } }
  );

  const [
    updateGeoserverServer,
    { data: updateGeoserverServerData, loading: updateGeoserverServerLoading },
  ] = useMutation<UpdateGeoserverServerTypes.UpdateGeoserverServer>(
    UPDATE_GEOSERVER_SERVER
  );

  if (
    !formState.touched.server &&
    !formState.touched.adminUsername &&
    !formState.touched.adminPassword &&
    geoserverServerData
  ) {
    formState.setField("server", geoserverServerData.geoserverServer.server);
    formState.setField(
      "adminUsername",
      geoserverServerData.geoserverServer.adminUsername
    );
    formState.setField(
      "adminPassword",
      geoserverServerData.geoserverServer.adminPassword
    );
  }

  async function handleUpdateGeoserverServer(e: SyntheticEvent) {
    try {
      await updateGeoserverServer({
        variables: {
          id: parseFloat(String(id)),
          input: {
            server: formState.values.server,
            adminUsername: formState.values.adminUsername,
            adminPassword: formState.values.adminPassword,
          },
        },
        refetchQueries: [{ query: GET_GEOSERVERS }],
        awaitRefetchQueries: true,
      });
      setUpdating(true);
    } catch (err) {
      const error = graphqlService.getError(err);

      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  }

  if (updating && updateGeoserverServerData) {
    setSnackbar({
      open: true,
      message: `Updated geoserver server ${updateGeoserverServerData.updateGeoserverServer.server}`,
      severity: "success",
    });
    setUpdating(false);
  }

  if (geoserverServerLoading) {
    return <Feedback title="Edit Geoserver Server" message="Loading data..." />;
  }

  if (geoserverServerError) {
    const err = graphqlService.getError(geoserverServerError);

    if (err.statusCode === 404) {
      return <Redirect to="/geoserverservers" />;
    }

    return (
      <Feedback
        title="Edit Geoserver Server"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Edit Area
          </Typography>

          <form onSubmit={handleUpdateGeoserverServer} noValidate>
            <TextField
              {...url("server")}
              label="Server"
              placeholder="Server"
              error={formState.errors.server ? true : false}
              helperText={formState.errors.server}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("adminUsername")}
              label="Admin Username"
              placeholder="Admin Username"
              error={formState.errors.adminUsername ? true : false}
              helperText={formState.errors.adminUsername}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("adminPassword")}
              label="Admin Password"
              placeholder="Admin Password"
              error={formState.errors.adminPassword ? true : false}
              helperText={formState.errors.adminPassword}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={updateGeoserverServerLoading ? true : false}
            >
              Update Geoserver Server
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
