import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { GET_QR, GET_QRS, UPDATE_QR } from "../../graphql/Qrs";
import * as GetQrTypes from "../../graphql/__generated__/GetQr";
import * as UpdateQrTypes from "../../graphql/__generated__/UpdateQr";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import SelectUser from "../Forms/SelectUser";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

dayjs.extend(advancedFormat);

interface ShowQrParams {
  id: string;
}

export default function ShowQr() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<ShowQrParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [issuedOn, setIssuedOn] = useState<any>(dayjs());
  const [validUntil, setValidUntil] = useState<any>(dayjs());
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [submitted, setSubmitted] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [formState, { text }] = useFormState({
    code: "",
  });

  const { data: qrData, loading: qrLoading, error: qrError } = useQuery<
    GetQrTypes.GetQr
  >(GET_QR, {
    variables: { id: parseFloat(String(id)) },
  });

  const [
    updateQr,
    { data: updateQrData, loading: updateQrLoading },
  ] = useMutation<UpdateQrTypes.UpdateQr>(UPDATE_QR);

  if (!formState.touched.code && qrData) {
    formState.setField("code", qrData.qr.code);
    setIssuedOn(dayjs.unix(Number(qrData.qr.issued_on)));
    setValidUntil(dayjs.unix(Number(qrData.qr.valid_until)));
    setSelectedUser(qrData.qr.user.id);
  }

  async function handleUpdateQr(e: SyntheticEvent) {
    e.preventDefault();

    setSubmitted(true);

    if (
      formState.values.code !== "" &&
      issuedOn !== null &&
      validUntil !== null &&
      selectedUser !== ""
    ) {
      try {
        await updateQr({
          variables: {
            id: parseFloat(String(id)),
            input: {
              code: formState.values.code,
              issued_on: issuedOn.format("X"),
              valid_until: validUntil.format("X"),
              userId: parseFloat(String(selectedUser)),
            },
          },
          refetchQueries: [{ query: GET_QRS }],
          awaitRefetchQueries: true,
        });
        setUpdating(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  if (updating && updateQrData) {
    setSnackbar({
      open: true,
      message: `Update QR login`,
      severity: "success",
    });
    setUpdating(false);
    setSubmitted(false);
  }

  if (qrLoading) {
    return <Feedback title="Edit QR Login" message="Loading data..." />;
  }

  if (qrError) {
    const err = graphqlService.getError(qrError);

    if (err.statusCode === 404) {
      return <Redirect to="/qrs" />;
    }

    return (
      <Feedback
        title="Edit QR Login"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Edit QR login
          </Typography>

          <form onSubmit={handleUpdateQr} noValidate>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <TextField
                {...text("code")}
                label="Code"
                placeholder="Code"
                error={formState.errors.code ? true : false}
                helperText={formState.errors.code}
                variant="outlined"
                fullWidth
                required
                margin="normal"
              />

              <DateTimePicker
                value={issuedOn}
                onChange={setIssuedOn}
                label="Issued On"
                ampm={false}
                inputVariant="outlined"
                fullWidth
                required
                margin="normal"
              />

              <DateTimePicker
                value={validUntil}
                onChange={setValidUntil}
                label="Valid Until"
                ampm={false}
                inputVariant="outlined"
                fullWidth
                required
                margin="normal"
              />

              <SelectUser
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                submitted={submitted}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButton}
                disabled={updateQrLoading ? true : false}
              >
                Update QR Login
              </Button>
            </MuiPickersUtilsProvider>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
