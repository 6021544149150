import gql from "graphql-tag";

export const GET_GEOSERVER_SERVERS = gql`
  query GetGeoserverServers {
    geoserverServers {
      id
      server
      adminUsername
      adminPassword
    }
  }
`;

export const GET_GEOSERVER_SERVER = gql`
  query getGeoserverServer($id: Float!) {
    geoserverServer(id: $id) {
      id
      server
      adminUsername
      adminPassword
    }
  }
`;

export const GET_GEOSERVER_SERVER_GEOSERVERS = gql`
  query GetGeoserverServerGeoservers($id: Float!) {
    geoserverServer(id: $id) {
      id
      server
      geoservers {
        id
        namespace
        username
        server {
          id
          server
        }
      }
    }
  }
`;

export const ADD_GEOSERVER_SERVER = gql`
  mutation CreateGeoserverServer($input: CreateGeoserverServerInput!) {
    createGeoserverServer(createGeoserverServerData: $input) {
      id
      server
    }
  }
`;

export const UPDATE_GEOSERVER_SERVER = gql`
  mutation UpdateGeoserverServer(
    $id: Float!
    $input: UpdateGeoserverServerInput!
  ) {
    updateGeoserverServer(updateGeoserverServerData: $input, id: $id) {
      id
      server
      adminUsername
      adminPassword
    }
  }
`;

export const DELETE_GEOSERVER_SERVER = gql`
  mutation DeleteGeoserverServer($id: Float!) {
    deleteGeoserverServer(id: $id)
  }
`;
