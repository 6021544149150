import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APICountry = {
  id: number;
  name: string;
  code: string;
};

export type APICountriesById = { [key: string]: APICountry };

export async function getAllCountries(): Promise<Array<APICountry>> {
  try {
    return await api.get("countries");
  }
  catch (err: any) {
    throw err;
  }
}

export async function getAllCountriesById(): Promise<APICountriesById | null> {
  const ret: APICountriesById = {};
  const countries = await getAllCountries();
  if (countries === null)
    return null;
  countries.forEach((country) => ret[country.id + ""] = country);
  return ret;
}

// TODO: implement this in backend
export async function getCountry(id: number): Promise<APICountry | null> {
  try {
    return await api.get(`country/${id}`);
  }
  catch (err: any) {
    throw err;
  }
}

