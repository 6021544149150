import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import AddGeoserver from "./AddGeoserver";
import ListGeoservers from "./ListGeoservers";

export default function Geoservers() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListGeoservers />
      <Box mt={4} />
      <AddGeoserver />
    </Container>
  );
}
