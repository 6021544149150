import { useMutation } from "@apollo/react-hooks";
import DayjsUtils from "@date-io/dayjs";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import { v4 as uuidv4 } from "uuid";
import { ADD_QR, GET_QRS } from "../../graphql/Qrs";
import * as CreateQrTypes from "../../graphql/__generated__/CreateQr";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import SelectUser from "../Forms/SelectUser";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

dayjs.extend(advancedFormat);

export default function AddQr() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [issuedOn, setIssuedOn] = useState<any>(dayjs());
  const [validUntil, setValidUntil] = useState<any>(dayjs());
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [submitted, setSubmitted] = useState(false);
  const [adding, setAdding] = useState(false);

  const [formState, { text }] = useFormState({
    code: uuidv4(),
  });

  const [addQr, { data, loading }] = useMutation<CreateQrTypes.CreateQr>(
    ADD_QR
  );

  if (adding && data) {
    setSnackbar({
      open: true,
      message: `Added QR Login`,
      severity: "success",
    });

    setAdding(false);
    setSubmitted(false);
    setIssuedOn(dayjs());
    setValidUntil(dayjs());
    setSelectedUser("");
    formState.setField("code", uuidv4());
  }

  async function handleAddQr(e: SyntheticEvent) {
    e.preventDefault();

    setSubmitted(true);

    if (formState.values.code === "") {
      formState.setFieldError("code", "Please fill out this field.");
    }

    if (
      formState.values.code !== "" &&
      issuedOn !== null &&
      validUntil !== null &&
      selectedUser !== ""
    ) {
      try {
        await addQr({
          variables: {
            input: {
              code: formState.values.code,
              issued_on: issuedOn.format("X"),
              valid_until: validUntil.format("X"),
              userId: selectedUser,
            },
          },
          refetchQueries: [{ query: GET_QRS }],
          awaitRefetchQueries: true,
        });
        setAdding(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Add a QR login
          </Typography>

          <form onSubmit={handleAddQr} noValidate>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <TextField
                {...text("code")}
                label="Code"
                placeholder="Code"
                error={formState.errors.code ? true : false}
                helperText={formState.errors.code}
                variant="outlined"
                fullWidth
                required
                margin="normal"
              />

              <DateTimePicker
                value={issuedOn}
                onChange={setIssuedOn}
                label="Issued On"
                ampm={false}
                inputVariant="outlined"
                fullWidth
                required
                margin="normal"
              />

              <DateTimePicker
                value={validUntil}
                onChange={setValidUntil}
                label="Valid Until"
                ampm={false}
                inputVariant="outlined"
                fullWidth
                required
                margin="normal"
              />

              <SelectUser
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                submitted={submitted}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButton}
                disabled={loading ? true : false}
              >
                Add QR Login
              </Button>
            </MuiPickersUtilsProvider>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
