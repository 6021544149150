import { useMutation } from "@apollo/react-hooks";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { GET_GEOSERVERS } from "../../graphql/Geoservers";
import { DELETE_GEOSERVER_SERVER } from "../../graphql/GeoserverServers";
import * as DeleteGeoserverServerTypes from "../../graphql/__generated__/DeleteGeoserverServer";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface DeleteGeoserverServerParams {
  id: string;
}

export default function DeleteGeoserverServer() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<DeleteGeoserverServerParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);

  const [deleteGeoserverServer, { data, loading }] = useMutation<
    DeleteGeoserverServerTypes.DeleteGeoserverServer
  >(DELETE_GEOSERVER_SERVER);

  async function handleDeleteGeoserverServer(e: SyntheticEvent) {
    e.preventDefault();

    try {
      await deleteGeoserverServer({
        variables: {
          id: parseFloat(String(id)),
        },
        refetchQueries: [{ query: GET_GEOSERVERS }],
        awaitRefetchQueries: true,
      });
    } catch (err) {
      const error = graphqlService.getError(err);

      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  }

  if (data && data.deleteGeoserverServer) {
    return <Redirect to="/geoserverservers" />;
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Delete Geoserver Server
          </Typography>

          <Typography variant="body1" color="error">
            This action cannot be undone, make sure you have the correct
            geoserver server selected as it will also delete all geoservers
            shown above
          </Typography>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.formButton}
            onClick={handleDeleteGeoserverServer}
            disabled={loading ? true : false}
          >
            Delete geoserver server
          </Button>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
