import { makeStyles } from "@material-ui/core";
const drawerWidth = 200;

const useStyles = makeStyles({
  rootContainer: {
    marginTop: "1em"
  },
  footerContainer: {
    paddingBottom: "1em"
  },
  buttonLink: {
    border: "none",
    background: "none",
    padding: "0 3px",
    lineHeight: "inherit",
    minWidth: "initial",
    textTransform: "none",
    transition: "none",
    verticalAlign: "top",
    width: "auto"
  },
  formButton: {
    display: "block",
    marginTop: "0.5em"
  },
  formButtonMultiple: {
    display: "inline-block",
    marginTop: "0.5em",
    marginRight: "0.5em"
  },
  formButtons: {
    display: "flex",
    flexDirection: "row"
  },
  formColumn2: {
    width: "48%"
  },
  formColumn3: {
    width: "31%"
  },
  formColumns: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%"
  },
  formContainer: {
    padding: "0 2em",
    minHeight: "20vh",
    maxHeight: "70vh",
    overflow: "visible scroll",
    "& .MuiFormControl-root": {
      marginBottom: "1em"
    },
    "& .MuiFormControl-root .MuiFormControl-root": {
      marginBottom: 0
    },
    "& .MuiAutocomplete-popper": {
      top: "43px"
    }
  },
  formSection: {
  },
  formSectionLast: {
    marginBottom: "3vh"
  },
  formSelect: {
    marginTop: 16,
    marginBottom: 8
  },
  formWarning: {
    border: "1px solid red",
    padding: "0 10px",
    "background-color": "#FFEEEE",
    margin: 0,
    "border-radius": "5px",
    "& h4": {
      "align-items": "end",
      display: "inline-flex",
      "font-size": "16px",
      margin: "10px",
      "vertical-align": "middle"
    },
    "& h4 svg": {
      height: "30px",
      width: "30px"
    },
    "& h4 span": {
      "margin-left": "8px"
    }
  },
  tableHeader: {
    paddingTop: 16,
    marginLeft: 16
  },
  table: {
    width: "100%",
    "& tr:hover": {
      backgroundColor: "#f5f5f5"
    }
  },
  tableTight: {
    width: "100%",
    "& td": {
      border: "none",
      padding: "5px"
    },
    "& td:first-of-type": {
      "font-weight": "bold"
    }
  },
  sidebarDrawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  sidebarPaper: {
    width: drawerWidth
  },
  loginContainer: {
    paddingTop: "1em",
    paddingBottom: "1em"
  },
  loginForm: {
    "& .MuiInputBase-input": {
      backgroundColor: "#fff"
    }
  },
  loginButton: {
    marginTop: "1em"
  },
  modalContainer: {
    margin: "5vh auto",
    maxWidth: "80%",
    minWidth: "50%",
    maxHeight: "90vh",
    overflow: "hidden"
  },
  modalContainerSmall: {
    margin: "10vh auto",
    maxWidth: "50%",
    minWidth: "30%",
    maxHeight: "80vh",
    overflow: "hidden"
  },
  modalTitle: {
    borderBottom: "1px solid",
    marginBottom: "0.5em",
    paddingBottom: "0.2em"
  },
  multiSelect: {
    paddingTop: "20px",
    "& .dropdown-content": {
    },
    "& .dropdown-content .disabled input": {
      display: "none"
    },
    "& .dropdown-content .disabled": {
      color: "black",
      fontWeight: "bold"
    }
  },
  listEmpty: {
    padding: 0,
    float: "left",
    width: "100%",
    "& li": {
      float: "left",
      padding: "2px 10px",
      margin: "5px",
      width: "fit-content"
    },
    "& .MuiListItemIcon-root": {
      minWidth: "32px",
      width: "32px"
    },
    "& .MuiListItemText-root span": {
      fontSize: "0.8rem"
    }
  },
  listItems: {
    padding: 0,
    float: "left",
    width: "100%",
    "& li": {
      cursor: "pointer",
      float: "left",
      border: "1px solid rgba(0, 0, 0, 0.5)",
      borderRadius: "10px",
      padding: "2px 10px",
      margin: "5px",
      width: "fit-content"
    },
    "& .noclick": {
      cursor: "default"
    },
    "& li:hover": {
      backgroundColor: "#f5f5f5"
    },
    "& .MuiListItemIcon-root": {
      minWidth: "32px",
      width: "32px"
    },
    "& .MuiListItemText-root span": {
      fontSize: "0.8rem"
    }
  },
  viewTitle: {
    fontSize: "1.4rem"
  },
  viewSectionTitle: {
    paddingTop: "1em",
    fontSize: "1.2rem",
    fontWeight: "bold"
  },
  viewTable: {
    width: "100%",
    "& tr:hover": {
      backgroundColor: "#f5f5f5"
    },
    "& tr > th": {
      padding: "3px 16px",
      fontWeight: "bold"
    },
    "& tr > td": {
      padding: "3px 16px"
    },
    "& tr > td:first-of-type": {
      maxWidth: "25%",
      fontWeight: "bold"
    }
  }
});

export default useStyles;
