import { Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import ListFlightZones from "./ListFlightZones";

export default function FlightZones() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListFlightZones />
    </Container>
  );
}
