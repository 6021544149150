import {
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { history } from "../../index";
import { useStoreState } from "../../store";
import Area from "../Area/Area";
import Areas from "../Areas/Areas";
import BucketTransfer from "../BucketTransfer/BucketTransfer";
import Companies from "../Companies/Companies";
import Company from "../Company/Company";
import Flights from "../Flights/Flights";
import FlightClients from "../FlightClients/FlightClients";
import FlightJobs from "../FlightJobs/FlightJobs";
import FlightOrders from "../FlightOrders/FlightOrders";
import FlightZones from "../FlightZones/FlightZones";
import Geoserver from "../Geoserver/Geoserver";
import Geoservers from "../Geoservers/Geoservers";
import GeoserverServer from "../GeoserverServer/GeoserverServer";
import GeoserverServers from "../GeoserverServers/GeoserverServers";
import Login from "../Login/Login";
import Qr from "../Qr/Qr";
import Qrs from "../Qrs/Qrs";
import Sidebar from "../Sidebar/Sidebar";
import User from "../User/User";
import Users from "../Users/Users";
import UploadUsers from "../UploadUsers/UploadUsers";

function PrivateRoute({ children, ...rest }: any) {
  const isAuthenticated = useStoreState((state) => state.authenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function UnauthenticatedRoute({ children, ...rest }: any) {
  const isAuthenticated = useStoreState((state) => state.authenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  })
);

export default function App() {
  const classes = useStyles();
  const isAuthenticated = useStoreState((state) => state.authenticated);

  return (
    <div className={classes.root}>
      <Router history={history}>
        <Route
          path="/"
          render={() => {
            if (isAuthenticated) {
              return <Sidebar />;
            } else {
              return <React.Fragment></React.Fragment>;
            }
          }}
        />

        <Switch>
          <PrivateRoute exact path="/">
            <main className={classes.content}>
              <Users />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/users">
            <main className={classes.content}>
              <Users />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/users/:id">
            <main className={classes.content}>
              <User />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/companies">
            <main className={classes.content}>
              <Companies />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/companies/:id">
            <main className={classes.content}>
              <Company />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/areas">
            <main className={classes.content}>
              <Areas />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/areas/:id">
            <main className={classes.content}>
              <Area />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/flightjobs">
            <main className={classes.content}>
              <FlightJobs />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/flightorders">
            <main className={classes.content}>
              <FlightOrders />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/flightzones">
            <main className={classes.content}>
              <FlightZones />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/flights">
            <main className={classes.content}>
              <Flights />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/flightclients">
            <main className={classes.content}>
              <FlightClients />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/geoservers">
            <main className={classes.content}>
              <Geoservers />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/geoservers/:id">
            <main className={classes.content}>
              <Geoserver />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/geoserverservers">
            <main className={classes.content}>
              <GeoserverServers />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/geoserverservers/:id">
            <main className={classes.content}>
              <GeoserverServer />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/qrs">
            <main className={classes.content}>
              <Qrs />
            </main>
          </PrivateRoute>
          <PrivateRoute exact path="/qrs/:id">
            <main className={classes.content}>
              <Qr />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/buckettransfer">
            <main className={classes.content}>
              <BucketTransfer />
            </main>
          </PrivateRoute>

          <PrivateRoute exact path="/uploadusers">
            <main className={classes.content}>
              <UploadUsers />
            </main>
          </PrivateRoute>

          <UnauthenticatedRoute path="/login">
            <Login />
          </UnauthenticatedRoute>

          <Route
            path="/"
            render={() => (
              <main className={classes.content}>
                <Card>
                  <CardContent>
                    <Typography variant="h4" component="h1" color="primary">
                      Page not found
                    </Typography>

                    <p>Could not find the request page</p>
                  </CardContent>
                </Card>
              </main>
            )}
          />
        </Switch>
      </Router>
    </div>
  );
}
