import { useQuery } from "@apollo/react-hooks";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import { GET_GEOSERVER_SERVERS } from "../../graphql/GeoserverServers";
import * as GetGeoserverServersTypes from "../../graphql/__generated__/GetGeoserverServers";
import GeoserverServersService from "../../services/GeoserverServersService";
import useStyles from "../../styles";

type SelectGeoserverServerProps = {
  selectedGeoserverServer: string;
  setSelectedGeoserverServer: (geoserverServer: string) => void;
  submitted: boolean;
};

export default function SelectGeoserverServer(
  props: SelectGeoserverServerProps
) {
  const geoserverServersService = new GeoserverServersService();
  const classes = useStyles();

  const { data, loading, error } = useQuery<
    GetGeoserverServersTypes.GetGeoserverServers
  >(GET_GEOSERVER_SERVERS);
  const [servers, setServers] = useState<{ [index: string]: string }>({});

  if (data && Object.keys(servers).length === 0) {
    setServers(geoserverServersService.getServers(data.geoserverServers));
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="geoserver-servers-select-label">
        Geoserver Server
      </InputLabel>
      <Select
        labelId="geoserver-servers-select-label"
        id="geoserver-servers-select"
        value={
          error
            ? "Unable to retrieve geoserver servers"
            : Object.keys(servers).length === 0
            ? ""
            : props.selectedGeoserverServer
        }
        input={<Input />}
        onChange={(e) =>
          props.setSelectedGeoserverServer(e.target.value as string)
        }
        renderValue={(selected) => {
          return servers[selected as string];
        }}
        variant="outlined"
        className={classes.formSelect}
        fullWidth
        disabled={loading || error ? true : false}
        error={props.submitted && props.selectedGeoserverServer === ""}
      >
        {data?.geoserverServers.map((geoserverServer) => (
          <MenuItem key={geoserverServer.id} value={geoserverServer.id}>
            <Checkbox
              checked={props.selectedGeoserverServer === geoserverServer.id}
            />
            <ListItemText primary={geoserverServer.server} />
          </MenuItem>
        ))}
      </Select>
      {props.submitted && props.selectedGeoserverServer === "" && (
        <FormHelperText error={true}>
          A geoserver server is required
        </FormHelperText>
      )}
    </FormControl>
  );
}
