import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { poolData } from "./constants";

export function getAwsToken() {
  return new Promise((resolve) => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser !== null) {
      cognitoUser.getSession((err: any, data: any) => {
        if (err) {
          resolve(null);
        } else {
          resolve(data.getIdToken().getJwtToken());
        }
      });
    } else {
      resolve(null);
    }
  });
}
