import * as GetGeoserverServersTypes from "../graphql/__generated__/GetGeoserverServers";

export default class GeoserverServersService {
  getServers(
    geoserverServers: GetGeoserverServersTypes.GetGeoserverServers_geoserverServers[]
  ) {
    let servers: { [index: string]: string } = {};

    for (let geo of geoserverServers) {
      servers[geo.id] = geo.server;
    }

    return servers;
  }
}
