import { Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import ListFlights from "./ListFlights";

export default function Flights() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListFlights />
    </Container>
  );
}
