import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import find from "lodash/find";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import {
  GET_COMPANIES,
  GET_COMPANY,
  UPDATE_COMPANY,
} from "../../graphql/Companies";
import * as GetCompanyTypes from "../../graphql/__generated__/GetCompany";
import * as UpdateCompanyTypes from "../../graphql/__generated__/UpdateCompany";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import SelectUsers from "../Forms/SelectUsers";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface ShowCompanyParams {
  id: string;
}

export default function ShowCompany() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<ShowCompanyParams>();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [updating, setUpdating] = useState(false);
  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [formState, { text }] = useFormState({ name: "" });

  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useQuery<GetCompanyTypes.GetCompany>(GET_COMPANY, {
    variables: { id: parseFloat(String(id)) },
  });
  const [
    updateCompany,
    { data: updateCompanyData, loading: updateCompanyLoading },
  ] = useMutation<UpdateCompanyTypes.UpdateCompany>(UPDATE_COMPANY);

  if (!formState.touched.name && companyData) {
    formState.setField("name", companyData.company.name);
    setSelectedUsers(
      companyData.company.users.reduce((acc, curr) => {
        return [...acc, curr.id];
      }, [] as string[])
    );
  }

  async function handleUpdateCompany(e: SyntheticEvent) {
    e.preventDefault();

    if (formState.validity.name && companyData) {
      const addedUsers = findAddedUsers(selectedUsers, companyData);
      const removedUsers = findRemovedUsers(selectedUsers, companyData);

      try {
        await updateCompany({
          variables: {
            id: parseFloat(String(id)),
            input: {
              name: formState.values.name,
              addUserIds: addedUsers,
              removeUserIds: removedUsers,
            },
          },
          refetchQueries: [{ query: GET_COMPANIES }],
          awaitRefetchQueries: true,
        });

        setUpdating(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  if (updating && updateCompanyData) {
    setSnackbar({
      open: true,
      message: `Updated company ${updateCompanyData.updateCompany.name}`,
      severity: "success",
    });
    setUpdating(false);
  }

  if (companyLoading) {
    return <Feedback title="Edit Company" message="Loading data..." />;
  }

  if (companyError) {
    const err = graphqlService.getError(companyError);

    if (err.statusCode === 404) {
      return <Redirect to="/companies" />;
    }

    return (
      <Feedback
        title="Edit Company"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Edit Company
          </Typography>

          <form onSubmit={handleUpdateCompany} noValidate>
            <TextField
              {...text("name")}
              label="Name"
              placeholder="Name"
              error={formState.errors.name ? true : false}
              helperText={formState.errors.name}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <SelectUsers
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={updateCompanyLoading ? true : false}
            >
              Update Company
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}

function findAddedUsers(
  selectedUsers: string[],
  data: GetCompanyTypes.GetCompany
) {
  return selectedUsers.reduce((acc, curr) => {
    if (find(data.company.users, { id: curr }) === undefined) {
      acc.push(curr);
    }

    return acc;
  }, [] as string[]);
}

function findRemovedUsers(
  selectedUsers: string[],
  data: GetCompanyTypes.GetCompany
) {
  return data.company.users.reduce((acc, curr) => {
    if (selectedUsers.indexOf(curr.id) === -1) {
      acc.push(curr.id);
    }

    return acc;
  }, [] as string[]);
}
