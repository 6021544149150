import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIClient = {
  id: number;
  name: string;
  processing_name: string;
  contact_name: string;
  contact_address: string;
  country_id: number;
  country: any;
};

export type APIClientsById = { [ key: string ] : APIClient };

export type CreateAPIClient = {
  name: string;
  processing_name: string;
  contact_name?: string;
  contact_address?: string;
  country_id: number;
};

export async function getAllClients(
  paginationToken?: number
): Promise<Array<APIClient>> {
  let url = `clients`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    if (response.pagination_token !== undefined) {
      const moreClients = await getAllClients(response.pagination_token);
      return [...response.clients, ...moreClients];
    } else {
      return response.clients;
    }
  }
  catch (err: any) {
    throw err;
  }
}

export async function getAllClientsById(): Promise<APIClientsById> {
  try {
    const clients = await getAllClients();
    const ret: { [ key: string] : APIClient } = {};
    clients.forEach((client) => ret[client.id + ""] = client);
    return ret;
  }
  catch (err: any) {
    throw err;
  }
}

export async function getClient(
  id: number,
  paginationToken?: number
): Promise<APIClient | null> {
  let url = `clients`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    for (const client of response.clients) {
      if (client.id === id) {
        return client;
      }
    }

    if (response.pagination_token !== undefined) {
      return await getClient(id, response.pagination_token);
    }

    return null;
  }
  catch (err: any) {
    throw err;
  }
}

export async function createClient(
  payload: CreateAPIClient
): Promise<string> {
  try {
    const response = await api.post("clients", payload);
    return response.client_id;
  }
  catch (err: any) {
    throw err;
  }
}
