import gql from "graphql-tag";

export const GET_QRS = gql`
  query GetQrs {
    qrs {
      id
      code
      issued_on
      valid_until
      user {
        id
        name
      }
    }
  }
`;

export const GET_QR = gql`
  query GetQr($id: Float!) {
    qr(id: $id) {
      id
      code
      issued_on
      valid_until
      user {
        id
        name
      }
    }
  }
`;

export const ADD_QR = gql`
  mutation CreateQr($input: CreateQrInput!) {
    createQr(createQrData: $input) {
      id
      code
      issued_on
      valid_until
      user {
        id
        name
      }
    }
  }
`;

export const UPDATE_QR = gql`
  mutation UpdateQr($id: Float!, $input: UpdateQrInput!) {
    updateQr(updateQrData: $input, id: $id) {
      id
      code
      issued_on
      valid_until
      user {
        id
        name
      }
    }
  }
`;

export const DELETE_QR = gql`
  mutation DeleteQr($id: Float!) {
    deleteQr(id: $id)
  }
`;
