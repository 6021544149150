import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import DeleteGeoserver from "./DeleteGeoserver";
import ShowGeoserver from "./ShowGeoserver";

export default function Geoserver() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ShowGeoserver />
      <Box mt={4} />
      <DeleteGeoserver />
    </Container>
  );
}
