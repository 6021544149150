import { Box, Container } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "../../styles";
import ListTransfers from "./ListTransfers";
import NewTransfer from "./NewTransfer";

export default function BucketTransfer() {
  const classes = useStyles();

  const [ source, setSource ] = useState<string>("");
  const [ target, setTarget ] = useState<string>("");

  const handleCopyInput = (field: "source" | "target", value: string) => {
    if (field === "source")
      setSource(value);
    if (field === "target")
      setTarget(value);
  };

  const handleUpdateInput = (field: "source" | "target", value: string) => {

  };

  return (
    <Container className={classes.rootContainer}>
      <NewTransfer
        onUpdateInput={handleUpdateInput}
        source={source}
        target={target}
      />
      <Box mt={4} />
      <ListTransfers
        onCopyInput={handleCopyInput}
      />
    </Container>
  );
}
