import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIDem = {
  s3_location: string;
};

export async function getAllDems(): Promise<Array<APIDem>> {
  try {
    return await api.get("dem");
  }
  catch (err: any) {
    throw err;
  }
}
