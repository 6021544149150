import { Button, Container, TextField, Typography } from "@material-ui/core";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import React, { SyntheticEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { poolData } from "../../constants";
import { useStoreActions } from "../../store";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface NewPasswordFormProps {
  authData: { email: string; password: string };
}

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default function NewPasswordForm(props: NewPasswordFormProps) {
  const classes = useStyles();
  const history = useHistory();
  const [formState, { password }] = useFormState();
  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);

  const [submitted, setSubmitted] = useState(false);

  async function makeLoginRequest(newPassword: string) {
    const authenticationData = {
      Username: props.authData.email,
      Password: props.authData.password,
    };

    const userData = {
      Username: props.authData.email,
      Pool: userPool,
    };

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setLoggedIn(undefined);
        history.push("/");
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        if (newPassword !== null) {
          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: (result) => {
                setLoggedIn(undefined);
                history.push("/");
              },
              onFailure: (err) => {
                setSubmitted(false);
                setSnackbar({
                  open: true,
                  message: err.message,
                  severity: "error",
                });
              },
            }
          );
        }
      },
      onFailure: (err) => {
        setSubmitted(false);
        setSnackbar({
          open: true,
          message: err.message,
          severity: "error",
        });
      },
    });
  }

  async function handleLogin(e: SyntheticEvent) {
    e.preventDefault();
    setSubmitted(true);

    if (!formState.touched.newPassword && !formState.validity.newPassword) {
      formState.setFieldError("newPassword", "Please fill out this field.");
      setSubmitted(false);
    }

    if (formState.validity.newPassword) {
      await makeLoginRequest(formState.values.newPassword);
    }
  }

  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.loginContainer}>
        <Typography variant="h4" component="h1" align="center">
          A password update is required
        </Typography>

        <form className={classes.loginForm} onSubmit={handleLogin} noValidate>
          <TextField
            {...password("newPassword")}
            label="New Password"
            placeholder="New Password"
            error={formState.errors.newPassword ? true : false}
            helperText={formState.errors.newPassword}
            variant="outlined"
            fullWidth
            required
            margin="normal"
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.loginButton}
            disabled={submitted}
            fullWidth
          >
            Update Password
          </Button>
        </form>
      </Container>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
