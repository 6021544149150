import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIZone = {
  id: number;
  client_id: number;
  client: any;
  name: string;
  buffer: number;
  remarks: string;
  size: number;
};

export type CreateAPIZone = {
  name: string;
  client_id: number;
  area_type: number;
  shape_file: any;
  buffer?: number;
  remarks?: string;
};

export async function getAllZones(
  paginationToken?: number
): Promise<Array<APIZone>> {
  let url = `zones`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    if (response.pagination_token !== undefined) {
      const moreZones = await getAllZones(response.pagination_token);
      return [...response.zones, ...moreZones];
    }
    else {
      return response.zones;
    }
  }
  catch (err: any) {
    throw err;
  }
}

export async function getZone(
  id: number,
  paginationToken?: number
): Promise<APIZone | null> {
  let url = `zones`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    for (const zone of response.zones) {
      if (zone.id === id) {
        return zone;
      }
    }

    if (response.pagination_token !== undefined) {
      return await getZone(id, response.pagination_token);
    }

    return null;
  }
  catch (err: any) {
    throw err;
  }
}

export async function createZone(
  payload: CreateAPIZone
): Promise<{ success: boolean; data: string }> {
  try {
    const response = await api.post(`zones`, payload);
    return response.zone_id;
  }
  catch (err: any) {
    throw err;
  }
}
