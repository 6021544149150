import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIAreaType = {
  id: number;
  name: string;
  description: string;
};

export async function getAllAreaTypes(): Promise<Array<APIAreaType>> {
  try {
    return await api.get("area_types");
  }
  catch (err: any) {
    throw err;
  }
}
