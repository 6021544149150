import gql from "graphql-tag";

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      name
    }
  }
`;

export const GET_COMPANY = gql`
  query GetCompany($id: Float!) {
    company(id: $id) {
      id
      name
      users {
        id
      }
    }
  }
`;

export const GET_COMPANY_AREAS = gql`
  query GetCompanyAreas($id: Float!) {
    company(id: $id) {
      id
      name
      areas {
        id
        name
      }
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(createCompanyData: $input) {
      id
      name
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Float!, $input: UpdateCompanyInput!) {
    updateCompany(updateCompanyData: $input, id: $id) {
      id
      name
      users {
        id
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: Float!) {
    deleteCompany(id: $id)
  }
`;
