import axios from "axios";
import { getAwsToken } from "../getAwsToken";

class APIError extends Error {

  statusCode: string

  constructor (statusCode: string | number, message?: any) {
    if (!message)
      message = "API Error";
    super(message);
    this.statusCode = statusCode + "";
    this.name = "APIError";
  }

}

async function _getLike (method: Function, apiKey: string, url: string, endpoint: string): Promise<any> {
  const token = await getAwsToken();
  url = url + "/" + endpoint;

  const response = await method(url, {
    headers: {
      "X-Api-Key": apiKey,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status + "" !== "200" && response.status + "" !== "201")
    throw new APIError(response.status, response.statusText)

  return response.data;
}

async function _postLike (method: Function, apiKey: string, url: string, endpoint: string, payload: any): Promise<any> {
  const token = await getAwsToken();
  url = url + "/" + endpoint;

  const response = await method(url, payload, {
    headers: {
      "X-Api-Key": apiKey,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status + "" !== "200" && response.status + "" !== "201")
    throw new APIError(response.status, response.statusText)

  return response.data;
}

export async function _get (apiKey: string, url: string, endpoint: string): Promise<any> {
  return _getLike(axios.get, apiKey, url, endpoint);
}

export async function _post (apiKey: string, url: string, endpoint: string, payload: any): Promise<any> {
  return _postLike(axios.post, apiKey, url, endpoint, payload);
}

export async function _patch (apiKey: string, url: string, endpoint: string, payload: any): Promise<any> {
  return _postLike(axios.patch, apiKey, url, endpoint, payload);
}

export async function _put (apiKey: string, url: string, endpoint: string, payload: any): Promise<any> {
  return _postLike(axios.put, apiKey, url, endpoint, payload);
}

export async function _delete (apiKey: string, url: string, endpoint: string): Promise<any> {
  return _getLike(axios.delete, apiKey, url, endpoint);
}

export function connector (apiKey: string, url: string) {
  return {
    get: _get.bind(null, apiKey, url),
    post: _post.bind(null, apiKey, url),
    patch: _patch.bind(null, apiKey, url),
    put: _put.bind(null, apiKey, url),
    delete: _delete.bind(null, apiKey, url)
  };
}
