import {
  Box,
  Button,
  TextField
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

import "./SearchField.css";

import React, { useState } from "react";

export function filter(arr: any, searchTerm: string | Array<string>, fields?: Array<string>, caseSensitive?: boolean) {

  function deepRead(obj: object, path: string): any {
    const pathElements = path.split(".");
    let ret: any = obj;
    for (let element of pathElements) {
      ret = ret[element];
      if (!ret)
        return "";
    }
    if (typeof(ret) === "string")
      return ret;
    if (typeof(ret) === "number" || typeof(ret) === "boolean")
      return "" + ret;
    return "";
  }

  if (!searchTerm || searchTerm === "")
    return [ ...arr ];

  let searchTerms:Array<string> = (searchTerm instanceof Array) ? searchTerm : [ searchTerm ];
  searchTerms = searchTerms.map((term) => term.trim());

  if (!caseSensitive)
    searchTerms = searchTerms.map((item: string) => item.toLowerCase());
  return arr.filter((item: any) => {
    const userFields = fields ? fields : Object.keys(item);
    return searchTerms.every((term: string) => (
      userFields.some((key: string) => (
        (caseSensitive ? deepRead(item, key) : deepRead(item, key).toLowerCase()).indexOf(term) >= 0
      ))
    ));
  });

}

export function highlight(str: any, searchTerm: string | Array<string>, caseSensitive?: boolean) {

  function token(length?: number) {
    if (length === undefined)
      return "<~~>";
    return "<~~>" + length + ">~~<<~~>";
  }

  if (!searchTerm || searchTerm === "" || (typeof(str) !== "string" && typeof(str) !== "number"))
    return str;

  if (!(searchTerm instanceof Array))
    searchTerm = [ searchTerm ];
  searchTerm = searchTerm.map((term) => term.trim());

  // keep a copy of the original string and convert it to lower case for case insensitive search
  // we use the lower case string for getting the slice lengths and the original string to
  // return the search results
  str = "" + str;
  let strO = str + "";
  if (!caseSensitive) {
    str = str.toLowerCase();
    searchTerm = searchTerm.map((item) => item.toLowerCase());
  }
  searchTerm.forEach((term) => str = str.replace(new RegExp(term, "g"), token(term.length)));
  str = str.split(token());
  const ret: any[] = [];
  str.forEach((fragment: string) => {
    if (fragment === "")
      return;
    if (fragment.slice(-4) === ">~~<") {
      const fragmentLength = parseInt(fragment.slice(0, -4));
      ret.push(<span className="searchResult">{strO.slice(0, fragmentLength)}</span>);
      strO = strO.slice(fragmentLength);
    }
    else {
      ret.push(<span>{strO.slice(0, fragment.length)}</span>);
      strO = strO.slice(fragment.length);
    }
  });
  return (<>{ret}</>);
}

export default function SearchField(props: any) {

  const [ searchText, setSearchText] = useState<string>(props.value ? props.value : "");

  function handleChange(e: any) {
    if (!e)
      return;
    setSearchText(e.target.value);
    if (props.onChange)
      props.onChange(e.target.value);
  }

  function handleClear() {
    setSearchText("");
    if (props.onChange)
      props.onChange("");
  }

  function handleFocus(e: any) {
    if (!e)
      return;
    e.target.select();
  }

  function handleKey(e: any) {
    if (e && e.keyCode === 13) {
      handleSearch();
      e.target.blur();
    }
  }

  function handleSearch() {
    if (props.onSearch)
      props.onSearch(searchText);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Button
        className="searchButton"
        onClick={handleSearch}
        variant="outlined"
      >
        <SearchIcon />
      </Button>
      <TextField
        className="searchText"
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKey}
        value={searchText}
        variant="outlined"
      />
      <Button
        className="clearButton"
        onClick={handleClear}
        variant="outlined"
      >
        <ClearIcon />
      </Button>
    </Box>
  );
}
