import { ApolloProvider } from "@apollo/react-hooks";
import { CssBaseline, StylesProvider } from "@material-ui/core";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { from } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";
import { StoreProvider } from "easy-peasy";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import Start from "./components/Start/start";
import { AppProvider } from "./app-context";
import { GatewayApi } from "./gateway-api";
import { API_GATEWAY_URL, API_ORIGIN_URL } from "./constants";
import { GRAPHQL_URL } from "./constants";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import getCognitoToken from "./utils";

const cache = new InMemoryCache();

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getCognitoToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const unauthorizedLink = onError((err) => {
  if (err.response && err.response.errors) {
    if ((err.response.errors[0].message as any).statusCode === 401) {
      store.getActions().setLoggedOut(undefined);
    }
  }
});

export const client = new ApolloClient({
  cache,
  link: from([authLink, unauthorizedLink, httpLink]),
});

const context = {
    gatewayApi: new GatewayApi(API_GATEWAY_URL, API_ORIGIN_URL)
};

export const history = createBrowserHistory();

ReactDOM.render(
  <StylesProvider injectFirst>
    <StoreProvider store={store}>
      <ApolloProvider client={client}>
        <AppProvider value={context}>
          <CssBaseline />
          <Start>
            <App />
          </Start>
        </AppProvider>
      </ApolloProvider>
    </StoreProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
