import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIFlight = {
  flight_id: number;
  flight_date: string;
  flight_start: string;
  flight_end: string;
  trigger_count: number;
  rgb_count: number;
  nir_camera_used: boolean;
  nir_count: number;
  altitude: number;
  camera_set: number;
  weather: string;
  remarks: string;
  country_id: number;
  country: any;
};

export type APIFlightsById = { [ key: string ] : APIFlight };

export type CreateAPIFlight = {
  altitude: number;
  camera_set: number;
  country_id: number;
  flight_date: string;
  flight_end: string;
  flight_start: string;
  nir_camera_used: boolean;
  nir_count?: number;
  remarks?: string;
  rgb_count: number;
  trigger_count: number;
  weather?: string;
};

export async function getAllFlights(
  paginationToken?: number
): Promise<Array<APIFlight>> {
  let url = `flights`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    if (response.pagination_token !== undefined) {
      const moreFlights = await getAllFlights(response.pagination_token);
      return [...response.flights, ...moreFlights];
    }
    else {
      return response.flights;
    }
  }
  catch (err: any) {
    throw err;
  }
}

export async function getAllFlightsById(): Promise<APIFlightsById> {
  try {
    const flights = await getAllFlights();
    const ret: { [ key: string] : APIFlight } = {};
    flights.forEach((flight) => ret[flight.flight_id + ""] = flight);
    return ret;
  }
  catch (err: any) {
    throw err;
  }
}

export async function getFlight(
  id: number,
  paginationToken?: number
): Promise<APIFlight | null> {
  let url = `flights`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    for (const flight of response.flights) {
      if (flight.flight_id === id) {
        return flight;
      }
    }

    if (response.pagination_token !== undefined) {
      return await getFlight(id, response.pagination_token);
    }

    return null;
  }
  catch (err: any) {
    throw err;
  }
}

export async function createFlight(
  payload: CreateAPIFlight
): Promise<{ success: boolean; data: string }> {
  try {
    const response = await api.post(`flights`, payload);
    return response.flight_id;
  } catch (err: any) {
    throw err;
  }
}
