import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Tooltip
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useEffect, useState } from "react";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ReportIcon from '@mui/icons-material/Report';
import InputIcon from '@mui/icons-material/Input';
import PendingIcon from '@mui/icons-material/Pending';

import {
  APIBucketTransfer,
  cancelBucketTransfer,
  deleteBucketTransfer,
  pauseBucketTransfer,
  resumeBucketTransfer,
  getAllBucketTransfers
} from "../../API/Storage/BucketTransfer";

const REFRESH_INTERVAL = 10000;

const parseError = (str: string, length?: number) : string => {
  const m = str.match(/\(.+\)/g);
  if (m) {
    if (m[0] === "(AccessDenied)" && str.indexOf("ListObjects") !== -1)
      return "It's possible this bucket's policy does not allow cross-account access. Please contact the dev team.";
    return m[0];
  }
  if (!length)
    length = 60;
  const words = str.split(" ");
  let output = "";
  while (words.length && output.length + words[0].length < length)
    output += words.shift() + " ";
  return output;
};

const parseDate = (seconds: number, split?: boolean) => {
  seconds = Math.round(seconds);
  if (split === undefined)
    split = true;
  const dateStr = (new Date(seconds * 1000)).toLocaleString();
  if (!split)
    return <div>{dateStr}</div>;
  const date = dateStr.split(" ")[0];
  const time = dateStr.replace(date + " ", "");
  return (<>
    <div>{date}</div>
    <div>{time}</div>
  </>);
};

const parseTime = (seconds: number) : string => {
  if (seconds < 0)
    return "...";
  seconds = Math.round(seconds);
  const s = seconds % 60;
  seconds = Math.floor(seconds / 60);
  const m = seconds % 60;
  const h = Math.floor(seconds / 60);
  if (h)
    return h + "h " + m + "m";
  if (m)
    return m + "m " + s + "s";
  return s + "s";
};

// TODO: move these to separate style files / component
const progressHeaderStyle = {
  fontSize: "0.8rem",
  margin: "2px",
  color: "white",
  padding: "1px 5px",
  borderRadius: "5px"
};

const progressBodyStyle = {
  fontSize: "1.4rem",
  margin: "0px",
  marginBottom: "5px"
};

const progressBarStyle = {
  padding: 0,
  height: "6px",
  border: "1px solid white"
};

const endTimeStyle = {
  padding: 0,
  fontSize: "11px"
};

const runningTimeStyle = {
  padding: 0,
  fontSize: "11px",
  textAlign: "center" as const
};

const taskProgress = (task: any) => {

  if (task.status === "error")
    return (
      <TableCell>
        <Tooltip title={task.error}>
          <span>{parseError(task.error)}</span>
        </Tooltip>
      </TableCell>
    )

  if (task.status === "queued" || task.status === "created")
    return (
      <TableCell>
        <Chip label="not started"/>
      </TableCell>
    );

  if (task.status === "finished")
    return (
      <TableCell>
        <Chip label="100% complete"/>
        <div style={endTimeStyle}>{parseDate(task.finishedAt, false)}</div>
      </TableCell>
    );

  if (task.status === "cancelled")
    return <TableCell></TableCell>

  const queued = (Math.round(1000 * task.queued / task.batches) / 10);
  const running = (Math.round(1000 * task.running / task.batches) / 10);
  const finished = (Math.round(1000 * task.finished / task.batches) / 10);
  const queuedStyle = {
    ...progressBarStyle,
    width: queued + "%",
    backgroundColor: "red"
  };
  const runningStyle = {
    ...progressBarStyle,
    width: running + "%",
    backgroundColor: "orange"
  };
  const finishedStyle = {
    ...progressBarStyle,
    width: finished + "%",
    backgroundColor: "green"
  };

  return (
    <TableCell>
      <Table>
        <TableRow>
          <TableCell style={{ padding: 0, border: "none" }}>
            <Typography
              align="center"
              style={{ ...progressHeaderStyle, backgroundColor: "red" }}
            >
              queued
            </Typography>
          </TableCell>
          <TableCell style={{ padding: 0, border: "none" }}>
            <Typography
              align="center"
              style={{ ...progressHeaderStyle, backgroundColor: "orange" }}
            >
              running
            </Typography>
          </TableCell>
          <TableCell style={{ padding: 0, border: "none" }}>
            <Typography
              align="center"
              style={{ ...progressHeaderStyle, backgroundColor: "green" }}
            >
              finished
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0, border: "none" }}>
            <Typography
              align="center"
              style={{ ...progressBodyStyle, color: "red" }}
            >
              {queued}
            </Typography>
          </TableCell>
          <TableCell style={{ padding: 0, border: "none" }}>
            <Typography
              align="center"
              style={{ ...progressBodyStyle, color: "orange" }}
            >
              {running}
            </Typography>
          </TableCell>
          <TableCell style={{ padding: 0, border: "none" }}>
            <Typography
              align="center"
              style={{ ...progressBodyStyle, color: "green" }}
            >
              {finished}
            </Typography>
          </TableCell>
        </TableRow>
      </Table>
      <div style={{ boxShadow: "0 0 10px #666666" }}>
        <Table>
          <TableRow>
            <TableCell style={finishedStyle}></TableCell>
            <TableCell style={runningStyle}></TableCell>
            <TableCell style={queuedStyle}></TableCell>
          </TableRow>
        </Table>
      </div>
    </TableCell>
  );
};

const statusColor = (statusText: string) => {
  // TODO: useTheme: import { useTheme } from '@material-ui/core/styles';
  switch (statusText) {
    case "running":
      return "orange";
    case "finished":
      return "green";
    default:
      return "red";
  }
};

export default function ListTransfers(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [page, setPage] = useState(0);
  const [transfers, setTransfers] = useState<Array<APIBucketTransfer> | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [updating, setUpdating] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "timestamp" | "fromTo" | "files" | "status" | "progress";
  }>({ order: "desc", field: "timestamp" });
  let tasks: any[] = [];

  function setError(e: any) {
    if (e instanceof Error)
      e = e.message;
    setSnackbar({
      open: true,
      message: e,
      severity: "error"
    });
  }

  const loadData = () => {
    getAllBucketTransfers()
      .then((res) => {
        setTransfers(res);
        setUpdating(null);
        setLoading(false);
      })
      .catch((err: any) => {
        setError(err);
        setLoadError("Unable to load data from the backend");
        setLoading(false);
      });
  };

  const handleCancel = (taskId: string) => {
    if (updating)
      return;
    setUpdating(taskId);
    cancelBucketTransfer(taskId)
      .then(loadData)
      .catch((err) => console.log(err));
  };

  const handleCopyInput = (field: "source" | "target", value: string) => {
    if (updating)
      return;
    if (props.onCopyInput instanceof Function)
      props.onCopyInput(field, value);
  };

  const handleDelete = (taskId: string) => {
    if (updating)
      return;
    setUpdating(taskId);
    deleteBucketTransfer(taskId)
      .then(loadData)
      .catch((err) => console.log(err));
  };

  const handlePause = (taskId: string) => {
    if (updating)
      return;
    setUpdating("pause-" + taskId);
    pauseBucketTransfer(taskId)
      .then(loadData)
      .catch((err) => console.log(err));
  };

  const handleResume = (taskId: string) => {
    if (updating)
      return;
    setUpdating("pause-" + taskId);
    resumeBucketTransfer(taskId)
      .then(loadData)
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
    const interval = setInterval(() => loadData(), REFRESH_INTERVAL);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (transfers) {
    tasks = sortBy(transfers, [
      (task: any) => {
        switch (sortOrder.field) {
          case "fromTo":
            return task.source + " " + task.target;
          case "files":
            return task.fileCount;
          case "status":
            return task.statusIndex;
          case "progress":
            return task.finished / task.batches;
          default:
            return task.createdAt;
        }
      },
    ]);
    if (sortOrder.order === "desc")
      tasks = tasks.reverse();
    tasks = tasks.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "timestamp" || field === "fromTo" || field === "files" || field === "status" || field === "progress") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Ongoing tasks" message="Loading data..." />;
  }

  if (loadError) {
    return (
      <Feedback title="Ongoing tasks" message={loadError} />
    );
  }

  const renderControls = (task: any) => {
    return (<>
      {updating !== "pause-" + task.taskId && (task.status === "running" || task.status === "stalled") &&
        <Tooltip title="Pause">
          <PauseCircleIcon
            color="primary"
            onClick={() => handlePause(task.taskId)}
            sx={updating ? {} : { cursor: "pointer" }}
          />
        </Tooltip>
      }
      {updating !== "pause-" + task.taskId && (task.status === "paused") &&
        <Tooltip title="Resume">
          <PlayCircleIcon
            color="primary"
            onClick={() => handleResume(task.taskId)}
            sx={updating ? {} : { cursor: "pointer" }}
          />
        </Tooltip>
      }
      {updating === "pause-" + task.taskId &&
        <Tooltip title="please wait ...">
          <PendingIcon
            color="info"
          />
        </Tooltip>
      }
      {updating !== task.taskId &&
       (task.status === "finished" || task.status === "cancelled" || task.status === "error") &&
        <Tooltip title="Remove">
          <DeleteIcon
            color="error"
            onClick={() => handleDelete(task.taskId)}
            sx={updating ? {} : { cursor: "pointer" }}
          />
        </Tooltip>
      }
      {updating !== task.taskId &&
       (task.status !== "finished" && task.status !== "cancelled" && task.status !== "error") &&
        <Tooltip title="Cancel">
          <CancelIcon
            color="warning"
            onClick={() => handleCancel(task.taskId)}
            sx={updating ? {} : { cursor: "pointer" }}
          />
        </Tooltip>
      }
      {updating === task.taskId &&
        <Tooltip title="please wait ...">
          <PendingIcon
            color="info"
          />
        </Tooltip>
      }
    </>);
  }

  const renderRow = (task: any) => {
    return (
      <TableRow
        key={task.taskId}
      >
        <TableCell>
          {parseDate(task.createdAt)}
        </TableCell>
        <TableCell>
          <Table className={classes.tableTight}>
            <TableBody>
              <TableRow>
                <TableCell>Source</TableCell>
                <TableCell>
                  <span>{task.source}</span>
                  <InputIcon
                    fontSize="small"
                    onClick={() => handleCopyInput("source", task.source)}
                    sx={{ cursor: "pointer", marginLeft: "10px", verticalAlign: "top" }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Target</TableCell>
                <TableCell>
                  <span>{task.target}</span>
                  <InputIcon
                    fontSize="small"
                    onClick={() => handleCopyInput("target", task.target)}
                    sx={{ cursor: "pointer", marginLeft: "10px", verticalAlign: "top" }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        <TableCell>{task.fileCount}</TableCell>
        <TableCell>
          <Chip
            label={task.status}
            style={{ color: statusColor(task.status) }}
          />
          {task.status === "error" &&
            <Tooltip title={task.error}>
              <ReportIcon
                color="error"
                sx={{ cursor: "pointer", verticalAlign: "middle" }}
              />
            </Tooltip>
          }
          {(task.status === "running" || task.status === "stalled") &&
            <div>
              <div style={runningTimeStyle}>{"L: " + parseTime(task.timeLeft)}</div>
              <div style={runningTimeStyle}>{"T: " + parseTime(task.timeTotal)}</div>
            </div>
          }
          {task.status === "finished" &&
            <div>
              <div style={runningTimeStyle}>{"T: " + parseTime(task.timeTotal)}</div>
            </div>
          }
        </TableCell>
        {taskProgress(task)}
        <TableCell>
          {renderControls(task)}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Paper>
        <Typography
          variant="h4"
          component="h1"
          color="primary"
          className={classes.tableHeader}
        >
          Ongoing tasks
        </Typography>

        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {[
                  [ "timestamp", "Timestamp" ],
                  [ "fromTo", "From / To" ],
                  [ "files", "Files" ],
                  [ "status", "Status" ],
                  [ "progress", "Progress (%)" ],
                  [ "x", "" ]
                ].map((field) => (
                  <TableCell
                    sortDirection={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    key={field[0]}
                  >
                    <TableSortLabel
                      active={sortOrder.field === field[0] ? true : false}
                      direction={
                        sortOrder.field === field[0] ? sortOrder.order : "asc"
                      }
                      onClick={() => updateOrder(field[0])}
                    >
                      {field[1]}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.length === 0 ? (
                <TableRow key="0">
                  <TableCell>No tasks found</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ) :
                tasks.map(renderRow)
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={(transfers ? transfers.length : 1)}
          page={page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          onPageChange={(e, newPage) => setPage(newPage)}
        />

      </Paper>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />

    </React.Fragment>
  );
}
