import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  APIZone,
  getZone
} from "../../API/Orders/Zones";
import {
  APIClientsById,
  getAllClientsById
  // getClient TODO: use this when implemented in backend
} from "../../API/Orders/Clients";

export default function ViewFlightZone(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [zone, setZone] = useState<APIZone | null>(null);

  useEffect(() => {
    if (props.zone)
      setZone(props.zone);
    else if (props.zoneId) {
      getZone(props.zoneId)
        .then((result: APIZone | null) => {
          if (result === null)
            return;
          getAllClientsById()
            .then((countries: APIClientsById | null) => {
              if (countries)
                result.client = countries[result.client_id];
              setZone(result);
            })
            .catch((err: any) => {
              setZone(result);
              console.log(err)
            });
        })
        .catch((err: any) => console.log(err));
    }
  }, [ props.zone, props.zoneId ]);

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Zone: {zone && zone.name}
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            color="primary"
            className={classes.viewTitle}
          >
            Zone ID: {zone ? zone.id : props.zoneId}
          </Typography>

          {zone === null &&
            <Container className={classes.formContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Loading data ...
              </Typography>
            </Container>
          }

          {zone !== null &&
          <Container className={classes.formContainer}>

            <Box className={classes.formSectionLast}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Zone Info
              </Typography>
              <TableContainer>
                <Table className={classes.viewTable}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>{zone.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Client</TableCell>
                      <TableCell>{zone.client ? zone.client.name : ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Buffer</TableCell>
                      <TableCell>{zone.buffer}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Remarks</TableCell>
                      <TableCell>{zone.remarks}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </Container>
          }

          <Box className={classes.formButtons} >
            <Button
              variant="contained"
              color="secondary"
              className={classes.formButtonMultiple}
              onClick={props.onCancel}
            >
              Close
            </Button>
          </Box>

        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
