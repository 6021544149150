import { useQuery } from "@apollo/react-hooks";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_GEOSERVERS } from "../../graphql/Geoservers";
import * as GetGeoserversTypes from "../../graphql/__generated__/GetGeoservers";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

export default function ListGeoservers() {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "namespace" | "username" | "server" | "area";
  }>({ order: "asc", field: "namespace" });
  let geoservers: any[] = [];

  const { data, loading, error } =
    useQuery<GetGeoserversTypes.GetGeoservers>(GET_GEOSERVERS);

  if (data && data.geoservers) {
    geoservers = sortBy(data.geoservers, [
      (geoserver) => {
        if (sortOrder.field === "namespace")
          return geoserver.namespace.toLowerCase();
        if (sortOrder.field === "username")
          return geoserver.username.toLowerCase();
        if (sortOrder.field === "server")
          return geoserver.server.server.toLowerCase();
        if (sortOrder.field === "area")
          return geoserver.area.name.toLowerCase();
      },
    ]);
    if (sortOrder.order === "desc") geoservers = geoservers.reverse();
    geoservers = geoservers.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: "namespace" | "username" | "server" | "area") {
    if (sortOrder.field === field) {
      if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
      if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
    } else {
      setSortOrder({ field, order: "asc" });
    }
  }

  if (loading) {
    return <Feedback title="Geoservers" message="Loading geoservers..." />;
  }

  if (error) {
    return (
      <Feedback
        title="Geoservers"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        Geoservers
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["namespace", "Namespace"],
                ["username", "Username"],
                ["server", "Server"],
                ["area", "Area"],
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0]}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() =>
                      updateOrder(
                        field[0] as "namespace" | "username" | "server" | "area"
                      )
                    }
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {geoservers.length === 0 ? (
              <TableRow key="0">
                <TableCell>No geoservers found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              geoservers.map((geoserver: any) => (
                <TableRow
                  key={geoserver.id}
                  onClick={() => history.push(`/geoservers/${geoserver.id}`)}
                >
                  <TableCell>{geoserver.namespace}</TableCell>
                  <TableCell>{geoserver.username}</TableCell>
                  <TableCell>{geoserver.server.server}</TableCell>
                  <TableCell>{geoserver.area.name}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data ? (data.geoservers ? data.geoservers.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
