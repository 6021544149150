import gql from "graphql-tag";

export const GET_AREAS = gql`
  query GetAreas {
    areas {
      id
      name
      company {
        id
        name
      }
    }
  }
`;

export const GET_AREA = gql`
  query GetArea($id: Float!) {
    area(id: $id) {
      id
      name
      users {
        id
      }
      company {
        id
      }
    }
  }
`;

export const GET_AREA_GEOSERVERS = gql`
  query GetAreaGeoservers($id: Float!) {
    area(id: $id) {
      id
      name
      geoservers {
        id
        namespace
        username
        server {
          id
          server
        }
      }
    }
  }
`;

export const ADD_AREA = gql`
  mutation CreateArea($input: CreateAreaInput!) {
    createArea(createAreaData: $input) {
      id
      name
    }
  }
`;

export const UPDATE_AREA = gql`
  mutation UpdateArea($id: Float!, $input: UpdateAreaInput!) {
    updateArea(updateAreaData: $input, id: $id) {
      id
      name
      users {
        id
      }
      company {
        id
      }
    }
  }
`;

export const DELETE_AREA = gql`
  mutation DeleteArea($id: Float!) {
    deleteArea(id: $id)
  }
`;
