import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  TextField,
  Typography
} from "@material-ui/core";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useFormState } from "react-use-form-state";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import WarningIcon from '@mui/icons-material/Warning';

import {
  CreateAPIBucketTransfer,
  createBucketTransfer
} from "../../API/Storage/BucketTransfer";

const S3_REGEXP = /^s3:\/(\/[^/]+){2,}\/?$/;

export default function NewTransfer(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);

  const [formState, { text }] = useFormState();
  const [formWarning, setFormWarning] = useState<string | null>(null);

  useEffect(() => {
    if (props.source)
      formState.setField("source", props.source);
    if (props.target)
      formState.setField("target", props.target);
  }, [ props, formState ]);

  function handleChange(e: SyntheticEvent) {
    setFormWarning(null);
  }

  async function handleNewTransfer(e: SyntheticEvent) {
    e.preventDefault();

    let valid = true;

    if (!formState.touched.source && !formState.validity.source) {
      formState.setFieldError("source", "Please fill out this field.");
      valid = false;
    }
    else if (formState.values.source.match(S3_REGEXP) === null) {
      formState.setFieldError("source", "Not a valid AWS S3 path.");
      valid = false;
    }

    if (!formState.touched.target && !formState.validity.target) {
      formState.setFieldError("target", "Please fill out this field.");
      valid = false;
    }
    else if (formState.values.target.match(S3_REGEXP) === null) {
      formState.setFieldError("target", "Not a valid AWS S3 path.");
      valid = false;
    }

    if (valid && formWarning === null) {
      if (formState.values.source.slice(-1) !== formState.values.target.slice(-1)) {
        setFormWarning("Paths are inconsistent");
        valid = false;
      }
      else if (formState.values.source.slice(-1) !== "/") {
        setFormWarning("Paths not ending in forward slash '/'");
        valid = false;
      }
    }

    if (valid)
    {
      try {
        setFormWarning(null);
        setAdding(true);
        const transfer: CreateAPIBucketTransfer = {
          source: formState.values.source,
          target: formState.values.target
        };
        await createBucketTransfer(transfer);
        setAdding(false);
        formState.reset();
      } catch (err: any) {
        setAdding(false);
        setSnackbar({
          open: true,
          message: err.message,
          severity: "error"
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            New transfer
          </Typography>

          <form onSubmit={handleNewTransfer} noValidate>
            <TextField
              {...text({
                name: "source",
                onChange: handleChange
              })}
              label="Source Path"
              placeholder="Path"
              error={formState.errors.source ? true : false}
              helperText={formState.errors.source}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text({
                name: "target",
                onChange: handleChange
              })}
              label="Target Path"
              placeholder="Path"
              error={formState.errors.target ? true : false}
              helperText={formState.errors.target}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            {formWarning &&
              <FormHelperText
                className={classes.formWarning}
                component="div"
                error={true}
                variant="outlined"
              >
                <h4>
                  <WarningIcon />
                  <span>{formWarning}</span>
                </h4>
                <p>
                  Paths are searched and replaced as part of the complete key of the object,
                  therefore a path ending in a forward slash '/' will replace whole directories
                  (similar to moving a file), while a path ending in any other character will
                  match and rename partial path elements (similar to using prefix* in a terminal):
                </p>
                <ul>
                  <li>'my/path/' matches 'my/path/file1', 'my/path/file2' but not 'my/path2/file1'</li>
                  <li>'my/path' matches 'my/path/...', 'my/path1/...', 'my/path2/...' and so on</li>
                </ul>
                <p>
                  The target path will replace the source path in the destination:
                </p>
                <ul>
                  <li>'my/path/' -&gt; 'new/path/' renames 'my/path/file1' to 'new/path/file1'</li>
                  <li>'my/path' -&gt; 'new/path/' renames 'my/path1/file1' to 'new/path/1/file1'</li>
                  <li>'my/path/' -&gt; 'new/path' renames 'my/path/file1' to 'new/pathfile1'</li>
                  <li>'my/path' -&gt; 'new/path' renames 'my/path1/...' to 'new/path1/...'</li>
                </ul>
                <p>Press 'Add to queue' again to confirm</p>
              </FormHelperText>
            }

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={adding ? true : false}
            >
              Add to queue
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
