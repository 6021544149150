import { useQuery } from "@apollo/react-hooks";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GET_QRS } from "../../graphql/Qrs";
import * as GetQrsTypes from "../../graphql/__generated__/GetQrs";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

export default function ListQrs() {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "code" | "valid_until" | "user";
  }>({ order: "asc", field: "code" });
  let qrs: any[] = [];

  const { data, loading, error } = useQuery<GetQrsTypes.GetQrs>(GET_QRS);

  if (data && data.qrs) {
    qrs = sortBy(data.qrs, [
      (qr) => {
        if (sortOrder.field === "code") return qr.code.toLowerCase();
        if (sortOrder.field === "valid_until") return qr.valid_until;
        if (sortOrder.field === "user") return qr.user.name.toLowerCase();
      },
    ]);
    if (sortOrder.order === "desc") qrs = qrs.reverse();
    qrs = qrs.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: "code" | "valid_until" | "user") {
    if (sortOrder.field === field) {
      if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
      if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
    } else {
      setSortOrder({ field, order: "asc" });
    }
  }

  if (loading) {
    return <Feedback title="Geoservers" message="Loading geoservers..." />;
  }

  if (error) {
    return (
      <Feedback
        title="Geoservers"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        QR Logins
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["code", "Code"],
                ["valid_until", "Valid Until"],
                ["user", "User"],
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0]}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() =>
                      updateOrder(field[0] as "code" | "valid_until" | "user")
                    }
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {qrs.length === 0 ? (
              <TableRow key="0">
                <TableCell>No QR logins found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              qrs.map((qr: any) => (
                <TableRow
                  key={qr.id}
                  onClick={() => history.push(`/qrs/${qr.id}`)}
                >
                  <TableCell>{qr.code}</TableCell>
                  <TableCell>
                    {dayjs.unix(qr.valid_until).format("DD-MM-YYYY HH:mm")}
                  </TableCell>
                  <TableCell>{qr.user.name}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data ? (data.qrs ? data.qrs.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
