import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../app-context";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";

export default function ListUsers() {
  const history = useHistory();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [data, setData] = useState({ users: [] });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "email" | "path" | "bucket" | "uploadFolder";
  }>({ order: "asc", field: "email" });
  let users: any[] = [];

  const appContext = useContext(AppContext);

  useEffect(() => {
    if (appContext.gatewayApi)
      appContext.gatewayApi.getUploadUsers()
        .then((res) => {
          setData({ ...data, users: res });
          setLoading(false);
        })
        .catch((err) => setError(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data && data.users) {
    users = sortBy(data.users, [
      (user: any) => {
        if (sortOrder.field === "email") return user.UserName.toLowerCase();
        if (sortOrder.field === "path") return user.Path.toLowerCase();
        if (sortOrder.field === "bucket") return user.UploadPath.bucket.toLowerCase();
        if (sortOrder.field === "uploadFolder")
          return (user.UploadPath.bucket + "/" + user.UploadPath.path).toLowerCase();
      },
    ]);
    if (sortOrder.order === "desc") users = users.reverse();
    users = users.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "email" || field === "path" || field === "bucket" || field === "uploadFolder") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Upload users" message="Loading users..." />;
  }

  if (error) {
    return (
      <Feedback title="Upload users" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.tableHeader}
      >
        Upload users
      </Typography>

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["email", "Email / Username"],
                ["path", "Path"],
                ["bucket", "Bucket"],
                ["uploadFolder", "Upload Folder"]
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length === 0 ? (
              <TableRow key="0">
                <TableCell>No users found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              users.map((user: any) => (
                <TableRow
                  key={user.UserName}
                  onClick={() => history.push(`/users/${user.id}`)}
                >
                  <TableCell>{user.UserName}</TableCell>
                  <TableCell>{user.Path}</TableCell>
                  <TableCell>{
                      (user.UploadPath && user.UploadPath.bucket)
                        ? user.UploadPath.bucket
                        : "not set"
                  }</TableCell>
                  <TableCell>{
                      (user.UploadPath && user.UploadPath.path)
                        ? user.UploadPath.path
                        : "not set"
                  }</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data ? (data.users ? data.users.length : 1) : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />
    </Paper>
  );
}
