import { useQuery } from "@apollo/react-hooks";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import { GET_AREAS } from "../../graphql/Areas";
import * as GetAreasTypes from "../../graphql/__generated__/GetAreas";
import CompanyService from "../../services/CompanyService";
import useStyles from "../../styles";

type SelectAreaProps = {
  selectedArea: string;
  setSelectedArea: (area: string) => void;
  submitted: boolean;
};

export default function SelectArea(props: SelectAreaProps) {
  const companyService = new CompanyService();
  const classes = useStyles();

  const { data, loading, error } = useQuery<GetAreasTypes.GetAreas>(GET_AREAS);
  const [names, setNames] = useState<{ [index: string]: string }>({});

  if (data && Object.keys(names).length === 0) {
    setNames(companyService.getNames(data.areas));
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="areas-select-label">Areas</InputLabel>
      <Select
        labelId="areas-select-label"
        id="areas-select"
        value={
          error
            ? "Unable to retrieve areas"
            : Object.keys(names).length === 0
            ? ""
            : props.selectedArea
        }
        input={<Input />}
        onChange={(e) => props.setSelectedArea(e.target.value as string)}
        renderValue={(selected) => {
          return names[selected as string];
        }}
        variant="outlined"
        className={classes.formSelect}
        fullWidth
        disabled={loading || error ? true : false}
        error={props.submitted && props.selectedArea === ""}
      >
        {data?.areas?.map((area) => (
          <MenuItem key={area.id} value={area.id}>
            <Checkbox checked={props.selectedArea === area.id} />
            <ListItemText primary={area.name} />
          </MenuItem>
        ))}
      </Select>
      {props.submitted && props.selectedArea === "" && (
        <FormHelperText error={true}>A company is required</FormHelperText>
      )}
    </FormControl>
  );
}
