export const APP_URL = "http://127.0.0.1:3002";

// Production pool data
export const poolData = {
  UserPoolId: "eu-west-1_Hstdmj1G3",
  ClientId: "22ohfnkdt8g6vucofooc8sat2k",
};

// Production Graphql
export const GRAPHQL_URL =
  "https://oi4qmtpn7b.execute-api.eu-west-1.amazonaws.com/prod/graphql";

// Production API Gateway URL
export const API_GATEWAY_URL = "";
export const API_ORIGIN_URL = "";

// Production transfers api
export const STORAGE_API_URL = "https://storage-api.opusinsights.nl/prod/";
export const STORAGE_API_KEY = "HTs7kITaH4aXHWIsecmse7D2aIWTXa5u12rz6FId";

// Production flights api
export const ORDERS_API_URL =
  "https://l9cz0wsqva.execute-api.eu-west-1.amazonaws.com/v1";
export const ORDERS_API_KEY = "HTs7kITaH4aXHWIsecmse7D2aIWTXa5u12rz6FId";
