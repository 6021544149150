import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";

type FeedbackProps = {
  title: string;
  message: string;
};

export default function Feedback(props: FeedbackProps) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" component="h1" color="primary">
          {props.title}
        </Typography>

        <p>{props.message}</p>
      </CardContent>
    </Card>
  );
}
