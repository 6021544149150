import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIProcessingZone = {
  id: number;
  size: number;
};

export async function getProcessingZones(
  id: number,
  paginationToken?: number
): Promise<Array<APIProcessingZone> | null> {
  let url = `processing_zones/${id}`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    if (response.pagination_token !== undefined) {
      const moreZones = await getProcessingZones(
        id,
        response.pagination_token
      );

      if (moreZones === null) {
        return null;
      } else {
        return [...response.processing_zones, ...moreZones];
      }
    } else {
      return response.processing_zones;
    }
  } catch (err) {
    return null;
  }
}
