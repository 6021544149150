import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Autocomplete
} from "@material-ui/lab";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  CreateAPIFlight,
  createFlight
} from "../../API/Orders/Flights";

export default function CreateFlight(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);

  const formValues: { [key: string]: any } = {
    country: null,
    start_time: null,
    end_time: null,
    trigger_count: null,
    rgb_count: null,
    flight_date: (new Date()).toISOString().split("T")[0],
    altitude: null,
    camera_set: null
  };

  const [formState, { date, number, time, text }] = useFormState(formValues);

  async function handleCreate(e: SyntheticEvent) {
    e.preventDefault();
    setAdding(true);

    let hasErrors = false;
    function setFieldError(key: string, error: string) {
      formState.setFieldError(key, error);
      hasErrors = true;
    }

    try {
      Object.keys(formValues).forEach((key: string) => {
        if (key === "country")
          if (formState.values[key] === null)
            setFieldError(key, "Please select an option from the list");
        if (typeof(formValues[key]) === "string") // we only have the date field here
          if (("" + formState.values[key]).match(/^(19|20)[0-9]{2}-[0-9]{2}-[0-9]{2}$/) === null)
            setFieldError(key, "Please pick a date");
        if (key === "start_time" || key === "end_time")
          if (formState.values[key] === null)
            setFieldError(key, "Please pick a time");
        if (key === "trigger_count" || key === "rgb_count" || key === "altitude" || key === "camera_set")
          if (formState.values[key] === "" || ("" + formState.values[key]).match(/^[0-9]+$/) === null)
            setFieldError(key, "Please type a number value");
      });

      if (hasErrors) {
        setAdding(false);
        return;
      }

      const flight: CreateAPIFlight = {
        altitude: parseInt(formState.values.altitude),
        camera_set: parseInt(formState.values.camera_set),
        country_id: parseInt(formState.values.country.id),
        flight_date: formState.values.flight_date,
        flight_end: formState.values.end_time,
        flight_start: formState.values.start_time,
        nir_camera_used: !!formState.values.nir_count,
        nir_count: formState.values.nir_count ? parseInt(formState.values.nir_count) : 0,
        remarks: formState.values.remarks,
        rgb_count: parseInt(formState.values.rgb_count),
        trigger_count: parseInt(formState.values.trigger_count),
        weather: formState.values.weather
      };

      createFlight(flight)
        .then((response) => {
          if (response.success) {
            setAdding(false);
            props.onCreate && props.onCreate(response.data);
          }
          else
            throw new Error("Could not create flight");
        });
    }
    catch (err: any) {
      setSnackbar({
        open: true,
        message: err.message,
        severity: "error"
      });
      setAdding(false);
    }
  }

  const countriesById = props.data && props.data.countries ? props.data.countries : {};
  const countries = Object.values(countriesById).sort((a: any, b: any) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

  const options:{ [key: string]: any } = {
    country: countries ? countries.map((country: any) => ({
      id: country.id,
      label: country.name
    })) : []
  };

  const rememberedOptions:{ [key: string]: any } = {};

  function recallOption(key: string, e: any, reason: string) {
    if (reason === "escape" || reason === "blur")
      if (rememberedOptions[key] !== undefined)
        formState.setField(key, rememberedOptions[key]);
  }

  function rememberOption(key: string) {
    rememberedOptions[key] = formState.values[key];
  }

  function setOption(key: string, e: any, value: any, reason: string, callback?: Function) {
    formState.setField(key, value);
    if (callback)
      callback(key, value);
  }

  function renderDropDown(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <Autocomplete
          id={key}
          value={formState.values[key]}
          options={options[key]}
          onChange={(e, value, reason) => setOption(key, e, value, reason, onChange ? onChange : undefined)}
          onClose={(e, reason) => recallOption(key, e, reason)}
          onOpen={(e) => rememberOption(key)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(a: any, b: any) => a.id === b.id}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderDateField(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...date(key) }
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderTimeField(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...time(key) }
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderNumberField(key: string, label: string) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...number(key) }
          label={label}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderTextArea(key: string, label: string) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...text(key) }
          label={label}
          multiline rows={3}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Create flight
          </Typography>

          <form onSubmit={handleCreate} noValidate>

            <Container className={classes.formContainer}>

              <Box className={classes.formColumns}>
                <Box className={classes.formColumn2}>
                  {renderDropDown("country", "Country *")}
                  {renderTimeField("start_time", "Start Time *")}
                  {renderNumberField("trigger_count", "Trigger Count *")}
                </Box>

                <Box className={classes.formColumn2}>
                  {renderDateField("flight_date", "Flight Date *")}
                  {renderTimeField("end_time", "End Time *")}
                  {renderNumberField("rgb_count", "RGB Count *")}
                </Box>
              </Box>

              <Box className={classes.formColumns}>
                <Box className={classes.formColumn3}>
                  {renderNumberField("altitude", "Altitude (feet) *")}
                </Box>

                <Box className={classes.formColumn3}>
                  {renderNumberField("camera_set", "Camera Set (#) *")}
                </Box>

                <Box className={classes.formColumn3}>
                  {renderNumberField("nir_count", "NIR Count (optional)")}
                </Box>
              </Box>

              <Box className={classes.formColumns}>
                <Box className={classes.formColumn2}>
                  {renderTextArea("weather", "Weather (optional)")}
                </Box>

                <Box className={classes.formColumn2}>
                  {renderTextArea("remarks", "Remarks (optional)")}
                </Box>
              </Box>
            </Container>

            <Box className={classes.formButtons} >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButtonMultiple}
                disabled={adding ? true : false}
              >
                Create flight
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.formButtonMultiple}
                onClick={props.onCancel}
              >
                {adding ? "Close" : "Cancel"}
              </Button>
            </Box>

          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
