import values from "lodash/values";

export default class GraphqlService {
  getError(err: any) {
    const error = {
      statusCode: 0,
      message: "Unable to complete request."
    };

    for (let message of err.graphQLErrors) {
      if (message.message) {
        message = message.message;
      }

      if (message.statusCode === 401) {
        error.statusCode = 401;
        error.message = "Invalid credentials";
      }

      if (message.statusCode === 404) {
        error.statusCode = 404;
        error.message = "Resource not found";
      }

      if (message.statusCode === 400) {
        error.statusCode = 400;

        if (typeof message.message === "string") {
          error.message = message.message;
        }

        if (typeof message.message === "object") {
          error.message = values(message.message[0].constraints)[0];
        }
      }
    }

    return error;
  }
}
