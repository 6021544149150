import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import ClearIcon from "@mui/icons-material/Clear";
import FlightIcon from '@mui/icons-material/Flight';
import LoopIcon from '@mui/icons-material/Loop';

import LandscapeIcon from '@mui/icons-material/Landscape';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import SpaIcon from '@mui/icons-material/Spa';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import CameraIcon from '@mui/icons-material/Camera';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import NatureIcon from '@mui/icons-material/Nature';
import GrassIcon from '@mui/icons-material/Grass';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

import {
  SingleAPIOrder,
  getOrder
} from "../../API/Orders/Orders";
import {
  APIDeliverablesById,
  getAllDeliverablesById
} from "../../API/Orders/Deliverables";
import {
  APIFlightsById,
  getAllFlightsById
} from "../../API/Orders/Flights";

function getProductIcon (product: string) {
  switch (product) {
    case "Topography":
      return <LandscapeIcon />;
    case "Infrastructure":
      return <HomeWorkIcon />;
    case "Environmental products":
      return <InvertColorsIcon />;
    case "Plantation products":
      return <SpaIcon />;
    case "Due diligence":
      return <PlaylistAddCheckIcon />;
    case "Aerial imagery":
      return <CameraIcon />;
    case "Expansion products":
      return <AddRoadIcon />;
    case "Plant count":
      return <NatureIcon />;
    case "Crop mask":
      return <GrassIcon />;
    case "Visualisations":
      return <BubbleChartIcon />;
    default:
      return <FlightIcon />;
  }
}

function listDeliverables (deliverables: any[]) {
  return deliverables.map((deliverable) => {
    let text = deliverable.deliverable;
    if (deliverable.crop.indexOf("no crop") === -1 && deliverable.crop.indexOf("any crop") === -1)
      text = deliverable.crop + " - " + text;
    if (deliverable.development_stage)
      text += " (" + deliverable.development_stage.name + ")";
    return text;
  });
}

export default function ViewFlightOrder(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [order, setOrder] = useState<SingleAPIOrder | null>(null);
  const [deliverables, setDeliverables] = useState<APIDeliverablesById | null>(null);
  const [flights, setFlights] = useState<APIFlightsById | null>(null);

  useEffect(() => {
    if (props.order)
      setOrder(props.order);
    else if (props.orderId) {
      getOrder(props.orderId)
        .then((result: SingleAPIOrder | null) => {
          if (result === null)
            return;
          setOrder(result);
        })
        .catch((err: any) => console.log(err));
    }
    Promise.all([ getAllDeliverablesById(), getAllFlightsById() ])
      .then(([ deliverables, flights ]) => {
        if (deliverables === null || flights === null)
          return;
        setDeliverables(deliverables);
        setFlights(flights);
      })
      .catch((err: any) => console.log(err));

  }, [ props.order, props.orderId ]);

  function renderFlights () {
    if (!order)
      return (<></>);
    return (
      <List className={order.flights && order.flights.length > 0  && flights ? classes.listItems : classes.listEmpty}>
        {(!order.flights || order.flights.length === 0) &&
          <ListItem>
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText>
              no flights
            </ListItemText>
          </ListItem>
        }
        {order.flights && order.flights.length > 0 && !flights &&
          <ListItem>
            <ListItemIcon>
              <LoopIcon />
            </ListItemIcon>
            <ListItemText>
              loading data ...
            </ListItemText>
          </ListItem>
        }
        {order.flights && order.flights.length > 0 && flights && order.flights.map((flightId) => {
          const flight = flights[flightId + ""];
          if (!flight)
            return <></>;
          return (
            <ListItem key={flightId}>
              <ListItemIcon>
                <FlightIcon />
              </ListItemIcon>
              <ListItemText>
                <div>{flightId + ": " + flight.flight_date}</div>
                <div>{flight.flight_start + " - " + flight.flight_end}</div>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    );
  }

  function renderDeliverables (mode: "aggregate" | "separate") {
    if (!order)
      return (<></>);

    if (!order.deliverables || order.deliverables.length === 0)
      return (
        <List className={classes.listEmpty}>
          <ListItem>
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText>
              no deliverables
            </ListItemText>
          </ListItem>
        </List>
      );

    if (!deliverables)
      return (
        <List className={classes.listEmpty}>
          <ListItem>
            <ListItemIcon>
              <LoopIcon />
            </ListItemIcon>
            <ListItemText>
              loading data ...
            </ListItemText>
          </ListItem>
        </List>
      );

    const byProduct: { [ key: string ]: any } = {};
    order.deliverables.forEach((deliverableId) => {
      const deliverable = deliverables[deliverableId + ""];
      if (byProduct[deliverable.product] === undefined)
        byProduct[deliverable.product] = {};
      if (byProduct[deliverable.product][deliverable.crop] === undefined)
        byProduct[deliverable.product][deliverable.crop] = [];
      byProduct[deliverable.product][deliverable.crop].push(deliverable);
    });
    const items: any[] = [];
    Object.keys(byProduct).forEach((product) => {
      Object.keys(byProduct[product]).forEach((crop) => {
        let listItemText = product;
        if (crop.indexOf("no crop") === -1 && crop.indexOf("any crop") === -1)
          listItemText += " (" + crop + ")";
        if (mode === "aggregate") {
          items.push({
            icon: getProductIcon(product),
            text: listItemText,
            details: listDeliverables(byProduct[product][crop]).join(", ")
          });
        }
        else {
          listDeliverables(byProduct[product][crop]).forEach((details) => {
            items.push({
              product: product,
              icon: getProductIcon(product),
              text: listItemText,
              details: details
            });
          });
        }
      });
    });
    if (mode === "aggregate")
      items.sort((a, b) => b.details.length - a.details.length);
    else
      items.sort((a, b) => (
        a.product > b.product ? 1 : a.product < b.product ? -1 :
        a.details > b.details ? 1 : a.details < b.details ? -1 : 0
      ));
    return (
      <List className={classes.listItems}>
        {items.map((item) => (
          <ListItem className="noclick">
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>
              <div><b>{item.text}</b></div>
              <div>{item.details}</div>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Order ID: {order ? order.id : props.orderId}
          </Typography>

          <Typography
            variant="h5"
            component="h5"
            color="primary"
            className={classes.viewTitle}
          >
            Client: {order ? order.client.name : "..."}
          </Typography>

          {order === null &&
            <Container className={classes.formContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Loading data ...
              </Typography>
            </Container>
          }

          {order !== null &&
          <Container className={classes.formContainer}>

            <Box className={classes.formSection}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Order Info
              </Typography>

              <Box className={classes.formColumns}>
                <Box className={classes.formColumn2}>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Client</TableCell>
                          <TableCell>{order.client.name + " (" + order.client.id + ")"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>{order.order_date}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Flown on</TableCell>
                          <TableCell>{order.flown_date}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box className={classes.formColumn2}>
                  <TableContainer>
                    <Table className={classes.viewTable}>
                      <TableBody>
                        <TableRow>
                          <TableCell>Zone</TableCell>
                          <TableCell>{order.zone.name + " (" + order.zone.id + ")"}</TableCell>
                        </TableRow>
                        {(order.zone.size || order.zone.remarks) &&
                        <TableRow>
                          <TableCell>Zone Details</TableCell>
                          <TableCell>
                            {
                              (order.zone.size ? "size: " + order.zone.size : "") +
                              (order.zone.size && order.zone.remarks ? ", " : "") +
                              (order.zone.remarks ? order.zone.remarks : "")
                            }
                          </TableCell>
                        </TableRow>
                        }
                        <TableRow>
                          <TableCell>Status</TableCell>
                          <TableCell>{order.status.name}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>

            <Box className={classes.formSection}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Flights
              </Typography>

              {renderFlights()}
            </Box>

            <Box className={classes.formSection}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.viewSectionTitle}
              >
                Deliverables
              </Typography>

              {renderDeliverables("aggregate")}

            </Box>
          </Container>
          }

          <Box className={classes.formButtons} >
            <Button
              variant="contained"
              color="secondary"
              className={classes.formButtonMultiple}
              onClick={props.onCancel}
            >
              Close
            </Button>
          </Box>

        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
