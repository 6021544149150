import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import { ADD_USER, GET_USERS } from "../../graphql/Users";
import * as CreateUsersTypes from "../../graphql/__generated__/CreateUser";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

export default function AddUser() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);

  const [formState, { text, email, password, checkbox }] = useFormState({
    isAdmin: false
  });

  const [addUser, { data, loading }] = useMutation<CreateUsersTypes.CreateUser>(
    ADD_USER
  );

  if (adding && data) {
    setSnackbar({
      open: true,
      message: `Added user ${data.createUser.name}`,
      severity: "success"
    });
    setAdding(false);
    formState.reset();
  }

  async function handleAddUser(e: SyntheticEvent) {
    e.preventDefault();

    if (!formState.touched.name && !formState.validity.name) {
      formState.setFieldError("name", "Please fill out this field.");
    }

    if (!formState.touched.email && !formState.validity.email) {
      formState.setFieldError("email", "Please fill out this field.");
    }

    if (!formState.touched.password && !formState.validity.password) {
      formState.setFieldError("password", "Please fill out this field.");
    }

    if (
      formState.validity.name &&
      formState.validity.email &&
      formState.validity.password
    ) {
      try {
        await addUser({
          variables: {
            input: {
              name: formState.values.name,
              email: formState.values.email,
              password: formState.values.password,
              isAdmin: formState.values.isAdmin
            }
          },
          refetchQueries: [{ query: GET_USERS }],
          awaitRefetchQueries: true
        });
        setAdding(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error"
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Add a user
          </Typography>

          <form onSubmit={handleAddUser} noValidate>
            <TextField
              {...text("name")}
              label="Name"
              placeholder="Name"
              error={formState.errors.name ? true : false}
              helperText={formState.errors.name}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...email("email")}
              label="Email address"
              placeholder="Email address"
              error={formState.errors.email ? true : false}
              helperText={formState.errors.email}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...password("password")}
              label="Password"
              placeholder="Password"
              error={formState.errors.password ? true : false}
              helperText={formState.errors.password}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkbox("isAdmin")["checked"]}
                  id={checkbox("isAdmin")["id"]}
                  name={checkbox("isAdmin")["name"]}
                  onChange={checkbox("isAdmin")["onChange"]}
                  onBlur={checkbox("isAdmin")["onBlur"]}
                  color="primary"
                />
              }
              label="Is Admin"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={loading ? true : false}
            >
              Login
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
