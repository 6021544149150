import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import DeleteGeoserverServer from "./DeleteGeoserverServer";
import ListGeoservers from "./ListGeoservers";
import ShowGeoserverServer from "./ShowGeoserverServer";

export default function GeoserverServer() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ShowGeoserverServer />
      <Box mt={4} />
      <ListGeoservers />
      <Box mt={4} />
      <DeleteGeoserverServer />
    </Container>
  );
}
