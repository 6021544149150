import {
  Box,
  Container,
  Typography
} from "@material-ui/core";

import React from "react";
import useStyles from "../../styles";

import SearchField from "../SearchField/SearchField";

export default function TitleBar(props: any) {
  const classes = useStyles();

  return (
    <Container style={{ paddingLeft: 0 }}>
      <Box
        sx={{
          alignItems: "baseline",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          color="primary"
          className={classes.tableHeader}
        >
          {props.title}
        </Typography>

        <SearchField
          onChange={props.onSearchChange}
          onSearch={props.onSearch}
          value={props.searchText}
        />
      </Box>
    </Container>
  );
}
