import {
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import TitleBar from "../TitleBar/TitleBar";
import { filter, highlight } from "../SearchField/SearchField";

import CreateFlight from "./CreateFlight";
import ViewFlight from "./ViewFlight";

import {
  APIFlight,
  getAllFlights
} from "../../API/Orders/Flights";
import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";

type ComponentData = {
  flights: Array<APIFlight> | null;
  countries: APICountriesById | null;
}

export default function ListFlights() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "id" | "country" | "date" | "start" | "end" | "rgbCount";
  }>({ order: "asc", field: "id" });
  let flights: any[] = [];
  let searchFlights: any[] = [];

  const [data, setData] = useState<ComponentData>({ flights: null, countries: {} });
  const [selected, setSelected] = useState<APIFlight | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [modal, setModal] = useState<"create" | "view" | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const searchTerms = searchText ? searchText.split("/") : "";

  function loadData() {
    setLoading(true);
    Promise.all([ getAllFlights(), getAllCountriesById() ])
      .then(([ flights, countriesById ]) => {
        if (flights === null || countriesById === null)
          throw new Error("Could not load data");
        flights.forEach((flight) => flight.country = countriesById[flight.country_id + ""]);
        setData((prev: ComponentData) => ({
          ...prev,
          flights: flights,
          countries: countriesById
        }));
        setLoading(false);
      })
      .catch((err) => setError(err));
  }

  function handleCreate() {
    setModal("create");
  }

  function handleSearch(str: string) {
    setPage(0);
    setSearchText(str);
  }

  function handleView(flight: APIFlight) {
    setSelected(flight);
    setModal("view");
  }

  useEffect(loadData, []);

  if (data && data.flights) {
    searchFlights = filter(data.flights, searchTerms, [
      "flight_id", "country.name", "fight_date", "flight_start", "flight_end"
    ]);
    flights = sortBy(searchFlights, [
      (flight) => {
        if (sortOrder.field === "id") return flight.flight_id;
        if (sortOrder.field === "country")
          return flight.country && flight.country.name ? flight.country.name.toLowerCase() : "";
        if (sortOrder.field === "date")
          return flight.flight_date ? flight.flight_date.toLowerCase() : "";
        if (sortOrder.field === "start")
          return flight.flight_start ? flight.flight_start : "";
        if (sortOrder.field === "end")
          return flight.flight_end ? flight.flight_end : "";
        if (sortOrder.field === "rgbCount")
          return flight.rgb_count;
      },
    ]);
    if (sortOrder.order === "desc") flights = flights.reverse();
    flights = flights.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "id" || field === "country" || field === "date" ||
        field === "start" || field === "end" || field === "rgbCount") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Flights" message="Loading flights..." />;
  }

  if (error) {
    return (
      <Feedback title="Flights" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <TitleBar
        onSearchChange={(str: string) => handleSearch(str)}
        searchText={searchText}
        title="Flights"
      />

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["id", "Flight ID"],
                ["country", "Country"],
                ["date", "Flight Date"],
                ["start", "Start Time"],
                ["end", "End Time"],
                ["rgbCount", "RGB Count"]
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {flights.length === 0 ? (
              <TableRow key="0">
                <TableCell>No flights found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              flights.map((flight: any) => (
                <TableRow
                  key={flight.flight_id}
                  onClick={ () => handleView(flight) }
                >
                  <TableCell>{highlight(flight.flight_id, searchTerms)}</TableCell>
                  <TableCell>{flight.country && highlight(flight.country.name, searchTerms)}</TableCell>
                  <TableCell>{highlight(flight.flight_date, searchTerms)}</TableCell>
                  <TableCell>{highlight(flight.flight_start, searchTerms)}</TableCell>
                  <TableCell>{highlight(flight.flight_end, searchTerms)}</TableCell>
                  <TableCell>{flight.rgbCount}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data && searchFlights ? searchFlights.length : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />

      <Container
        className={classes.footerContainer}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={handleCreate}
        >
          New Flight
        </Button>
      </Container>

      <Modal
        className={classes.modalContainer}
        open={modal === "create"}
      >
        <div>
          <CreateFlight
            data={data}
            onCancel={() => setModal(null)}
            onCreate={() => setModal(null)}
          />
        </div>
      </Modal>

      <Modal
        className={classes.modalContainerSmall}
        open={modal === "view"}
      >
        <div>
          <ViewFlight
            flight={selected}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>

    </Paper>
  );
}
