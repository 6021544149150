import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import AddUploadUser from "./AddUploadUser";
import ListUploadUsers from "./ListUploadUsers";

export default function Users() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ListUploadUsers />
      <Box mt={4} />
      <AddUploadUser />
    </Container>
  );
}
