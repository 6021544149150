import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIJob = {
  id: number;
  client_id: number;
  client: any;
  dem: string;
  workteam_arn: string;
  flights: Array<{
    code: string;
    date: string;
    id: number;
    number: number;
  }>;
  order_id: number;
  gcp: boolean;
  start_date: number;
  status: {
    code: string;
    message: string;
    layer: {
      elevation: string;
      mosaic: string;
      tree_count: string;
    };
  };
  running: boolean;
  quality: {
    alignment_quality: {
      downscale: string;
      key_point_limit: number;
      tie_point_limit: number;
    };
    "3d_model": {
      face_count: string;
      surface_type: string;
    };
    dense_cloud_quality: {
      downscale: string;
      filter_mode: string;
      colourise: boolean;
    };
    elevation_quality: {
      ground_point_classification: boolean;
      colour_point_classification: boolean;
      max_angle: number;
      max_distance: number;
      search_radius: number;
    };
  };
  processing_zone: number;
  execution_id: string;
  ec2_id: {
    mosaic: string;
    preprocessing: string;
    tree_count: string;
  };
  crops: Array<{
    id: number;
    name: string;
  }>;
  number_of_files: number;
};

export type CreateAPIJob = {
  order_id: number;
  dem: string;
  workteam_arn: string;
  gcp: boolean;
  processing_zone: number;
  quality: {
    "3d_model": {
      face_count: string;
      surface_type: string;
    };
    alignment_quality: {
      downscale: string;
      key_point_limit: number;
      tie_point_limit: number;
    };
    dense_cloud_quality: {
      colourise: boolean;
      downscale: string;
      filter_mode: string;
    };
    elevation_quality: {
      colour_point_classification: boolean;
      ground_point_classification: boolean;
      max_angle: number;
      max_distance: number;
      search_radius: number;
    };
  };
};

export async function getAllJobs(
  paginationToken?: number
): Promise<Array<APIJob>> {
  let url = `jobs`;
  if (paginationToken) {
    url = `${url}?token=${paginationToken}`;
  }

  try {
    const response = await api.get(url);

    if (response.pagination_token !== undefined) {
      const moreJobs = await getAllJobs(response.jobs.pagination_token);
      return [...response.jobs.jobs, ...moreJobs];
    }
    else {
      return response.jobs.jobs;
    }
  }
  catch (err: any) {
    throw err;
  }
}

export async function getJob(id: string): Promise<APIJob | null> {
  let url = `jobs/${id}`;

  try {
    const response = await api.get(url);

    if (response[0] !== undefined) {
      return response[0];
    }

    return null;
  }
  catch (err: any) {
    throw err;
  }
}

export async function resumeJob(job_id: string): Promise<string> {
  try {
    await api.patch(`jobs/${job_id}`, {});
    return "Successfully resumed";
  }
  catch (err: any) {
    throw err;
  }
}

export async function stopJob(job_id: string): Promise<string> {
  try {
    await api.delete(`jobs/${job_id}`);
    return "Successfully stopped";
  }
  catch (err: any) {
    throw err;
  }
}

export async function createJob(
  payload: CreateAPIJob
): Promise<string> {
  try {
    const response = await api.post(`jobs`, payload);
    return response.id;
  } catch (err: any) {
    throw err;
  }
}
