import { Box, Container } from "@material-ui/core";
import React from "react";
import useStyles from "../../styles";
import DeleteCompany from "./DeleteCompany";
import ListAreas from "./ListAreas";
import ShowCompany from "./ShowCompany";

export default function Company() {
  const classes = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <ShowCompany />
      <Box mt={4} />
      <ListAreas />
      <Box mt={4} />
      <DeleteCompany />
    </Container>
  );
}
