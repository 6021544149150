export class GatewayApi {

    baseUrl: string
    origin: string

    constructor (baseUrl: string, origin: string) {
      this.baseUrl = baseUrl;
      this.origin = origin;
    }

    getOptions (method: string, body: object | null) {
      return ({
        method: method,
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Origin': this.origin,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: body ? JSON.stringify(body) : undefined
      });
    }

    async doFetch (url: string, options: object) {
      url = this.baseUrl + url;
      const res = await fetch(url, options);
      const payload = await res.json();
      if (!payload.statusCode)
        throw new Error("Bad response");
      if (payload.statusCode > 201)
        throw new Error(payload.message);
      return payload.body;
    }

    async getData (url: string) {
      const options = this.getOptions("GET", null);
      const res = await this.doFetch(url, options);
      return res;
    }

    async postData (url: string, data: object) {
      const options = this.getOptions("POST", data);
      const res = await this.doFetch(url, options);
      return res;
    }

    async deleteData (url: string) {
      const options = this.getOptions("DELETE", null);
      const res = await this.doFetch(url, options);
      return res;
    }

    /* upload users feature */
    async getUploadUsers () {
      const res = await this.getData("/upload-users");
      return res.users;
    }

    async addUploadUser (path: string, email: string, folder: string, comment: string) {
      const userData = {
        "region": "eu-central-1",
        "bucket": "opus-upload-2",
        "user": {
          "path": path,
          "email": email,
        },
        "folder": folder,
        "documentPath": comment
      };
      const res = await this.postData("/upload-user", userData);
      return res;
    }

}
