import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import { ADD_COMPANY, GET_COMPANIES } from "../../graphql/Companies";
import * as CreateCompanyTypes from "../../graphql/__generated__/CreateCompany";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import SelectUsers from "../Forms/SelectUsers";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

export default function AddCompany() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [adding, setAdding] = useState(false);

  const [formState, { text }] = useFormState({});

  const [addCompany, { data, loading }] = useMutation<
    CreateCompanyTypes.CreateCompany
  >(ADD_COMPANY);

  if (adding && data) {
    setSnackbar({
      open: true,
      message: `Added company ${data.createCompany.name}`,
      severity: "success"
    });

    setAdding(false);
    setSelectedUsers([]);
    formState.reset();
  }

  async function handleAddCompany(e: SyntheticEvent) {
    e.preventDefault();

    if (!formState.touched.name && !formState.validity.name) {
      formState.setFieldError("name", "Please fill out this field.");
    }

    if (formState.validity.name) {
      try {
        await addCompany({
          variables: {
            input: {
              name: formState.values.name,
              userIds: selectedUsers
            }
          },
          refetchQueries: [{ query: GET_COMPANIES }],
          awaitRefetchQueries: true
        });
        setAdding(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error"
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Add a Company
          </Typography>

          <form onSubmit={handleAddCompany} noValidate>
            <TextField
              {...text("name")}
              label="Name"
              placeholder="Name"
              error={formState.errors.name ? true : false}
              helperText={formState.errors.name}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <SelectUsers
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={loading ? true : false}
            >
              Add Company
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
