import { Snackbar as MuiSnackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { SyntheticEvent } from "react";

export interface SnackbarState {
  open: boolean;
  message: string;
  severity: "error" | "success" | "info" | "warning";
}

type SnackbarProps = {
  state: SnackbarState;
  handleClose: (e: SyntheticEvent) => void;
};

export const defaultSnackbarState: SnackbarState = {
  open: false,
  message: "",
  severity: "info"
};

export default function Snackbar({ state, handleClose }: SnackbarProps) {
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={state.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert
        onClose={handleClose}
        severity={state.severity}
        variant="filled"
        elevation={6}
      >
        {state.message}
      </MuiAlert>
    </MuiSnackbar>
  );
}
