import { useQuery } from "@apollo/react-hooks";
import {
  Checkbox,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";
import React, { useState } from "react";
import { GET_USERS } from "../../graphql/Users";
import * as GetUsersTypes from "../../graphql/__generated__/GetUsers";
import CompanyService from "../../services/CompanyService";
import useStyles from "../../styles";

type SelectUserProps = {
  selectedUser: string;
  setSelectedUser: (users: string) => void;
  submitted: boolean;
};

export default function SelectUser(props: SelectUserProps) {
  const companyService = new CompanyService();
  const classes = useStyles();

  const { data, loading, error } = useQuery<GetUsersTypes.GetUsers>(GET_USERS);
  const [names, setNames] = useState<{ [index: string]: string }>({});

  if (data && Object.keys(names).length === 0) {
    setNames(companyService.getNames(data.users));
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="user-select-label">User</InputLabel>
      <Select
        labelId="user-select-label"
        id="user-select"
        value={
          error
            ? "Unable to retrieve users"
            : Object.keys(names).length === 0
            ? ""
            : props.selectedUser
        }
        input={<Input />}
        onChange={e => props.setSelectedUser(e.target.value as string)}
        renderValue={selected => {
          return names[props.selectedUser];
        }}
        variant="outlined"
        className={classes.formSelect}
        fullWidth
        disabled={loading || error ? true : false}
        error={props.submitted && props.selectedUser === ""}
      >
        {data?.users?.map(user => (
          <MenuItem key={user.id} value={user.id}>
            <Checkbox checked={props.selectedUser === user.id} />
            <ListItemText primary={user.name} />
          </MenuItem>
        ))}
      </Select>
      {props.submitted && props.selectedUser === "" && (
        <FormHelperText error={true}>A user is required</FormHelperText>
      )}
    </FormControl>
  );
}
