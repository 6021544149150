import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIDeliverables = {
  deliverable: string;
  id: number;
  crop: string;
  product: string;
  development_stage: {
    id: number;
    name: string;
  };
};

export type APIDeliverablesById = { [ key: string ] : APIDeliverables };

export async function getAllDeliverables(): Promise<Array<APIDeliverables>> {
  try {
    return await api.get("deliverables");
  }
  catch (err: any) {
    throw err;
  }
}

export async function getAllDeliverablesById(): Promise<APIDeliverablesById> {
  try {
    const deliverables = await getAllDeliverables();
    const ret: { [ key: string] : APIDeliverables } = {};
    deliverables.forEach((deliverable) => ret[deliverable.id + ""] = deliverable);
    return ret;
  }
  catch (err: any) {
    throw err;
  }
}

