import gql from "graphql-tag";

export const GET_GEOSERVERS = gql`
  query GetGeoservers {
    geoservers {
      id
      namespace
      username
      server {
        id
        server
      }
      area {
        id
        name
      }
    }
  }
`;

export const GET_GEOSERVER = gql`
  query GetGeoserver($id: Float!) {
    geoserver(id: $id) {
      namespace
      username
      password
      server {
        id
      }
      area {
        id
      }
    }
  }
`;

export const ADD_GEOSERVER = gql`
  mutation CreateGeoserver($input: CreateGeoserverInput!) {
    createGeoserver(createGeoserverData: $input) {
      id
      namespace
      username
    }
  }
`;

export const UPDATE_GEOSERVER = gql`
  mutation UpdateGeoserver($id: Float!, $input: UpdateGeoserverInput!) {
    updateGeoserver(updateGeoserverData: $input, id: $id) {
      id
      namespace
      username
      password
      server {
        id
      }
      area {
        id
      }
    }
  }
`;

export const DELETE_GEOSERVER = gql`
  mutation DeleteGeoserver($id: Float!) {
    deleteGeoserver(id: $id)
  }
`;
