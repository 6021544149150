import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Autocomplete
} from "@material-ui/lab";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  CreateAPIJob,
  createJob
} from "../../API/Orders/Jobs";
import {
  APIProcessingZone,
  getProcessingZones
} from "../../API/Orders/ProcessingZones";

export default function CreateFlightJob(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);
  const [zones, setZones] = useState<Array<APIProcessingZone> | null>(null);

  const formValues: { [key: string]: any } = {
    order: null,
    dem: null,
    workteam: null,
    processing_zone: null,
    gcp: expandOption([ "no", "No" ]),
    face_count: expandOption([ "low", "Low" ]),
    surface_type: expandOption([ "height", "Height" ]),
    cloud_colourise: expandOption([ "no", "No" ]),
    cloud_downscale: expandOption([ "average", "Average" ]),
    cloud_filter_mode: expandOption([ "aggressive", "Aggressive" ]),
    alignment_downscale: expandOption([ "high", "High" ]),
    alignment_key_point_limit: 120000,
    alignment_tie_point_limit: 80000,
    elevation_colour_point_classification: expandOption([ "no", "No" ]),
    elevation_ground_point_classification: expandOption([ "no", "No" ]),
    elevation_max_angle: 5,
    elevation_max_distance: 5,
    elevation_search_radius: 50
  };

  const [formState, { number }] = useFormState(formValues);

  function setError(e: any) {
    if (e instanceof Error)
      e = e.message;
    setSnackbar({
      open: true,
      message: e,
      severity: "error"
    });
  }

  async function handleCreate(e: SyntheticEvent) {
    e.preventDefault();
    setAdding(true);

    let hasErrors = false;
    function setFieldError(key: string, error: string) {
      formState.setFieldError(key, error);
      hasErrors = true;
    }

    try {
      Object.keys(formValues).forEach((key: string) => {
        if (typeof(formValues[key]) === "object")
          if (formState.values[key] === null)
            setFieldError(key, "Please select an option from the list");
        if (typeof(formValues[key]) === "number")
          if (formState.values[key] === "" || ("" + formState.values[key]).match(/^[0-9]+$/) === null)
            setFieldError(key, "Please enter a valid integer number");
      });

      if (hasErrors) {
        setAdding(false);
        return;
      }

      const job: CreateAPIJob = {
        order_id: parseInt(formState.values.order.id),
        dem: formState.values.dem.id,
        workteam_arn: formState.values.workteam.arn,
        gcp: formState.values.gcp.id === "yes",
        processing_zone: parseInt(formState.values.processing_zone.id),
        quality: {
          "3d_model": {
            face_count: formState.values.face_count.id,
            surface_type: formState.values.surface_type.id
          },
          alignment_quality: {
            downscale: formState.values.alignment_downscale.id,
            key_point_limit: parseInt(formState.values.alignment_key_point_limit),
            tie_point_limit: parseInt(formState.values.alignment_tie_point_limit)
          },
          dense_cloud_quality: {
            colourise: formState.values.cloud_colourise.id === "yes",
            downscale: formState.values.cloud_downscale.id,
            filter_mode: formState.values.cloud_filter_mode.id
          },
          elevation_quality: {
            colour_point_classification: formState.values.elevation_colour_point_classification.id === "yes",
            ground_point_classification: formState.values.elevation_ground_point_classification.id === "yes",
            max_angle: parseInt(formState.values.elevation_max_angle),
            max_distance: parseInt(formState.values.elevation_max_distance),
            search_radius: parseInt(formState.values.elevation_search_radius)
          }
        }
      };

      createJob(job)
        .then((response) => {
          setAdding(false);
          props.onCreate && props.onCreate(response);
        })
        .catch((err: any) => {
          setError(err);
        });
    }
    catch (err: any) {
      setError(err);
      setAdding(false);
    }
  }

  function handleChange(field: string, value: any) {
    if (field === "order") {
      formState.resetField("zone");
      if (zones !== null)
        setZones([]);
      if (value) {
        getProcessingZones(value.id)
          .then((result) => {
            setZones(result);
          }).catch((err: any) => {
            setError(err);
          });
      }
    }
  }

  const orders = props.data && props.data.orders ? props.data.orders : {};
  const dems = props.data && props.data.dems ? props.data.dems : [];
  const workteams = props.data && props.data.workteams ? props.data.workteams : {};

  function expandOption(option: Array<any>): { [key: string]: any } {
    return { id: option[0], label: option[1], value: option[2] };
  }

  function expandOptions(options: Array<Array<any>>): Array<{ [key: string]: any }> {
    return options.map(expandOption);
  }

  const options:{ [key: string]: any } = {
    dem: dems ? dems.map((dem: any) => ({
      id: dem.s3_location,
      label: dem.s3_location
    })) : [],
    order: orders ? Object.values(orders).map((order: any) => ({
      id: order.id,
      label: order.id + " / " + order.client.name + " / " + order.zone.name + " (" + order.status.name + ")"
    })) : [],
    processing_zone: zones ? zones.map((zone: any) => ({
      id: zone.id,
      label: zone.id + ": size " + zone.size
    })) : [],
    workteam: workteams ? Object.values(workteams).map((workteam: any) => ({
      id: workteam.arn,
      arn: workteam.arn,
      label: workteam.name
    })) : [],
    gcp: expandOptions([ [ "no", "No" ], [ "yes", "Yes" ] ]),
    face_count: expandOptions([ [ "low", "Low" ], [ "average", "Average" ], [ "high", "High" ] ]),
    surface_type: expandOptions([ [ "height", "Height" ], [ "arbitrary", "Arbitrary" ] ]),
    cloud_colourise: expandOptions([ [ "no", "No" ], [ "yes", "Yes" ] ]),
    cloud_downscale: expandOptions([
      [ "none", "None" ], [ "lowest", "Lowest" ], [ "low", "Low" ],
      [ "average", "Average" ], [ "high", "High" ], [ "highest", "Highest" ]
    ]),
    cloud_filter_mode: expandOptions([
      [ "none", "None" ], [ "mild", "Mild" ], [ "moderate", "Moderate" ], [ "aggressive", "Aggressive" ]
    ]),
    alignment_downscale: expandOptions([
      [ "lowest", "Lowest" ], [ "low", "Low" ], [ "average", "Average" ], [ "high", "High" ], [ "highest", "Highest" ]
    ]),
    elevation_colour_point_classification: expandOptions([ [ "no", "No" ], [ "yes", "Yes" ] ]),
    elevation_ground_point_classification: expandOptions([ [ "no", "No" ], [ "yes", "Yes" ] ])
  };

  const rememberedOptions:{ [key: string]: any } = {};

  function recallOption(key: string, e: any, reason: string) {
    if (reason === "escape" || reason === "blur")
      if (rememberedOptions[key] !== undefined)
        formState.setField(key, rememberedOptions[key]);
  }

  function rememberOption(key: string) {
    rememberedOptions[key] = formState.values[key];
  }

  function setOption(key: string, e: any, value: any, reason: string, callback?: Function) {
    formState.setField(key, value);
    if (callback)
      callback(key, value);
  }

  function renderDropDown(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <Autocomplete
          id={key}
          value={formState.values[key]}
          options={options[key]}
          onChange={(e, value, reason) => setOption(key, e, value, reason, onChange ? onChange : undefined)}
          onClose={(e, reason) => recallOption(key, e, reason)}
          onOpen={(e) => rememberOption(key)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(a: any, b: any) => a.id === b.id}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderNumberField(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...number(key) }
          label={label}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Create Job
          </Typography>

          <form onSubmit={handleCreate} noValidate>

            <Container className={classes.formContainer}>

              {renderDropDown("order", "Order *", handleChange)}
              {renderDropDown("dem", "Dem *")}
              {renderDropDown("workteam", "Workteam *")}
              {renderDropDown("processing_zone", "Processing Zone *")}

              <FormControl style={{marginTop: "-1em"}} fullWidth error={zones === null}>
                { zones === null &&
                  <FormHelperText id="processing_zone-hint2">
                    Select an order to load the associated processing zones
                  </FormHelperText>
                }
              </FormControl>

              <Box className={classes.formColumns}>
                <Box className={classes.formColumn2}>
                  {renderDropDown("gcp", "Ground Control Point")}
                  {renderDropDown("face_count", "3D Model Face Count")}
                  {renderDropDown("surface_type", "3D Model Surface Type")}
                  {renderDropDown("cloud_colourise", "Dense Cloud Quality Colourise")}
                  {renderDropDown("cloud_downscale", "Dense Cloud Quality Downscale")}
                  {renderDropDown("cloud_filter_mode", "Dense Cloud Quality Filter Mode")}
                </Box>

                <Box className={classes.formColumn2}>
                  {renderDropDown("alignment_downscale", "Alignment Quality Downscale")}
                  {renderNumberField("alignment_key_point_limit", "Alignment Quality Key Point Limit")}
                  {renderNumberField("alignment_tie_point_limit", "Alignment Quality Tie Point Limit")}
                  {renderDropDown("elevation_colour_point_classification", "Elevation Colour Point Classification")}
                  {renderDropDown("elevation_ground_point_classification", "Elevation Ground Point Classification")}
                  {renderNumberField("elevation_max_angle", "Elevation Quality Max Angle")}
                  {renderNumberField("elevation_max_distance", "Elevation Quality Max Distance")}
                  {renderNumberField("elevation_search_radius", "Elevation Quality Search Radius")}
                </Box>
              </Box>

            </Container>

            <Box className={classes.formButtons} >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButtonMultiple}
                disabled={adding ? true : false}
              >
                Create Job
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.formButtonMultiple}
                onClick={props.onCancel}
              >
                {adding ? "Close" : "Cancel"}
              </Button>
            </Box>

          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
