import {
  Button,
  Container,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import sortBy from "lodash/sortBy";
import React, { useState, useEffect } from "react";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import TitleBar from "../TitleBar/TitleBar";
import { filter, highlight } from "../SearchField/SearchField";

import CreateFlightClient from "./CreateFlightClient";
import ViewFlightClient from "./ViewFlightClient";

import {
  APIClient,
  getAllClients
} from "../../API/Orders/Clients";
import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";

type ComponentData = {
  clients: Array<APIClient> | null;
  countries: APICountriesById | null;
}

export default function ListFlightClients() {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [sortOrder, setSortOrder] = useState<{
    order: "asc" | "desc";
    field: "id" | "name" | "processingName" | "contactName" | "contactAddress" | "country";
  }>({ order: "asc", field: "id" });
  let clients: any[] = [];
  let searchClients: any[] = [];

  const [data, setData] = useState<ComponentData>({ clients: null, countries: {} });
  const [selected, setSelected] = useState<APIClient | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [modal, setModal] = useState<"create" | "view" | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const searchTerms = searchText ? searchText.split("/") : "";

  function loadData() {
    setLoading(true);
    Promise.all([ getAllClients(), getAllCountriesById() ])
      .then(([ clients, countriesById ]) => {
        if (clients === null || countriesById === null)
          throw new Error("Could not load data");
        clients.forEach((client) => client.country = countriesById[client.country_id + ""]);
        setData((prev: ComponentData) => ({
          ...prev,
          clients: clients,
          countries: countriesById
        }));
        setLoading(false);
      })
      .catch((err) => setError(err));
  }

  function handleCreate() {
    setModal("create");
  }

  function handleSearch(str: string) {
    setPage(0);
    setSearchText(str);
  }

  function handleView(client: APIClient) {
    setSelected(client);
    setModal("view");
  }

  useEffect(loadData, []);

  if (data && data.clients) {
    searchClients = filter(data.clients, searchTerms, [
      "id", "name", "processing_name", "contact_name", "contact_address", "country.name"
    ]);
    clients = sortBy(searchClients, [
      (client) => {
        if (sortOrder.field === "id") return client.id;
        if (sortOrder.field === "name")
          return client.name ? client.name.toLowerCase() : "";
        if (sortOrder.field === "processingName")
          return client.processing_name ? client.processing_name.toLowerCase() : "";
        if (sortOrder.field === "contactName")
          return client.contact_name ? client.contact_name.toLowerCase() : "";
        if (sortOrder.field === "contactAddress")
          return client.contact_address ? client.contact_address.toLowerCase() : "";
        if (sortOrder.field === "country")
          return client.country && client.country.name ? client.country.name.toLowerCase() : "";
      },
    ]);
    if (sortOrder.order === "desc") clients = clients.reverse();
    clients = clients.slice(page * 10, page * 10 + 10);
  }

  function updateOrder(field: string) {
    if (field === "id" || field === "name" || field === "processingName" ||
        field === "contactName" || field === "contactAddress" || field === "country") {
      if (sortOrder.field === field) {
        if (sortOrder.order === "asc") setSortOrder({ field, order: "desc" });
        if (sortOrder.order === "desc") setSortOrder({ field, order: "asc" });
      } else {
        setSortOrder({ field, order: "asc" });
      }
    }
  }

  if (loading) {
    return <Feedback title="Flight Clients" message="Loading clients..." />;
  }

  if (error) {
    return (
      <Feedback title="Flight Clients" message="Unable to load data from the backend" />
    );
  }

  return (
    <Paper>
      <TitleBar
        onSearchChange={(str: string) => handleSearch(str)}
        searchText={searchText}
        title="Flight Clients"
      />

      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {[
                ["id", "Client ID"],
                ["name", "Name"],
                ["processingName", "Processing Name"],
                ["contactName", "Contact Name"],
                ["contactAddress", "Contact Address"],
                ["country", "Country"]
              ].map((field) => (
                <TableCell
                  sortDirection={
                    sortOrder.field === field[0] ? sortOrder.order : "asc"
                  }
                  key={field[0]}
                >
                  <TableSortLabel
                    active={sortOrder.field === field[0] ? true : false}
                    direction={
                      sortOrder.field === field[0] ? sortOrder.order : "asc"
                    }
                    onClick={() => updateOrder(field[0])}
                  >
                    {field[1]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.length === 0 ? (
              <TableRow key="0">
                <TableCell>No clients found</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              clients.map((client: any) => (
                <TableRow
                  key={client.id}
                  onClick={ () => handleView(client) }
                >
                  <TableCell>{highlight(client.id, searchTerms)}</TableCell>
                  <TableCell>{highlight(client.name, searchTerms)}</TableCell>
                  <TableCell>{highlight(client.processing_name, searchTerms)}</TableCell>
                  <TableCell>{highlight(client.contact_name, searchTerms)}</TableCell>
                  <TableCell>{highlight(client.contact_address, searchTerms)}</TableCell>
                  <TableCell>{highlight(client.country.name, searchTerms)}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={data && searchClients ? searchClients.length : 1}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        onPageChange={(e, newPage) => setPage(newPage)}
      />

      <Container
        className={classes.footerContainer}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={handleCreate}
        >
          New Client
        </Button>
      </Container>

      <Modal
        className={classes.modalContainer}
        open={modal === "create"}
      >
        <div>
          <CreateFlightClient
            data={data}
            onCancel={() => setModal(null)}
            onCreate={() => setModal(null)}
          />
        </div>
      </Modal>

      <Modal
        className={classes.modalContainerSmall}
        open={modal === "view"}
      >
        <div>
          <ViewFlightClient
            client={selected}
            onCancel={() => setModal(null)}
          />
        </div>
      </Modal>

    </Paper>
  );
}
