import { CircularProgress, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "../../store";
import getCognitoToken from "../../utils";

interface StartProps {
  children: any;
}

function Start(props: StartProps) {
  const setLoggedIn = useStoreActions((actions) => actions.setLoggedIn);
  const isAuthenticated = useStoreState((state) => state.authenticated);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const testLogin = async () => {
      try {
        await getCognitoToken();
        setLoggedIn(undefined);
      } catch (err) {
        setReady(true);
      }
    };

    testLogin();
  }, [setLoggedIn]);

  useEffect(() => {
    if (isAuthenticated && !ready) {
      setReady(true);
    }
  }, [isAuthenticated, ready]);

  if (ready) {
    return props.children;
  }

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <CircularProgress />
    </Grid>
  );
}

export default Start;
