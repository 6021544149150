import { STORAGE_API_KEY, STORAGE_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(STORAGE_API_KEY, STORAGE_API_URL);

export type APIBucketTransfer = {
  taskId: string,
  source: string,
  target: string,
  status: string,
  statusIndex: number,
  createdAt: number | null,
  queuedAt: number | null,
  startedAt: number | null,
  finishedAt: number | null,
  batches: number,
  fileCount: number,
  queued: number,
  running: number,
  finished: number
};

export type CreateAPIBucketTransfer = {
  source: string,
  target: string
};

export async function getAllBucketTransfers (): Promise<Array<APIBucketTransfer>> {
  try {
    const result = await api.get("bucket/transfers");
    return result.body;
  }
  catch (err: any) {
    throw err;
  }
}

export async function createBucketTransfer (transfer: CreateAPIBucketTransfer): Promise<any> {
  try {
    return await api.post("bucket/transfer", transfer);
  }
  catch (err: any) {
    throw err;
  }
}

export async function cancelBucketTransfer (taskId: string): Promise<any> {
  try {
    return await api.post("bucket/transfer/" + taskId + "/cancel", { value: true });
  }
  catch (err: any) {
    throw err;
  }
}

export async function deleteBucketTransfer (taskId: string): Promise<any> {
  try {
    return await api.delete("bucket/transfer/" + taskId);
  }
  catch (err: any) {
    throw err;
  }
}

export async function pauseBucketTransfer (taskId: string): Promise<any> {
  try {
    return await api.post("bucket/transfer/" + taskId + "/pause", { value: true });
  }
  catch (err: any) {
    throw err;
  }
}

export async function resumeBucketTransfer (taskId: string): Promise<any> {
  try {
    return await api.post("bucket/transfer/" + taskId + "/pause", { value: false });
  }
  catch (err: any) {
    throw err;
  }
}
