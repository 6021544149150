import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { useFormState } from "react-use-form-state";
import {
  ADD_GEOSERVER_SERVER,
  GET_GEOSERVER_SERVERS,
} from "../../graphql/GeoserverServers";
import * as CreateGeoserverServerTypes from "../../graphql/__generated__/CreateGeoserverServer";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

export default function AddGeoserverServer() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);

  const [formState, { text, url }] = useFormState({});

  const [addGeoserverServer, { data, loading }] = useMutation<
    CreateGeoserverServerTypes.CreateGeoserverServer
  >(ADD_GEOSERVER_SERVER);

  if (adding && data) {
    setSnackbar({
      open: true,
      message: `Added company ${data.createGeoserverServer.server}`,
      severity: "success",
    });

    setAdding(false);
    formState.reset();
  }

  async function handleAddGeoserver(e: SyntheticEvent) {
    e.preventDefault();

    if (!formState.touched.server && !formState.validity.server) {
      formState.setFieldError("server", "Please fill out this field.");
    }

    if (!formState.touched.adminUsername && !formState.validity.adminUsername) {
      formState.setFieldError("adminUsername", "Please fill out this field.");
    }

    if (!formState.touched.adminPassword && !formState.validity.adminPassword) {
      formState.setFieldError("adminPassword", "Please fill out this field.");
    }

    if (
      formState.validity.server &&
      formState.validity.adminUsername &&
      formState.validity.adminPassword
    ) {
      try {
        await addGeoserverServer({
          variables: {
            input: {
              server: formState.values.server,
              adminUsername: formState.values.adminUsername,
              adminPassword: formState.values.adminPassword,
            },
          },
          refetchQueries: [{ query: GET_GEOSERVER_SERVERS }],
          awaitRefetchQueries: true,
        });
        setAdding(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Add a geoserver server
          </Typography>

          <form onSubmit={handleAddGeoserver} noValidate>
            <TextField
              {...url("server")}
              label="server"
              placeholder="server"
              error={formState.errors.server ? true : false}
              helperText={formState.errors.server}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("adminUsername")}
              label="Admin Username"
              placeholder="Admin Username"
              error={formState.errors.adminUsername ? true : false}
              helperText={formState.errors.adminUsername}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("adminPassword")}
              label="Admin Password"
              placeholder="Admin Password"
              error={formState.errors.adminPassword ? true : false}
              helperText={formState.errors.adminPassword}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={loading ? true : false}
            >
              Add Geoserver Server
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
