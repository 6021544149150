import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { SyntheticEvent, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import {
  GET_GEOSERVER,
  GET_GEOSERVERS,
  UPDATE_GEOSERVER,
} from "../../graphql/Geoservers";
import * as GetGeoserverTypes from "../../graphql/__generated__/GetGeoserver";
import * as UpdateGeoserverTypes from "../../graphql/__generated__/UpdateGeoserver";
import GraphqlService from "../../services/GraphqlService";
import useStyles from "../../styles";
import Feedback from "../Feedback/Feedback";
import SelectArea from "../Forms/SelectArea";
import SelectGeoserverServer from "../Forms/SelectGeoserverServer";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

interface ShowGeoserverParams {
  id: string;
}

export default function ShowGeoserver() {
  const graphqlService = new GraphqlService();

  const classes = useStyles();
  const { id } = useParams<ShowGeoserverParams>();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [selectedGeoserverServer, setSelectedGeoserverServer] = useState<
    string
  >("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [updating, setUpdating] = useState(false);

  const {
    data: geoserverData,
    loading: geoserverLoading,
    error: geoserverError,
  } = useQuery<GetGeoserverTypes.GetGeoserver>(GET_GEOSERVER, {
    variables: { id: parseFloat(String(id)) },
  });
  const [
    updateGeoserver,
    { data: updateGeoserverData, loading: UpdateGeoserverLoading },
  ] = useMutation<UpdateGeoserverTypes.UpdateGeoserver>(UPDATE_GEOSERVER);

  const [formState, { text }] = useFormState({
    namespace: "",
    username: "",
    password: "",
  });

  if (!formState.touched.namespace && geoserverData) {
    formState.setField("namespace", geoserverData.geoserver.namespace);
    formState.setField("username", geoserverData.geoserver.username);
    formState.setField("password", geoserverData.geoserver.password);
    setSelectedGeoserverServer(geoserverData.geoserver.server.id);
    setSelectedArea(geoserverData.geoserver.area.id);
  }

  async function handleUpdateGeoserver(e: SyntheticEvent) {
    e.preventDefault();

    if (
      formState.validity.namespace &&
      formState.validity.username &&
      formState.validity.password &&
      selectedGeoserverServer !== "" &&
      selectedArea !== ""
    ) {
      try {
        await updateGeoserver({
          variables: {
            id: parseFloat(String(id)),
            input: {
              username: formState.values.username,
              password: formState.values.password,
              namespace: formState.values.namespace,
              geoserverServerId: selectedGeoserverServer,
              areaId: selectedArea,
            },
          },
          refetchQueries: [{ query: GET_GEOSERVERS }],
          awaitRefetchQueries: true,
        });
        setUpdating(true);
      } catch (err) {
        const error = graphqlService.getError(err);

        setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
      }
    }
  }

  if (updating && updateGeoserverData) {
    setSnackbar({
      open: true,
      message: `Updated geoserver namespace ${updateGeoserverData.updateGeoserver.namespace}`,
      severity: "success",
    });
    setUpdating(false);
  }

  if (geoserverLoading) {
    return <Feedback title="Edit Geoserver" message="Loading data..." />;
  }

  if (geoserverError) {
    const err = graphqlService.getError(geoserverError);

    if (err.statusCode === 404) {
      return <Redirect to="/geoserver" />;
    }

    return (
      <Feedback
        title="Edit Geoserver"
        message="Unable to load data from the backend"
      />
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography variant="h4" component="h1" color="primary">
            Edit geoserver server
          </Typography>

          <form onSubmit={handleUpdateGeoserver} noValidate>
            <TextField
              {...text("namespace")}
              label="Namespace"
              placeholder="Namespace"
              error={formState.errors.namespace ? true : false}
              helperText={formState.errors.namespace}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("username")}
              label="Username"
              placeholder="Username"
              error={formState.errors.username ? true : false}
              helperText={formState.errors.username}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <TextField
              {...text("password")}
              label="Password"
              placeholder="Password"
              error={formState.errors.password ? true : false}
              helperText={formState.errors.password}
              variant="outlined"
              fullWidth
              required
              margin="normal"
            />

            <SelectGeoserverServer
              selectedGeoserverServer={selectedGeoserverServer}
              setSelectedGeoserverServer={setSelectedGeoserverServer}
              submitted={true}
            />

            <SelectArea
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
              submitted={true}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.formButton}
              disabled={UpdateGeoserverLoading ? true : false}
            >
              Update Geoserver
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
