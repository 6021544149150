import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Autocomplete
} from "@material-ui/lab";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useFormState } from "react-use-form-state";
import { MultiSelect } from "react-multi-select-component";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  APIAreaType,
  getAllAreaTypes
} from "../../API/Orders/AreaTypes";
import {
  APIClient,
  getAllClients
} from "../../API/Orders/Clients";
import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";
import {
  APIDeliverables,
  getAllDeliverables
} from "../../API/Orders/Deliverables";
import {
  APIFlight,
  getAllFlights
} from "../../API/Orders/Flights";
import {
  APIStatus,
  getAllStatuses
} from "../../API/Orders/Status";
import {
  APIZone,
  getAllZones
} from "../../API/Orders/Zones";
import {
  CreateAPIOrder,
  attachFlightsToOrder,
  createOrder
} from "../../API/Orders/Orders";

const buildDeliverableLabel = (deliverable: APIDeliverables) => {
  let ret = deliverable.deliverable;
  if (deliverable.crop && deliverable.crop.indexOf("no crop") === -1 && deliverable.crop.indexOf("any crop") === -1)
    ret = deliverable.crop + " - " + ret;
  if (deliverable.development_stage)
    ret += " (" + deliverable.development_stage.name + ")";
  return ret;
};

const buildDeliverableOptions = (deliverables: Array<APIDeliverables>) => {
  const ret:Array<any> = [];
  const byProduct:{ [key: string]: Array<APIDeliverables> } = {};
  deliverables.forEach((deliverable: APIDeliverables) => {
    if (byProduct[deliverable.product] === undefined)
      byProduct[deliverable.product] = [];
    byProduct[deliverable.product].push(deliverable);
  });
  for (let product in byProduct) {
    ret.push({
      label: product,
      value: null,
      disabled: true
    });
    byProduct[product].forEach((option) => ret.push({
      label: buildDeliverableLabel(option),
      value: option.id
    }));
  }
  return ret;
};

const buildFlightOptions = (flights: Array<APIFlight>, flightDate: string | null) => {
  const ret:Array<any> = [];
  flights.forEach((flight) => {
    ret.push({
      label: flight.flight_id + " (" + flight.flight_start + ")",
      search: flight.flight_id + "",
      value: flight.flight_id
    });
  });
  return ret;
};

export default function CreateFlightOrder(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);
  const [areaTypes, setAreaTypes] = useState<Array<APIAreaType>>([]);
  const [clients, setClients] = useState<Array<APIClient>>([]);
  const [countries, setCountries] = useState<APICountriesById>({});
  const [deliverables, setDeliverables] = useState<Array<APIDeliverables>>([]);
  const [flights, setFlights] = useState<Array<APIFlight>>([]);
  const [statuses, setStatuses] = useState<Array<APIStatus>>([]);
  const [zones, setZones] = useState<Array<APIZone>>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);

  const formValues: { [key: string]: any } = {
    client: null,
    order_date: (new Date()).toISOString().split("T")[0],
    deliverables: [],
    flights: [],
    area_type: null,
    status: null,
    zone: null
  };

  const [formState, { date, raw }] = useFormState(formValues);

  function setError(e: any) {
    if (e instanceof Error)
      e = e.message;
    setSnackbar({
      open: true,
      message: e,
      severity: "error"
    });
  }

  useEffect(() => {
    Promise.all([
      getAllAreaTypes(),
      getAllClients(),
      getAllCountriesById(),
      getAllDeliverables(),
      getAllFlights(),
      getAllStatuses(),
      getAllZones()
    ])
      .then(([ areaTypes, clients, countries, deliverables, flights, statuses, zones ]) => {
        if (areaTypes === null || clients === null || countries === null ||
            deliverables === null || flights === null || statuses === null || zones === null)
          return;
        setAreaTypes(areaTypes);
        setClients(clients);
        setCountries(countries);
        setDeliverables(deliverables);
        setFlights(flights);
        setStatuses(statuses);
        setZones(zones);
      })
      .catch((err: any) => console.log(err));
  }, []);

  async function handleCreate(e: SyntheticEvent) {
    e.preventDefault();
    setAdding(true);

    let hasErrors = false;
    function setFieldError(key: string, error: string) {
      formState.setFieldError(key, error);
      hasErrors = true;
    }

    try {
      Object.keys(formValues).forEach((key: string) => {
        if (typeof(formValues[key]) === "object")
          if (formState.values[key] === null)
            setFieldError(key, "Please select an option from the list");
        if (typeof(formValues[key]) === "string") // we only have the date field here
          if (("" + formState.values[key]).match(/^(19|20)[0-9]{2}-[0-9]{2}-[0-9]{2}$/) === null)
            setFieldError(key, "Please pick a date");
        if (formValues[key] instanceof Array)
          if (formState.values[key].length === 0)
            setFieldError(key, "Please select at least one option");
      });

      if (hasErrors) {
        setAdding(false);
        return;
      }

      const order: CreateAPIOrder = {
        area_type: parseInt(formState.values.area_type.id),
        date: formState.values.order_date,
        status: parseInt(formState.values.status.id),
        zone_id: parseInt(formState.values.zone.id),
        deliverables: formState.values.deliverables.map((item: any) => parseInt(item.value))
      };
      const flightIds = formState.values.flights.map((item: any) => parseInt(item.value));

      createOrder(order)
        .then((response) => {
          const orderId = response.id;
          attachFlightsToOrder(parseInt(orderId), flightIds)
            .then((response) => {
                setAdding(false);
                props.onCreate && props.onCreate(orderId);
            })
            .catch((err: any) => {
              setError(err);
              setAdding(false);
            });
        })
        .catch((err: any) => {
          setError(err);
          setAdding(false);
        });
    }
    catch (err: any) {
      setError(err);
      setAdding(false);
    }
  }

  function handleChange(field: string, value: any) {
    if (field === "client") {
      formState.resetField("zone");
      setSelectedClient(value ? parseInt(value.id) : null);
    }
  }

  const deliverableOptions = deliverables ? buildDeliverableOptions(deliverables) : [];
  const flightsOptions = flights ? buildFlightOptions(flights, formState.values.order_date) : [];

  const options:{ [key: string]: any } = {
    client: clients ? clients.map((client: any) => ({
      id: client.id,
      label: client.name + (countries[client.country_id+""] ? " (" + countries[client.country_id+""].name + ")" : "")
    })) : [],
    zone: selectedClient && zones ? zones.filter((zone: APIZone) => zone.client_id === selectedClient).map((zone) => ({
      id: zone.id,
      label: zone.id + ": " + zone.name
    })) : [],
    deliverables: deliverableOptions,
    flights: flightsOptions,
    area_type: areaTypes ? areaTypes.map((areaType: any) => ({
      id: areaType.id,
      label: areaType.name
    })) : [],
    status: statuses ? statuses.map((status: any) => ({
      id: status.id,
      label: status.name
    })) : []
  };

  const rememberedOptions:{ [key: string]: any } = {};

  function recallOption(key: string, e: any, reason: string) {
    if (reason === "escape" || reason === "blur")
      if (rememberedOptions[key] !== undefined)
        formState.setField(key, rememberedOptions[key]);
  }

  function rememberOption(key: string) {
    rememberedOptions[key] = formState.values[key];
  }

  function setOption(key: string, e: any, value: any, reason: string, callback?: Function) {
    formState.setField(key, value);
    if (callback)
      callback(key, value);
  }

  function renderDropDown(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <Autocomplete
          id={key}
          value={formState.values[key]}
          options={options[key]}
          onChange={(e, value, reason) => setOption(key, e, value, reason, onChange ? onChange : undefined)}
          onClose={(e, reason) => recallOption(key, e, reason)}
          onOpen={(e) => rememberOption(key)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(a: any, b: any) => a.id === b.id}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderDateField(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...date(key) }
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderMultiSelect(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <InputLabel id={key + "-label"} shrink>
          {label}
        </InputLabel>
        <MultiSelect className={classes.multiSelect}
          { ...raw(key)}
          filterOptions={async (options, filter) => {
            if (!filter || filter === "")
              return options;
            const re = new RegExp(filter, "i");
            return options.filter((option: any) => {
              if (option.search)
                return option.search.match(re);
              else
                return option.label.match(re);
            })
          }}
          options={options[key]}
          hasSelectAll={false}
          labelledBy="Select"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Create Order
          </Typography>

          <form onSubmit={handleCreate} noValidate>

            <Container className={classes.formContainer}>
              {renderDropDown("client", "Client *", handleChange)}
              {renderDateField("order_date", "Order Date *")}
              {renderMultiSelect("deliverables", "Deliverables *")}
              {renderMultiSelect("flights", "Flights *")}
              {renderDropDown("area_type", "Area Type *")}
              {renderDropDown("status", "Status *")}
              {renderDropDown("zone", "Zone *")}

              <FormControl style={{marginTop: "-1em"}} fullWidth error={selectedClient === null}>
                { selectedClient === null &&
                  <FormHelperText id="zone-hint">
                    Select a client to load the associated zones
                  </FormHelperText>
                }
              </FormControl>
            </Container>

            <Box className={classes.formButtons} >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButtonMultiple}
                disabled={adding ? true : false}
              >
                Create Order
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.formButtonMultiple}
                onClick={props.onCancel}
              >
                {adding ? "Close" : "Cancel"}
              </Button>
            </Box>

          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
