import gql from "graphql-tag";

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      email
      name
      lastLogin
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: Float!) {
    user(id: $id) {
      id
      email
      name
      isAdmin
    }
  }
`;

export const ADD_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(createUserData: $input) {
      id
      name
      email
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: Float!, $input: UpdateUserInput!) {
    updateUser(updateUserData: $input, id: $id) {
      id
      name
      email
      isAdmin
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: Float!) {
    deleteUser(id: $id)
  }
`;
