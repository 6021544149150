import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Autocomplete
} from "@material-ui/lab";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useFormState } from "react-use-form-state";
import useStyles from "../../styles";
import Snackbar, { defaultSnackbarState } from "../Snackbar/Snackbar";

import {
  APIAreaType,
  getAllAreaTypes
} from "../../API/Orders/AreaTypes";
import {
  APICountriesById,
  getAllCountriesById
} from "../../API/Orders/Countries";
import {
  CreateAPIZone,
  createZone
} from "../../API/Orders/Zones";

export default function CreateFlightZone(props: any) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState(defaultSnackbarState);
  const [adding, setAdding] = useState(false);
  const [shapeFile, setShapeFile] = useState<any>(null);
  const [shapeFileError, setShapeFileError] = useState<string | null>(null);
  const [areaTypes, setAreaTypes] = useState<Array<APIAreaType>>([]);
  const [countries, setCountries] = useState<APICountriesById>({});

  const formValues: { [key: string]: any } = {
    name: "",
    client: null,
    area_type: null
  };

  const [formState, { number, text }] = useFormState(formValues);

  useEffect(() => {
    Promise.all([ getAllAreaTypes(), getAllCountriesById() ])
      .then(([ areaTypes, countries ]) => {
        if (areaTypes === null || countries === null)
          return;
        setAreaTypes(areaTypes);
        setCountries(countries);
      })
      .catch((err: any) => console.log(err));
  }, []);

  async function handleCreate(e: SyntheticEvent) {
    e.preventDefault();
    setAdding(true);

    let hasErrors = false;
    function setFieldError(key: string, error: string) {
      formState.setFieldError(key, error);
      hasErrors = true;
    }

    try {
      Object.keys(formValues).forEach((key: string) => {
        if (typeof(formValues[key]) === "object")
          if (formState.values[key] === null)
            setFieldError(key, "Please select an option from the list");
        if (typeof(formValues[key]) === "string")
          if (formState.values[key] === "" || formState.values[key] === null)
            setFieldError(key, "Please enter a valid value");
      });
      if (shapeFile === null) {
        setShapeFileError("Please upload a shape file");
        hasErrors = true;
      }

      if (hasErrors) {
        setAdding(false);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(shapeFile);
      reader.onload = () => {
        if (!reader.result)
          return handleError("Could not read shape file");
        const fileData = (reader.result as string).split(",")[1];
      
        const zone: CreateAPIZone = {
          name: formState.values.name,
          client_id: parseInt(formState.values.client.id),
          area_type: parseInt(formState.values.area_type.id),
          shape_file: fileData,
          buffer: parseInt(formState.values.buffer),
          remarks: formState.values.remarks
        };

        createZone(zone)
          .then((response) => {
            if (response.success) {
              setAdding(false);
              props.onCreate && props.onCreate(response.data);
            }
            else
              return handleError("Could not create zone");
          });
      }
    }
    catch (err: any) {
      handleError(err.message);
    }
  }

  function handleError (message: string) {
    setSnackbar({
      open: true,
      message: message,
      severity: "error"
    });
    setAdding(false);
  }

  function handleSelectFile(e: any) {
    if (!e)
      return setShapeFile(null);
    if (e.target.files.length === 0)
      return setShapeFile(null);
    setShapeFile(e.target.files[0]);
  }

  const clientsById = props.data && props.data.clients ? props.data.clients : {};
  const clients = Object.values(clientsById).sort((a: any, b: any) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

  const options:{ [key: string]: any } = {
    client: clients ? clients.map((client: any) => ({
      id: client.id,
      label: client.name + (countries[client.country_id+""] ? " (" + countries[client.country_id+""].name + ")" : "")
    })) : [],
    area_type: areaTypes ? areaTypes.map((areaType: any) => ({
      id: areaType.id,
      label: areaType.name
    })) : []
  };

  const rememberedOptions:{ [key: string]: any } = {};

  function recallOption(key: string, e: any, reason: string) {
    if (reason === "escape" || reason === "blur")
      if (rememberedOptions[key] !== undefined)
        formState.setField(key, rememberedOptions[key]);
  }

  function rememberOption(key: string) {
    rememberedOptions[key] = formState.values[key];
  }

  function setOption(key: string, e: any, value: any, reason: string, callback?: Function) {
    formState.setField(key, value);
    if (callback)
      callback(key, value);
  }

  function renderDropDown(key: string, label: string, onChange?: Function) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <Autocomplete
          id={key}
          value={formState.values[key]}
          options={options[key]}
          onChange={(e, value, reason) => setOption(key, e, value, reason, onChange ? onChange : undefined)}
          onClose={(e, reason) => recallOption(key, e, reason)}
          onOpen={(e) => rememberOption(key)}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(a: any, b: any) => a.id === b.id}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderNumberField(key: string, label: string) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...number(key) }
          label={label}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderTextField(key: string, label: string) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...text(key) }
          label={label}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderTextArea(key: string, label: string) {
    return (
      <FormControl fullWidth error={formState.errors[key] !== undefined}>
        <TextField
          { ...text(key) }
          label={label}
          multiline rows={3}
          variant="standard"
        />
        {formState.errors[key] &&
          <FormHelperText id={key + "-hint"}>
            {formState.errors[key]}
          </FormHelperText>
        }
      </FormControl>
    );
  }

  function renderFileField() {
    return (<>
      <Box className={classes.formColumns}>
        <Box className={classes.formColumn2}>
          <FormControl fullWidth error={shapeFileError !== null}>
            <Button
              variant="contained"
              component="label"
            >
              Shape File *
              <input
                onChange={handleSelectFile}
                type="file"
                hidden
              />
            </Button>
          </FormControl>
        </Box>
        <Box className={classes.formColumn2} sx={{ paddingTop: "8px" }}>
          {shapeFile &&
            <span>{shapeFile.name}</span>
          }
        </Box>
      </Box>
      <FormControl fullWidth error={shapeFileError !== null}>
        {shapeFileError !== null &&
          <FormHelperText id={"shapeFile-hint"}>
            {shapeFileError}
          </FormHelperText>
        }
      </FormControl>
    </>);
  }

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            className={classes.modalTitle}
          >
            Create Zone
          </Typography>

          <form onSubmit={handleCreate} noValidate>

            <Container className={classes.formContainer}>

              {renderTextField("name", "Name *")}
              {renderDropDown("client", "Client *")}
              {renderDropDown("area_type", "Area Type *")}
              {renderFileField()}
              {renderNumberField("buffer", "Buffer")}
              {renderTextArea("remarks", "Remarks")}

            </Container>

            <Box className={classes.formButtons} >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.formButtonMultiple}
                disabled={adding ? true : false}
              >
                Create Zone
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.formButtonMultiple}
                onClick={props.onCancel}
              >
                {adding ? "Close" : "Cancel"}
              </Button>
            </Box>

          </form>
        </CardContent>
      </Card>

      <Snackbar
        state={snackbar}
        handleClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </React.Fragment>
  );
}
