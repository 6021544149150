import { ORDERS_API_KEY, ORDERS_API_URL } from "../../constants";
import { connector } from "../common";
const api = connector(ORDERS_API_KEY, ORDERS_API_URL);

export type APIStatus = {
  id: number;
  order: number;
  name: string;
};

export async function getAllStatuses(): Promise<Array<APIStatus>> {
  try {
    return api.get("status");
  }
  catch (err: any) {
    throw err;
  }
}
